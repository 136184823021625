import styled from 'styled-components';

export const RoomDetailsContainer = styled.div`

  display: block;
  width: 100%;
  height: calc(100vh - 50px);
  min-height: 610px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    min-height: 460px;
  }

  @media only screen and (min-width: 501px) and (max-width: 767px) {
    min-height: 570px;
  }

  @media only screen and (min-width: 401px) and (max-width: 500px) {
    min-height: 490px;
  }

  @media only screen and (min-width: 375px) and (max-width: 400px)  {
    min-height: 460px;
  }

  @media only screen and (max-width: 374px) {
    min-height: 430px;
  }
`;