import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Menu, Title, Item, SubMenuItem } from './styles'
import Cross from 'assets/svg/cross.svg'

const MainNav = ({ open, setOpen }) => {

  const history = useHistory()
  const { pathname } = useLocation()

  const MENU_ITEMS = [
    {
      title: 'HOME',
      to: '/',
    },
    {
      title: 'BOOKINGS',
      to: '/bookings',
      subMenu: [
        {
          title: 'Create Booking',
          to: '/create-booking',
        },
      ],
    },
    {
      title: 'STAFF',
      to: '/staff',
    },
    {
      title: 'REPORTS',
      to: '/reports',
      subMenu: [
        {
          title: 'Occupancy Report',
          to: '/occupancy-report',
        },
        {
          title: 'Revenue Report',
          to: '/revenue-report',
        },
        {
          title: 'Daily Reconciliation',
          to: '/daily-reconciliation',
        },
        {
          title: 'Bookings Report',
          to: '/bookings-report',
        },
        {
          title: 'Room Night Report',
          to: '/room-night-report',
        },
      ],
    }
  ];

  return (
    <Menu open={open}>
      <Title>
        <img
          src={Cross}
          alt="cross"
          onClick={()=>{
            setOpen(false)
          }}
        />
      </Title>
      {
        MENU_ITEMS.map((item, index) => {
          const { title, to, subMenu } = item
          return (
            <React.Fragment key={index}>
              {
                <>
                  <Item
                    desktop={title === 'HOME'}
                    active={to === pathname}            
                    onClick={()=>{
                      history.push(to)
                    }}
                  >
                    {title}
                  </Item>
                  {
                    subMenu?.map(({ title, to }, i) => (
                      <SubMenuItem
                        key={i}
                        active={to === pathname}
                        onClick={()=>{
                          history.push(to)
                        }}
                      >
                        {title}
                      </SubMenuItem>
                    ))
                  }
                </>
              }
            </React.Fragment>
          )
        })
      }
    </Menu>
  );
}

export default MainNav;
