import styled from 'styled-components';
import colors from 'constants/colors';

export const ClientInfoModalContainer = styled.div`
  display: ${(props) => (props.open? null : 'none')};
  width: 355px;
  height: 100%;
  position: fixed;
  top: 150px;
  right: 0;
  background-color: ${colors.black5};
  z-index: 100;
  padding: 15px 15px;
  border-left: 1px solid ${colors.white1};

  .clientButtonSection {
    width: 375px;
    margin-bottom: 20px;
    .clientName {
      color: ${colors.white1};
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .buttonsContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .clientInfoItem {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;

    .infoTitle {
      color: ${colors.grey22};
    }

    .info {
      color: ${colors.white1};
    }
  }
`;