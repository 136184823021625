import React from 'react';
import EighthFloor from './floors/EighthFloor';
import NinthFloor from './floors/NinthFloor';
import TenthFloor from './floors/TenthFloor';

const FloorPlan = ({
  selectedFloor,
  scale,
  desktop,
  backgroundColor,
  eighthFloorRooms,
  ninthFloorRooms,
  tenthFloorRooms
}) => {

  return selectedFloor === 8 ? (
    <EighthFloor scale={scale} desktop={desktop} backgroundColor={backgroundColor} rooms={eighthFloorRooms}/>
  ) : selectedFloor === 9 ? (
    <NinthFloor scale={scale} desktop={desktop} backgroundColor={backgroundColor} rooms={ninthFloorRooms}/>
  ) : (
    <TenthFloor scale={scale} desktop={desktop} backgroundColor={backgroundColor} rooms={tenthFloorRooms}/>
  );
};

export default FloorPlan;
