import React from 'react';
import { ReconciliationTableContainer } from '../style';

const DailyReconciliationTable = () => {
  const data=[1,2,3,4,5]
  return (
    <ReconciliationTableContainer>
      <thead>
        <tr>
          <th className="date-column">Date</th>
          <th className="customer-column">Customer</th>
          <th className="amountPaid-column">Amount Paid</th>
          <th className="tax-column">Tax</th>
          <th className="charge-column">Total Charged</th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((record, index) => (
            <tr key={index}>
              <td>5/24/2020 5:34 PM</td>
              <td>Firstname Lastname Verylongname Maybe</td>
              <td>1234.56</td>
              <td>34.45</td>
              <td>1234.56</td>
            </tr>
          ))
        }
      </tbody>
    </ReconciliationTableContainer>
  )
}

export default DailyReconciliationTable;
