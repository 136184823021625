import React from 'react';
import { GroupLabelContainer, DividingLine } from 'screens/Bookings/styles';

const GroupLabel = ({ height, groupName, top }) => {

  return (
    <>
      <GroupLabelContainer height={height} top={top}>
        {groupName}
      </GroupLabelContainer>
      <DividingLine top={top-1}/>
    </>
  )
};

export default GroupLabel;
