import { styled } from '@mui/system';
import { Badge } from '@mui/base';
import colors from 'constants/colors';

const StyledBadge = styled(Badge)`
  position: relative;

  .base-Badge-badge {
    position: absolute;
    top: ${({top}) => top? top: '5px'};
    right: ${({right}) => right? right: '5px'};
    z-index: auto;
    width: 11px;
    height: 11px;
    background: ${colors.red1};
    border-radius: 11px;

    ${
      ({mobile})=>mobile? `
        @media only screen and (min-width: 767px) and (max-width: 1200px) {
          width: 16px;
          height: 16px;
          border-radius: 16px;
        }
                  
        @media only screen and (max-width: 767px) {
          width: 10px;
          height: 10px;
          border-radius: 10px;
        }
      `: null
    };
  }

  .base-Badge-invisible {
    display: none;
  }
`;

export default StyledBadge