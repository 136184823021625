import React, { useState } from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Button from 'components/Button';
import { GET_ALL_BOOKING_ADD_ONS } from 'graphql/service';
import { useQuery } from '@apollo/client';
import { AddOnContainer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const AddOns = ({ bookingId }) => {

  const [bookingAddOns, setBookingAddOns] = useState([])

  //FIXME: Add on data is not being fetched by bookingId right now
  const { data: addOnData, error } = useQuery(GET_ALL_BOOKING_ADD_ONS, {
    variables: { bookingId: bookingId },
    onCompleted: (addOnData) => {
      if (addOnData) {
        setBookingAddOns(addOnData.validBookingAddons)
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const addOnsArray = bookingAddOns.map((bookingAddOn, index) => {
    return (
      <AddOnContainer key={index}>
        <div className='label'>{bookingAddOn.label}</div>
      </AddOnContainer>
    )
  })

  const handleSaveChanges = () => {
    console.log('save changes')
  }

  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;
      `}      
    >
      <CardHeader>
        <div className='type'>ADD-ONS</div>
        <div className='buttonContainer'>
          <Button
            mainPage
            success
            onClick={handleSaveChanges}
            additionalStyle={`
              margin-left: 14px;
              width: 113px;
            `}
          >
            Save Changes
          </Button>
        </div>
      </CardHeader>
      <div>{addOnsArray}</div>
    </Card>
  );
};

export default AddOns;
