import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  ${({ additionalStyle }) => additionalStyle};

  .type {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.5px;

    @media only screen and (min-width: 767px) and (max-width: 1200px) {
      font-size: 17px;
    }

    @media only screen and (max-width: 767px) {
      font-weight: bold;
    }
  }  
  
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subTitle {
      font-size: 10px;
      font-weight: 600;
      color: ${colors.grey5};
      margin-right: 10px;

      @media only screen and (min-width: 767px) {
        white-space: nowrap;
      }
    }
  }
`;
