import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import ShowInformation from 'components/ShowInformation';
import moment from 'moment';

const MetaInformation = ({created, origin, confirmationNumber, channel, campaignTag}) => {

  return (
    <Card>
      <CardHeader>
        <div className='type'>META INFORMATION</div>
      </CardHeader>
      <FormContainer>
        <ShowInformation title="CREATED" description={moment(created).format('M/D/YYYY h:mmA')} />
        <ShowInformation title="ORIGIN" description={origin} />
        <ShowInformation title="CONFIRMATION #" description={confirmationNumber} />
        <ShowInformation title="CHANNEL" description={channel} />
        <ShowInformation title="CAMPAIGN TAG" description={campaignTag} />
      </FormContainer>
    </Card>
  )
}

export default MetaInformation;
