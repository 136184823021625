import React from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header';
import SubHeader from './components/SubHeader';
import RevenueReportTable from './components/RevenueReportTable';

const RevenueReport = () => {
  return (
    <>
      <Helmet>
        <title>Revenue Report - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalStyle={`
          border: none;
        `}
      />
      <SubHeader />
      <RevenueReportTable />
    </>
  );
};

export default RevenueReport;
