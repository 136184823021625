import styled from 'styled-components';
import colors from 'constants/colors';

export const FloorNumbers = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5px;

  .active {
    background-color: ${colors.white2};
    color: ${colors.black5};
  }

  div {
    background-color: ${colors.grey10};
    color: ${colors.grey11};
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    margin: 5px;
    border-radius: 50%;
    position: relative;
    font-size: 19px;
    font-weight: bold;

    @media only screen and (min-width: 767px) and (max-width: 1200px) {
      width: 51px;
      height: 51px;
      line-height: 51px;
      font-size: 24px;
    }

    @media only screen and (max-width: 767px) {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
    }
  }
`;

export const ViewLegend = styled.div`
  color: ${({active})=>active? colors.blue2: colors.white1};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 34px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
  }

  div {
    padding: 5px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    img{
      width: 40px;
      height: 29px;
    }
  }
              
  @media only screen and (max-width: 767px) {
    img{
      width: 26px;
      height: 19px;
    }
  }
`;
