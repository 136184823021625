import React from 'react';
import Header from 'components/Header';
import Alerts from 'screens/Home/components/Alerts';
import {
  AlertsScreenContainer,
  Content,
  Container,
} from './styles';

const AlertsScreen = ({mobile, open}) => {

  return (
    <>
      <Header mobile={mobile} open={open}/>
      <AlertsScreenContainer mobile={mobile} open={open}>
        <Content>
          <Container>
            <Alerts mobile/>
          </Container>
        </Content>
      </AlertsScreenContainer>
    </>
  )
}

export default AlertsScreen;
