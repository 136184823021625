import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import { Container, Head, Footer } from './styles';

const ModalStaffModifyAccess = ({hidden, buttonStyle}) => {

  const [isOpen, setIsOpen] = useState(false)
  
  const handleModifyAccess = () => {

  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Modify Access"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 611px;
        .modal-content{
          height: 333px;
          .close {
            z-index: 200;
          }          
          .modal-body {
            position: relative;
            top: 20px;
            height: 100%;
          }
        }
        top: 185px;
      `}
    >
      <Container>
        <Head>Modify Access for Jane Smith</Head>
        <div className="groupTitle">SECURITY ACCESS LEVEL</div>
        <div className="checkboxGroup">
          <Checkbox
            name="floor10"
            checked={false}
            onChange={(e) => { }}
            title="Floor 10 - Rooms"
          />
          <Checkbox
            name="floor9"
            checked={true}
            onChange={(e) => { }}
            title="Floor 9 - Rooms"
          />
          <Checkbox
            name="floor8"
            checked={true}
            onChange={(e) => { }}
            title="Floor 8 - Rooms"
          />
          <Checkbox
            name="utility"
            checked={true}
            onChange={(e) => { }}
            title="Utility Closets"
          />
          <Checkbox
            name="housekeepingSupply"
            checked={true}
            onChange={(e) => { }}
            title="Housekeeping Supply Closets"
          />
        </div>
      </Container>
      <Footer>
        <Input
          type="password"
          title="ENTER YOUR PASSWORD"
          onChange={(e) => { }}
          additionalStyle={`
            flex-basis: 45%;
            margin-right:10%;
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleModifyAccess}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalStaffModifyAccess;
