import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header';
import DailyReconciliationTable from './components/DailyReconciliationTable';
import SubHeader from './components/SubHeader';
import moment from 'moment';

const DailyReconciliation = () => {

  const [checkInDate, setCheckInDate] = useState(moment().format('MM/DD/YYYY'));
  const [checkOutDate, setCheckOutDate] = useState(moment().add(1, 'days').format('MM/DD/YYYY'));

  return (
    <>
      <Helmet>
        <title>Daily Reconciliation - Rook Hotel PMS</title>
      </Helmet>
      <Header        
        additionalStyle={`
          border: none;
        `}
      />
      <SubHeader
        checkInDate={checkInDate}
        setCheckInDate={setCheckInDate}
        checkOutDate={checkOutDate}
        setCheckOutDate={setCheckOutDate}
      />
      <DailyReconciliationTable />
    </>
  );
};

export default DailyReconciliation;
