import axios from 'axios';
import { message } from 'antd'
import { getMessageTimeOut } from 'utils/util';

export default function ajax (url='', data={}, type='GET') {
    //collecting error within the function instead of rejecting the error
    return new Promise((resolve, reject) => {
        let promise = null
        const token = localStorage.getItem('token')
        const service = axios.create({
            baseURL: process.env.REACT_APP_DEVELOPMENT==='development' ? `${process.env.REACT_APP_REMOTE_ENDPOINT}`: '/',
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
            withCredentials: true,
        })

        switch (type) {
            case 'GET':
                promise = service.get(url,{params: data})
                break
            case 'POST': 
                promise = service.post(url,data)
                break
            case 'PUT': 
                promise = service.put(url,data)
                break
            case 'DELETE': 
                promise = service.delete(url,{params: data})
                break
            default:
                const content = 'Request type is wrong!'
                message.error({
                    content,
                    duration: getMessageTimeOut(content),
                    onClick: () => {
                        message.destroy()
                    }
                })
        }
        
        promise.then(response => {
            resolve(response.data)
        }).catch(error => {
            resolve(error)
            const content = error.message
            message.error({
              content,
              duration: getMessageTimeOut(content),
              onClick: () => {
                message.destroy()
              }
            })
        })
    })
}