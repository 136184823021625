import styled from 'styled-components';
import colors from 'constants/colors';

export const SideBarItemContainer = styled.div`
  cursor: pointer;
  height: ${(props) => props.rowHeight ? props.rowHeight + 'px' : '32px'};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.warning? colors.brown3: null};
  color: ${(props) => props.warning? colors.red1: colors.blue1};
  padding-right: 20px;

  .warningCover {
    position: absolute;
    height: 32px;
    left: 100px;
    right: 0;
    background-color: ${colors.brown3};
    z-index: 5;
  }
`;

export const TimelineSubHeaderContainer = styled.div`
  position: fixed;
  top: 50px;
  z-index: 500;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.black5};
  color: ${colors.white1};
  padding-left: 10px;

  .title {
      font-size: 12px;
      letter-spacing: 2.5px;
      font-weight: 600;
      color: ${colors.grey5};
      margin-right: 5px;
    }

  .contentContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;

    .dateSection {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 170px;
    }

    .timePickerContainer {
      display: flex;
      align-items: center;
      margin-left: 30px;
    }
  }
`;

export const ItemRendererContainer = styled.div`
  height: ${(props) => props.rowHeight ? props.rowHeight + 'px' : '30px'};
  white-space: nowrap;
  text-align: center;
  vertical-align: center;
  overflow: hidden;
  text-overflow : ellipsis;
  padding: 0 10px 0 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black5};
`;

export const GroupLabelContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top ? `${props.top}px` :'0'};;
  background-color: ${colors.black5};
  border-left: 1px solid ${colors.white1};
  width: 30px;
  height: ${(props) => props.height ? `${props.height}px` :'32px'};
  display: flex;
  writing-mode: vertical-rl; 
  transform: rotate(180deg);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.white1};
  z-index: 100;
`;

export const DividingLine = styled.div`
  position: absolute;
  top: ${(props) => props.top ? `${props.top}px` :'0'};;
  height: 1px;
  width: 100%;
  background-color: ${colors.white1};
`;
