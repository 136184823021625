import styled from 'styled-components';
import colors from 'constants/colors';

export const CommunicationContainer = styled.div`
  ${({mobile, open}) => {
    if(mobile && open) {     
      return `
        display: block;
        z-index: 4000;
        position: fixed;
        top: 0px;
        width: 100%;
        height: 100vh;
      `
    } else {
      return `display: block;`
    }
  }}
`;

export const Content = styled.div`
  height: 100%;
  background-color: ${colors.black5};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  .navigator {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 49px;
    padding: 0 20%;
    color: ${colors.grey22};
    font-size: 16px;
    font-weight: 600;
    background-color: ${colors.grey2};

    div {
      height: 49px;
      line-height: 49px;
      text-align: center;
      padding: 0 20px;
      cursor: pointer;
    }

    .active {
      color: ${colors.black5};
      background-color: ${colors.blue1};
    }

    @media only screen and (max-width: 767px) {
      height: 31px;
      font-size: 11px;
      font-weight: bold;
      padding: 0 30px;
      justify-content: center;

      div {
        height: 31px;
        line-height: 31px;
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }

  .messages, .audios {
    height: 100%;
    overflow: auto;
    background-color: ${colors.black5}; 
    padding: 30px 30px 75px 30px;

    ::-webkit-scrollbar { 
      width: 0 !important; 
    }

    ::-moz-scrollbar {
      width: 0 !important; 
    }
  }

  .typeBar, .callBar {
    position: fixed;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 30px;

    div {
      width: 95%;
    }

    input {
      height: 45px;
      width: 95%;
      background-color: transparent;
      color: ${colors.white1};
      padding: 0 20px;
      border: 1px solid ${colors.grey9};
      border-radius: 22.5px;
    
      :focus-visible {
        outline: none;
        background-color: ${colors.black5};
      }
    }

    img {
      width: 45px;
      height: 45px;
      margin-left: 5px;
      cursor: pointer;
    }

    @media only screen and (max-width: 767px) {

      input {
        height: 39px;
        border-radius: 19.5px;
      }

      img {
        width: 39px;
        height: 39px;
      }
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  height: 73px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white1};
  padding: 0 30px;
  background-color: ${colors.grey2};
  border-bottom: 1px solid ${colors.black5};

  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(10%) hue-rotate(300deg) brightness(100%) contrast(100%);
    margin-right: 10px;  
    width: 36px;
    height: 37px;
  } 

  @media only screen and (max-width: 767px) {
    height: 52px;
    font-size: 13px; 

    img{
      margin-right: 5px;
      width: 27px;
      height: 28px;
    }
  }
`;
