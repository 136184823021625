import styled from 'styled-components';
import colors from 'constants/colors';

export const TravelInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  column-gap: 10px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 25px;
  }

  & > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  span {
    display: block;
    margin-bottom: 7px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    color: ${colors.grey5};
    white-space: nowrap;

    @media only screen and (max-width: 1200px) {
      font-size: 11px;
    }
  }

  .datePickerContainer {
    width: 55%;
  }
  
  .timePickerContainer {
    width: 45%;
  }
`;
