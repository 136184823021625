import React from 'react';
import { ReactComponent as SvgImageBack } from 'assets/svg/Back.svg';
import { ReactComponent as SvgImageFace } from 'assets/svg/Face.svg';
import { ReactComponent as SvgImageFront } from 'assets/svg/Front.svg';
import { ReactComponent as SvgImageVideo } from 'assets/svg/Video.svg';
import { Container, CustomerInformationImagesItem } from './styles';

const GuestInformationImages = ({DLFrontPath, DLBackPath, FacePath, VideoPath}) => {

    return (
    <Container>
      <div className='title'>VIEW IMAGES:</div>
      <CustomerInformationImagesItem
        onClick={() => { }}
      >
        <SvgImageFront />
        <span>DL Front</span>
      </CustomerInformationImagesItem>
      <CustomerInformationImagesItem
        onClick={() => { }}
      >
        <SvgImageBack />
        <span>DL Back</span>
      </CustomerInformationImagesItem>
      <CustomerInformationImagesItem
        onClick={() => { }}
      >
        <SvgImageFace />
        <span>Face</span>
      </CustomerInformationImagesItem>
      <CustomerInformationImagesItem
        onClick={() => { }}
      >
        <SvgImageVideo />
        <span>Video</span>
      </CustomerInformationImagesItem>
    </Container>
  )
}

export default GuestInformationImages;
