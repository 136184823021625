import styled from 'styled-components';
import colors from 'constants/colors';

export const NotesContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin-top: 5px;
`;

export const NavigatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;

  /* @media only screen and (max-width: 1200px) {
    justify-content: center;
  } */
`;

export const Navigator = styled.div`
  color: ${colors.blue1};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  /* @media only screen and (max-width: 1200px) {
    display: none;
  } */

  ${(props) => props.disabled ? `
    pointer-events:none;
    color: ${colors.grey8}
  `: null }
`;

// export const Swiper = styled.div`
//   color: ${colors.blue1};
//   font-size: 18px;
//   font-weight: bold;
//   cursor: pointer;

//   @media only screen and (min-width: 1201px) {
//     display: none;
//   }

//   @media only screen and (max-width: 767px) {
//     font-size: 13px;
//   }

//   ${(props) => props.disabled ? `
//     pointer-events:none;
//     color: ${colors.grey8}
//   `: null }
// `;