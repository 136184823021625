import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStaffInfo } from 'redux/actions'
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import ModalStaffModifyAccess from 'screens/ModalStaffModifyAccess';
import ModalGenerateKey from 'screens/ModalGenerateKey';
import ModalKeyPrinting from 'screens/ModalKeyPrinting';
import { usePlacesWidget } from 'react-google-autocomplete'

const StaffInformation = ({
  staffInfoError,
  setStaffInfoError
}) => {

  const dispatch = useDispatch()
  const staffInfo = useSelector(state => state.staffInfoReducer.staffInfo)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const [deviceId, setDeviceId] = useState('Kiosk 1')
  const [KeyPrintModalIsOpen, setKeyPrintModalIsOpen] = useState(false)

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      dispatch(setStaffInfo((staffInfo) => ({...staffInfo, address: place.formatted_address})))
    },
    options: {
      types: ['address'],
    },
  }) 

  useEffect(() => {
    if(phoneNumberValidity) {
      setStaffInfoError({...staffInfoError, phoneNumberError: false})
      dispatch(setStaffInfo((staffInfo) => ({...staffInfo, phoneNumber: phoneNumber})))
    }
  }, [phoneNumberValidity])

  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader
        additionalStyle={`
          @media only screen and (max-width: 767px) {
            
            .type {
              width: 100%;
              margin-bottom: 10px; 
            }

            .buttonContainer {
              width: 100%;
              justify-content: space-between;
            }
            
            flex-direction: column;
            justify-content: center;
          }
        `}
      >
        <div className='type'>STAFF INFORMATION</div>
        <div className='buttonContainer'>
          <ModalStaffModifyAccess
            buttonStyle={`
              width: 132px;
            `}
          />
          <div>
            <ModalGenerateKey
              buttonLabel='Generate Keycard'
              buttonStyle={`
                width: 145px;
                margin-left: 14px;
              `}
              roomNumber={'staff'}
              deviceId={deviceId}
              setDeviceId={setDeviceId}
              setKeyPrintModalIsOpen={setKeyPrintModalIsOpen}
            />
            <ModalKeyPrinting
              isOpen={KeyPrintModalIsOpen}
              setIsOpen={setKeyPrintModalIsOpen}
              buttonLabel={""}
              deviceId={deviceId}
            />
          </div> 
        </div>
      </CardHeader>
      <FormContainer>
        <Input
          mainPage
          title="NAME"
          value={staffInfo.fullName}
          error={staffInfoError.fullNameError}
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, fullName: e.target.value}))
          }}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          mainPage
          title="ADDRESS"
          inputFieldRef={ref}
          value={staffInfo.address}
          error={staffInfoError.addressError}
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, address: e.target.value}))
          }}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          mainPage
          title="EMAIL"
          value={staffInfo.email}
          error={staffInfoError.emailError}
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, email: e.target.value}))
          }}
          additionalStyle={`
            flex-basis: 63%;
            margin-right:2%;
            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <PhoneNumberInput
          mainPage
          title="PHONE"
          error={staffInfoError.phoneNumberError}
          initialValue={staffInfo.phoneNumber}
          onChangeNumber={setPhoneNumber}
          setPhoneNumberValidity={setPhoneNumberValidity}
          onBlur={() => {
            if(!phoneNumberValidity&&phoneNumber.length>0) {
              setStaffInfoError({...staffInfoError, phoneNumberError: true})
            }
          }}
          additionalStyle={`
            flex-basis: 35%;
            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
            }
          `}
        />
        <Dropdown
          mainPage
          options={['staff', 'housekeeping']}
          value={staffInfo.roles || ''}
          error={staffInfoError.rolesError}
          title="ROLES"
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, roles: e}))
          }}
          additionalStyle={`
            flex-basis: 50%;
            margin-right:5%;
          `}
        />
        <Dropdown
          mainPage
          options={['active']}
          value={staffInfo.status || ''}
          error={staffInfoError.statusError}
          title="STATUS"
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, status: e}))
          }}
          additionalStyle={`
            flex-basis: 45%;
          `}
        />
      </FormContainer>
    </Card>
  );
};

export default StaffInformation;
