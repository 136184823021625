import React from 'react';
import { ModalBody } from 'reactstrap'
import { StyledModal } from './styles'
import Button from 'components/Button'

const ModalMain = ({
  isOpen,
  setIsOpen,
  className,
  modalTitle,
  children,
  additionalStyle,
  danger,
  success,
  disabled,
  buttonLabel,
  buttonStyle,
  hidden,
  cleanFunction
}) => {

  const toggle = () => {
    if(cleanFunction) {
      if(isOpen) {
        cleanFunction()
      }
    }
    setIsOpen(!isOpen)
  }

  return (
    <div style={{display: hidden? 'none': null}}>
      <Button
        mainPage
        danger={danger}
        success={success}
        disabled={disabled}
        hidden={!buttonLabel}
        onClick={toggle}
        additionalStyle={buttonStyle}
      >
        {buttonLabel}
      </Button>
      <StyledModal
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        additionalStyle={additionalStyle}
      >
        <div
          className="close"
          onClick={toggle}
        />
        <ModalBody>{children}</ModalBody>
      </StyledModal>
    </div>
  );
};

export default ModalMain;
