import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerContainer } from './styles';
import { DateIcon } from 'assets/icons';

const DatePicker = ({
  disabled,
  error,
  minDate,
  dateValue,
  dateOnChange,
  timelineState,
  setDate,
  additionalStyle,
  variant,
  placeholder
}) => {

  const { state, setState } = timelineState ? timelineState : {};

  const onClickChange = (newValue) => {
    // change Material UI calendar date
    setDate(moment(newValue));

    //change timeline state to jump to selected date
    setState((state) => {
      return {
        ...state,
        visibleTimeStart: moment(newValue)
        .startOf('week')
        .toDate()
        .valueOf(),
        visibleTimeEnd: moment(newValue)
        .startOf('week')
        .add(10, 'day')
        .toDate()
        .valueOf(),
        today: moment(newValue).startOf('day').valueOf(),
        endToday: moment(newValue).endOf('day').valueOf(),
      };
    });
  };

  return (
    <DatePickerContainer additionalStyle={additionalStyle}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          slots={{
            openPickerIcon: DateIcon
          }}
          disabled={disabled}
          minDate={minDate}
          fullWidth
          label=" "
          inputFormat="MM/dd/yyyy"
          value={dateValue}
          onChange={dateOnChange ? dateOnChange : onClickChange}
          renderInput={(props) => 
            <TextField
              {...props}
              variant={variant}
              error={error}
              autoComplete="off"
              InputProps={{
                ...props.InputProps,
                notched: false,
                id: "datePickerInput",
                placeholder: placeholder,
              }}
            />            
          }
        />
      </LocalizationProvider>
    </DatePickerContainer>
  );
};

export default DatePicker;