import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  width: ${(props) => props.width? props.width: '33%'};
  flex-wrap: wrap;
  justify-content: flex-start;
  color: ${colors.white1};
  margin: 10px 0;

  div {
    flex-grow: 1;
    width: 100%;
  }

  .title {
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    color: ${colors.grey5};
  }

  .info {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

      .title {
        font-size: 11px;
      }

      .info{
        font-size: 15px;
      }
    }

    @media only screen and (max-width: 767px) {
      width: ${(props) => props.width? props.width: '50%'};
      .title {
        font-size: 11px;
      }

      .info{
        font-size: 13px;
      }
    }

  ${({ additionalStyle }) => additionalStyle};
`;
