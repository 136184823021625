/* global ActiveXObject */
import React from "react";
import { MainContainer } from "./styles";
import io from "socket.io-client";
import { Strings } from "./Scripts/regula.sdk.strings";
import {
  eRPRM_ResultType,
  eRFID_ResultType,
  eRFID_NotificationCodes,
  eRPRM_FieldVerificationResult,
  eVisualFieldTypeStrings,
  eRFID_DataFile_Type,
  eGraphicFieldType,
  eRPRM_SecurityFeatureType,
  eGraphicFieldTypeStrings,
  eBarCodeResultCodesStrings,
  eBarcodeTypeStrings,
  eBarCodeModuleTypeStrings,
} from "./Scripts/regula.sdk.enums";
import { toByteArray } from "./Scripts/bs64";

const PassportScanner = () => {
  let client;
  const serviceUrl = "http://192.168.5.223:3000/Regula.SDK.Api";

  /*=====/Settings=====*/

  function GetPropertyValue(propertyName, successFunction) {
    client.Post("GetPropertyValue", successFunction, propertyName);
  }

  function SetPropertyValue(propertyName, value, successFunction) {
    client.Post("SetPropertyValue", successFunction, propertyName, value);
  }

  /*=====/Methods=====*/

  function AppendImage(
    AImage,
    AFormat,
    ALightType,
    APageIndex,
    successFunction
  ) {
    var img = AImage.replace("data:image/jpeg;base64,", "");
    client.Post(
      "AppendImage",
      successFunction,
      img,
      AFormat,
      ALightType,
      APageIndex
    );
  }

  function AppendImageFromFile(
    AFileName,
    ALightType,
    APageIndex,
    successFunction
  ) {
    client.Post(
      "AppendImageFromFile",
      successFunction,
      AFileName,
      ALightType,
      APageIndex
    );
  }

  function BatteryStatusByIdx(index, successFunction) {
    client.Post("BatteryStatusByIdx", successFunction, index);
  }

  function Calibrate(successFunction) {
    client.Post("Calibrate", successFunction);
  }

  function CancelOpticalOperation(successFunction) {
    client.Post("CancelOpticalOperation", successFunction);
  }

  function CheckReaderImageLight(index, successFunction) {
    client.Post("CheckReaderImageLight", successFunction, index);
  }

  function CheckReaderImagePageIndex(index, successFunction) {
    client.Post("CheckReaderImagePageIndex", successFunction, index);
  }

  function CheckReaderResult(type, index, output, param, successFunction) {
    client.Post(
      "CheckReaderResult",
      successFunction,
      type,
      index,
      output,
      param
    );
  }

  function CheckReaderResultFromList(
    container,
    output,
    param,
    successFunction
  ) {
    client.Post(
      "CheckReaderResultFromList",
      successFunction,
      container,
      output,
      param
    );
  }

  function CheckReaderResultXML(type, index, output, successFunction) {
    client.Post("CheckReaderResultXML", successFunction, type, index);
  }

  function CheckRFIDResult(type, output, param, successFunction) {
    client.Post("CheckRFIDResult", successFunction, type, output, param);
  }

  function CheckRFIDResultFromList(container, output, param, successFunction) {
    client.Post(
      "CheckRFIDResultFromList",
      successFunction,
      container,
      output,
      param
    );
  }

  function CheckRFIDResultXML(type, output, successFunction) {
    client.Post("CheckRFIDResultXML", successFunction, type);
  }

  function CheckUpdates(successFunction) {
    client.Post("CheckUpdates", successFunction);
  }

  function ClearResults(successFunction) {
    client.Post("ClearResults", successFunction);
  }

  function Connect(successFunction) {
    client.Post("Connect", successFunction);
  }

  function Disconnect(successFunction) {
    client.Post("Disconnect", successFunction);
  }

  function DoProcessImage(AFilename, successFunction) {
    client.Post("DoProcessImage", successFunction, AFilename);
  }

  function DoProcessImages(AFolder, successFunction) {
    client.Post("DoProcessImages", successFunction, AFolder);
  }

  function get_AvailableDevices(index, successFunction) {
    client.Post("get_AvailableDevices", successFunction, index);
  }

  function get_AvailableGraphicFormats(index, successFunction) {
    client.Post("get_AvailableGraphicFormats", successFunction, index);
  }

  function get_AvailableLanguages(index, successFunction) {
    client.Post("get_AvailableLanguages", successFunction, index);
  }

  function GetBarcodeModuleCount(successFunction) {
    client.Post("GetBarcodeModuleCount", successFunction);
  }

  function GetBarcodeModuleDataByIdx(index, successFunction) {
    client.Post("GetBarcodeModuleDataByIdx", successFunction, index);
  }

  function GetGraphicFieldByTypeAndSource(type, source, successFunction) {
    client.Post(
      "GetGraphicFieldByTypeAndSource",
      successFunction,
      type,
      source
    );
  }

  function GetImages(successFunction) {
    client.Post("GetImages", successFunction);
  }

  function GetReaderBitmapImage(index, successFunction) {
    client.Post("GetReaderBitmapImage", successFunction, index);
  }

  function GetReaderBitmapImageByLightIndex(light, successFunction) {
    client.Post("GetReaderBitmapImageByLightIndex", successFunction, light);
  }

  function GetReaderBitmapImageByLightIndexAndPageIndex(
    light,
    page,
    successFunction
  ) {
    client.Post(
      "GetReaderBitmapImageByLightIndexAndPageIndex",
      successFunction,
      light,
      page
    );
  }

  function GetReaderEOSBitmapImage(index, successFunction) {
    client.Post("GetReaderEOSBitmapImage", successFunction, index);
  }

  function GetReaderEOSBitmapImageByLightIndex(light, successFunction) {
    client.Post("GetReaderEOSBitmapImageByLightIndex", successFunction, light);
  }

  function GetReaderEOSBitmapImageByLightIndexAndPageIndex(
    light,
    page,
    successFunction
  ) {
    client.Post(
      "GetReaderEOSBitmapImageByLightIndexAndPageIndex",
      successFunction,
      light,
      page
    );
  }

  function GetReaderFileImage(index, successFunction) {
    client.Post("GetReaderFileImage", successFunction, index);
  }

  function GetReaderFileImageByLightIndex(light, successFunction) {
    client.Post("GetReaderFileImageByLightIndex", successFunction, light);
  }

  function GetReaderFileImageByLightIndexAndPageIndex(
    light,
    page,
    successFunction
  ) {
    client.Post(
      "GetReaderFileImageByLightIndexAndPageIndex",
      successFunction,
      light,
      page
    );
  }

  function GetReaderGraphicsBitmapByFieldType(type, successFunction) {
    client.Post("GetReaderGraphicsBitmapByFieldType", successFunction, type);
  }

  function GetReaderGraphicsFileImageByFieldType(type, successFunction) {
    client.Post("GetReaderGraphicsFileImageByFieldType", successFunction, type);
  }

  function GetRFIDDGRawData(type, successFunction) {
    client.Post("GetRFIDDGRawData", successFunction, type);
  }

  function GetRFIDGraphicsBitmapByFieldType(type, successFunction) {
    client.Post("GetRFIDGraphicsBitmapByFieldType", successFunction, type);
  }

  function GetRFIDGraphicsFileImageByFieldType(type, successFunction) {
    client.Post("GetRFIDGraphicsFileImageByFieldType", successFunction, type);
  }

  function GetSnapshot(light, mode, successFunction) {
    client.Post("GetSnapshot", successFunction, light, mode);
  }

  function GetTextFieldByType(type, successFunction) {
    client.Post("GetTextFieldByType", successFunction);
  }

  function GetTextFieldByTypeAndLCID(type, lcid, successFunction) {
    client.Post("GetTextFieldByTypeAndLCID", successFunction, type, lcid);
  }

  function GetTextFieldByTypeAndSource(
    type,
    source,
    originalSource,
    lcid,
    successFunction
  ) {
    client.Post(
      "GetTextFieldByTypeAndSource",
      successFunction,
      type,
      source,
      originalSource,
      lcid
    );
  }

  function Hide(successFunction) {
    client.Post("Hide", successFunction);
  }

  function HideResultPopup(successFunction) {
    client.Post("HideResultPopup", successFunction);
  }

  function IsReaderResultTypeAvailable(type, successFunction) {
    client.Post("IsReaderResultTypeAvailable", successFunction, type);
  }

  function LED(color, rate, index, successFunction) {
    client.Post("LED", successFunction, color, rate, index);
  }

  function PlaySound(ATimes, successFunction) {
    client.Post("PlaySound", successFunction);
  }

  function RefreshPACertStore(successFunction) {
    client.Post("RefreshPACertStore", successFunction);
  }

  function ReportCurrentSampleIssue(successFunction) {
    client.Post("ReportCurrentSampleIssue", successFunction);
  }

  function RFIDCancelReading(successFunction) {
    client.Post("RFIDCancelReading", successFunction);
  }

  function SetActiveLights(activeLights, successFunction) {
    client.Post("SetActiveLights", successFunction, activeLights);
  }

  function GetActiveLights(successFunction) {
    client.Post("GetActiveLights", successFunction);
  }

  function SaveConfig(successFunction) {
    client.Post("SaveConfig", successFunction);
  }

  function Show(successFunction) {
    client.Post("Show", successFunction);
  }

  function WaitAndReadRFID(successFunction) {
    client.Post("WaitAndReadRFID", successFunction);
  }

  /*=====/Methods (SPECIAL)=====*/

  function ShutdownComputer(doRestart, successFunction) {
    client.Post("RestartSdk", successFunction, doRestart);
  }

  function RestartSdk(successFunction) {
    client.Post("RestartSdk", successFunction);
  }

  function GetServiceVersion(successFunction) {
    client.Post("GetServiceVersion", successFunction);
  }

  function GetSystemDateTime(successFunction) {
    client.Post("GetSystemDateTime", successFunction);
  }

  function SetSystemDateTime(value, successFunction) {
    client.Post("SetSystemDateTime", successFunction, value);
  }

  function NotifyRfidRequestHandled(successFunction) {
    client.Post("NotifyRfidRequestHandled", successFunction);
  }

  function NotifyCalibrationHandled(successFunction) {
    client.Post("NotifyCalibrationHandled", successFunction);
  }

  /*=====EVENTS=====*/

  const OnNotificationOpticalCallback = function () {
    console.log("OnNotificationOpticalCallback");
  };

  const OnImageReadyCallback = function () {
    console.log("OnImageReadyCallback");
  };

  const OnNotificationRFIDCallback = function (ACode, AValue) {
    console.log("OnNotificationRFIDCallback");
    var hiWord = ACode & 0xffff0000;
    if (
      hiWord === eRFID_NotificationCodes.RFID_Notification_PCSC_ReadingDatagroup
    ) {
      var loWord = ACode & 0x0000ffff;
      var dg = getRFIDDG(loWord);
      console.log("dg:", dg);
    }
  };

  //scanned data
  const OnProcessingFinishedCallback = function () {
    console.log("OnProcessingFinishedCallback");
    //Getting scanned images
    IsReaderResultTypeAvailable(
      eRPRM_ResultType.RPRM_ResultType_RawImage,
      function (count) {
        for (var i = 0; i < count; i++) {
          GetReaderFileImage(i, function (data) {
            if (data !== null) {
              const img = "data:image/png;base64," + btoa(data);
              console.log("img:", img);
            }
          });
        }
      }
    );

    //Getting text results comparison
    IsReaderResultTypeAvailable(
      eRPRM_ResultType.RPRM_ResultType_OCRLexicalAnalyze,
      function (count) {
        for (var i = 0; i < count; i++) {
          CheckReaderResultXML(
            eRPRM_ResultType.RPRM_ResultType_OCRLexicalAnalyze,
            i,
            0,
            function (data) {
              const json = ParseData(data);
              console.log(
                eRPRM_ResultType.RPRM_ResultType_OCRLexicalAnalyze + ":",
                JSON.stringify(json)
              );
            }
          );
        }
      }
    );

    //Getting security features
    IsReaderResultTypeAvailable(
      eRPRM_ResultType.RPRM_ResultType_Authenticity,
      function (securityCount) {
        let securityArray = new Array(securityCount);
        for (var i = 0; i < securityCount; i++) {
          let gotSecurityResults = 0;
          let idx = i;
          CheckReaderResultXML(
            eRPRM_ResultType.RPRM_ResultType_Authenticity,
            idx,
            0,
            function (data, type, index) {
              gotSecurityResults++;
              securityArray[idx] = data;

              if (gotSecurityResults === securityCount) {
                for (var k = 0; k < securityCount; k++) {
                  const json = ParseData(securityArray[k]);
                  console.log(
                    eRPRM_ResultType.RPRM_ResultType_Authenticity + ":",
                    JSON.stringify(json)
                  );
                }
              }
            }
          );
        }
      }
    );

    IsReaderResultTypeAvailable(
      eRPRM_ResultType.RPRM_ResultType_Graphics,
      function (count) {
        for (var i = 0; i < count; i++) {
          CheckReaderResultXML(
            eRPRM_ResultType.RPRM_ResultType_Graphics,
            i,
            0,
            function (data) {
              const json = ParseData(data);
              console.log(
                eRPRM_ResultType.RPRM_ResultType_Graphics + ":",
                JSON.stringify(json)
              );
            }
          );
        }
      }
    );

    IsReaderResultTypeAvailable(
      eRPRM_ResultType.RPRM_ResultType_BarCodes,
      function (count) {
        for (var i = 0; i < count; i++) {
          CheckReaderResultXML(
            eRPRM_ResultType.RPRM_ResultType_BarCodes,
            i,
            0,
            function (data) {
              const json = ParseData(data);
              console.log(
                eRPRM_ResultType.RPRM_ResultType_BarCodes + ":",
                JSON.stringify(json)
              );
            }
          );
        }
      }
    );

    CheckRFIDResultXML(
      eRFID_ResultType.RFID_ResultType_RFID_ImageData,
      0,
      function (data) {
        if (data !== null) {
          const json = ParseData(data);
          console.log(
            eRFID_ResultType.RFID_ResultType_RFID_ImageData + ":",
            JSON.stringify(json)
          );
        }
      }
    );

    //Getting RFID technical data
    GetPropertyValue("DoRFID", function (data) {
      if (data) {
        const json = ParseRFIDChipData();
        console.log("RFID technical data:", JSON.stringify(json));
      }
    });
  };

  const OnProcessingStartedCallback = function () {
    console.log("OnProcessingStartedCallback");
  };

  const OnResultReadyCallback = function () {
    console.log("OnResultReadyCallback");
  };

  const OnResultReadyXMLCallback = function () {
    console.log("OnResultReadyXMLCallback");
  };

  const OnRFIDRequestCallback = function () {
    console.log("OnRFIDRequestCallback");
  };

  const OnSystemNotificationCallback = function () {
    console.log("OnSystemNotificationCallback");
  };

  function initRegulaReader(url, isFallback, callback) {
    if (!url) {
      url = "http://localhost:3000";
    }

    // WebSocket client
    console.log(
      `Initializing *WEBSCOKET* connection with URL: ${new URL(url).origin}`
    );
    client = new socketsClient(new URL(url).origin);

    if (callback) {
      callback();
    }
  }

  class socketsClient {
    socket = null;

    constructor(url) {
      this.socket = io(url);

      this.socket.on("connect", () => {
        console.log("Connected to server");
      });

      this.socket.on("disconnect", () => {
        console.log("Disconnected from server");
      });

      this.socket.on("error", () => {
        console.log("Error occured");
      });

      // Setup callbacks based on socket messages coming from server
      this.socket.on("OnNotificationOptical", function (ACode, AValue) {
        if (OnNotificationOpticalCallback !== null)
          OnNotificationOpticalCallback(ACode, AValue);
      });

      this.socket.on("OnImageReady", function (ALight, APageIndex) {
        if (OnImageReadyCallback !== null)
          OnImageReadyCallback(ALight, APageIndex);
      });

      this.socket.on("OnNotificationRFID", function (ACode, AValue) {
        if (OnNotificationRFIDCallback !== null)
          OnNotificationRFIDCallback(ACode, AValue);
      });

      this.socket.on("OnProcessingFinished", function () {
        if (OnProcessingFinishedCallback !== null)
          OnProcessingFinishedCallback();
      });

      this.socket.on("OnProcessingStarted", function () {
        if (OnProcessingStartedCallback !== null) OnProcessingStartedCallback();
      });

      this.socket.on("OnResultReady", function (AType) {
        if (OnResultReadyCallback !== null) OnResultReadyCallback(AType);
      });

      this.socket.on("OnResultReadyXML", function (AType, ResultXML) {
        if (OnResultReadyXMLCallback !== null)
          OnResultReadyXMLCallback(AType, ResultXML);
      });

      this.socket.on("OnRFIDRequest", function (RequestXML) {
        if (OnRFIDRequestCallback !== null) OnRFIDRequestCallback(RequestXML);

        NotifyRfidRequestHandled();
      });

      this.socket.on("OnSystemNotification", function (code, value) {
        if (OnSystemNotificationCallback !== null)
          OnSystemNotificationCallback(code, value);
      });
    }

    Post(url, successFunction) {
      var args = Array.from(arguments).slice(2);

      var callback = function (reply) {
        if (reply.hasOwnProperty("result")) {
          if (successFunction !== null) successFunction(reply.result);
        } else {
          console.log(reply);
        }
      };

      if (args.length > 0) {
        args.push(callback);
        this.socket.emit(url, ...args);
      } else this.socket.emit(url, callback);
    }

    Get(url, successFunction) {
      this.Post(url, successFunction, arguments);
    }
  }

  /*=====Utilities=====*/

  function xmlToJson(xml) {
    let obj = {};

    if (xml.nodeType === 1) {
      // element
      if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          let attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) {
      // text
      obj = xml.nodeValue;
    }

    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        let item = xml.childNodes.item(i);
        let nodeName = item.nodeName;
        if (typeof obj[nodeName] === "undefined") {
          obj[nodeName] = xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push === "undefined") {
            let old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xmlToJson(item));
        }
      }
    }
    return obj;
  }

  function getFromEnum(set, value) {
    for (var k in set) {
      if (set.hasOwnProperty(k)) {
        if (set[k] === value) {
          return k;
        }
      }
    }
    return undefined;
  }

  function GetTranslation(value) {
    var cookieLang = "STR_EN";

    var entry = Strings[value];
    if (entry) {
      var result = entry[cookieLang];
      if (result !== null) return result;
    }

    return value;
  }

  function trimChar(string, charToRemove) {
    while (string.charAt(0) === charToRemove) {
      string = string.substring(1);
    }

    while (string.charAt(string.length - 1) === charToRemove) {
      string = string.substring(0, string.length - 1);
    }

    return string;
  }

  //Helping functions
  function getRFIDDG(loWord) {
    for (var index in eRFID_DataFile_Type) {
      if (eRFID_DataFile_Type[index] === loWord) return index;
    }
    return "undefined";
  }

  function ParseData(data) {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(data, "text/xml");
    var json = xmlToJson(xmlDoc);
    return json;
  }

  function ParseRFIDChipData() {
    CheckRFIDResultXML(
      eRFID_ResultType.RFID_ResultType_RFID_BinaryData,
      0,
      function (data) {
        if (data !== null) {
          var xmlDoc;
          if (window.DOMParser) {
            var parser = new DOMParser();
            xmlDoc = parser.parseFromString(data, "text/xml");
          } // Internet Explorer
          else {
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = false;
            xmlDoc.loadXML(data);
          }

          var json = xmlToJson(xmlDoc);

          return json;
        }
      }
    );
  }

  function AppendAndProcessImages(files) {
    ClearResults(function (reply) {
      var count = 0;
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        var reader = new FileReader();
        reader.onload = (function (file, count) {
          return function (e) {
            var ext = file.name.substring(
              file.name.lastIndexOf("."),
              file.name.length
            );
            AppendImage(e.target.result, ext, 6, 0, function (data) {
              count++;
              if (count === files.length) {
                DoProcessImages(null);
              }
            });
          };
        })(file, count);
        reader.readAsDataURL(file);
      }
    });
  }

  function bin2String(bytes) {
    var str = "";
    var split = bytes.split(" ");

    for (var i = 0; i < split.length; i++) {
      var b = split[i];
      if (b === 0) str += " ";
      else {
        str += String.fromCharCode(b);
      }
    }
    return str;
  }

  return (
    <MainContainer>
      <button
        onClick={() => {
          //Getting everything ready
          initRegulaReader(serviceUrl);
        }}
      >
        Create Websocket
      </button>
      <button onClick={() => {}}>connect</button>
      <button
        onClick={() => {
          client.Post("GetImages", function (result) {
            console.log(result);
          });
        }}
      >
        Post
      </button>
    </MainContainer>
  );
};

export default PassportScanner;
