import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MainModal from 'components/ModalMain';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Head, Container, Description, Title, Link, Footer } from './styles';

const ModalReplyFromDashboard = ({hidden, buttonStyle, buttonLabel="Reply From Dashboard", tenantId }) => {

  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [replyOption, setReplyOption] = useState('Email')
  const handleReply = () => {
    //TODO: Implement reply logic
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 635px;
        .modal-content{
          height: 412px;
          .close {
            z-index: 200;
          }      
          .modal-body {
            position: relative;
            top: 20px;
            height: 100%;
          }
        }
        top: 123px;
      `}
    >
      <Head>Reply to this message:</Head>
      <Description>
        <Title>Nancy Hachisu (via email): </Title>        
        This is a message about lorem ipsum dolor sit amet, consectetur adipiscing
        elit, yada yada yada… This is a message about lorem ipsum dolor sit amet,
        consectetur adipiscing elit, yada yada yada…
        <Link
          onClick={() => history.push(`/guest/${tenantId}`)}
        >
          Read more {'>'}
        </Link>
      </Description>
      <Container>
        <TextArea
          title="REPLY"
          onChange={(e) => { }}
          additionalStyle={`
            flex-basis: 100%;
            
            textarea {
              min-height:80px;
            }
          `}
        />
      </Container>
      <Footer>
        <Dropdown
          options={['Email', 'Text Message', 'Chat']}
          value={replyOption}
          title="SEND AS"
          onChange={(e) => setReplyOption(e.target.value)}
          additionalStyle={`
            width: 30%;
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleReply}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalReplyFromDashboard;
