import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import ModalBookingCancel from 'screens/ModalGroupCancel';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import { Row, RowContainer, TravelInputRow, InputRow } from './styles';
import { useLazyQuery } from '@apollo/client';
import {
  GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE,
  GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE
} from 'graphql/service';
import { OpenNewPageIcon } from 'assets/icons';
import colors from 'constants/colors';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import moment from 'moment';

const BookingInformation = ({
  bookingInfo,
  setBookingInfo,
  index
}) => {

  const history = useHistory()
  const [types, setTypes] = useState({})
  const [originRoom, setOriginRoom] = useState({
    roomTypeName: bookingInfo.roomType.name,
    roomNumber: bookingInfo.roomNumber,
    roomId: bookingInfo.roomId,
    checkInDate: bookingInfo.checkInDate,
    checkOutDate: bookingInfo.checkOutDate,
    price: bookingInfo.price
  })
  const [prices, setPrices] = useState({})
  const [typedRooms, setTypedRooms] = useState({})

  const [
    getAvailableRoomTypesByDateRange,
    {
    data: availableRoomTypesData,
    loading: availableRoomTypesLoading,
    error: availableRoomTypesError
  }] = useLazyQuery(GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE)

  useEffect(()=> {
    getAvailableRoomTypesByDateRange({
      variables: {
        input: {
          checkInDate: bookingInfo.checkInDate,
          checkOutDate: bookingInfo.checkOutDate,
          roomQuantity: 1,
          guestQuantity: bookingInfo.roomType.capacity,
          isWheelchairAccessible: bookingInfo.isWheelchairAccessible
        }
      }, 
      onCompleted: (availableRoomTypesData)=> {
        if(availableRoomTypesData) {
          const types = {}
          const prices = {}
          availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
            if(type.name!=='Amenities') {
              types[type.name] = type.roomTypeId
              prices[type.roomTypeId] = type.pricePerNight
            }        
          })
          types[bookingInfo.roomType.name] = bookingInfo.roomType.roomTypeId
          prices[bookingInfo.roomType.roomTypeId] = bookingInfo.price
          setTypes(types)
          setPrices(prices)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    })
  },[])

  const [
    getAvailableRoomsByRoomTypeIdAndDateRange,
    { data: roomsData, loading, error }
  ] = useLazyQuery(GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE)
  
  useEffect(()=> {
    getAvailableRoomsByRoomTypeIdAndDateRange({
      variables: {
        input: {
          roomTypeId: bookingInfo.roomType.roomTypeId,
          checkInDate: bookingInfo.checkInDate,
          checkOutDate: bookingInfo.checkOutDate
        }
      },
      onCompleted: (roomsData) => {
        if(roomsData) {
          const typedRooms = {}
          roomsData.roomsAvailability.forEach((room)=> {
            typedRooms[room.roomNumber]=room.roomId 
          })
          typedRooms[bookingInfo.roomNumber] = bookingInfo.roomId
          setTypedRooms(typedRooms)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    })
  },[])
  
  const handleSaveChanges = () => {
    if(bookingInfo.dateTimeChanged || bookingInfo.roomChanged) {
      const { checkInDate, checkOutDate, roomId, ratePlanId } = bookingInfo
      const input = {
        checkInDate,
        checkOutDate,
        roomId,
        ratePlanId
      } 
      setOriginRoom({
        roomTypeName: bookingInfo.roomType.name,
        roomNumber: bookingInfo.roomNumber,
        roomId: bookingInfo.roomId,
        checkInDate: bookingInfo.checkInDate,
        checkOutDate: bookingInfo.checkOutDate,
        price: bookingInfo.price
      })
      setBookingInfo({...bookingInfo, dateTimeChanged: false, roomChanged: false})
    }
  }


  const handleChangeRoomType = (e) => {
    if(e!==bookingInfo.roomType.name) {                 
      getAvailableRoomsByRoomTypeIdAndDateRange({
        variables: {
          input: {
            roomTypeId: types[e],
            checkInDate: bookingInfo.checkInDate,
            checkOutDate: bookingInfo.checkOutDate
          }
        },
        onCompleted: (roomsData) => {
          if(roomsData) {
            const typedRooms = {}
            roomsData.roomsAvailability.forEach((room)=> {
              typedRooms[room.roomNumber]=room.roomId     
            })
            if(e===originRoom.roomTypeName) {
              typedRooms[originRoom.roomNumber] = originRoom.roomId
              setBookingInfo({
                ...bookingInfo,
                roomType: {roomTypeId: types[e], name: e},
                price: prices[types[e]],
                roomNumber: originRoom.roomNumber,
                roomId: originRoom.roomId,
                roomChanged: false
              })
            } else {
              setBookingInfo({
                ...bookingInfo,
                roomType: {roomTypeId: types[e], name: e},
                price: prices[types[e]],
                roomNumber: Object.keys(typedRooms)[0],
                roomId: typedRooms[Object.keys(typedRooms)[0]],
                roomChanged: true
              }) 
            }
            setTypedRooms(typedRooms)
          }
        },
        onError: (error) => {
          let content = error.message
          if(error.message.includes('Validation')) {
              content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
          }
          
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      })
    }  
  }

  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;

        .buttonContainer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          column-gap: 10px;

          .openNewPageIcon {
            cursor: pointer;
          }
        }
      `}
    >
      <CardHeader>
        <div className='type'>BOOKING {index + 1}</div>
        <div className='buttonContainer'>
          <div
            className='openNewPageIcon'
            onClick={() => {
              history.push(`/booking-details/${bookingInfo.bookingId}`)
            }}
          >
            <OpenNewPageIcon />
          </div>
          <Button
            mainPage
            success
            onClick={handleSaveChanges}
            additionalStyle={`
              width: 113px;
            `}
          >
            Save Changes
          </Button>
          <ModalBookingCancel
            danger
            buttonLabel={'Remove'}
            buttonStyle={`
              width: 103px;
            `}
            bookingId={bookingInfo.bookingId}
          />
        </div>
      </CardHeader>
      <FormContainer>
        <RowContainer>
          <Row>
            <Dropdown
              mainPage
              title="ROOM #"
              options={Object.keys(typedRooms)}
              value={bookingInfo.roomNumber}
              onChange={(e) => {
                setBookingInfo({...bookingInfo, roomNumber: e, roomId: typedRooms[e], roomChanged: true})
              }}
              additionalStyle={`
                flex-basis: 40%;
                margin-right: 10px;
              `}
            />
            <Dropdown
              mainPage
              title="ROOM TYPE"
              options={Object.keys(types)}
              value={bookingInfo.roomType.name}
              onChange={handleChangeRoomType}
              additionalStyle={`
                flex-basis: 60%;
              `}
            />
          </Row>
          <Row>
            <InputRow>
              <div>
                <Dropdown
                  mainPage
                  options={[1, 2]}
                  value={bookingInfo.roomType.capacity}
                  title="CAPACITY"
                  disabled={true}
                  additionalStyle={`
                    width: 100%;
                  `}
                />
                <Dropdown
                  mainPage
                  options={['true', 'false']}
                  value={bookingInfo.isWheelchairAccessible + ''}
                  title="BARRIER FREE"
                  disabled={true}
                  additionalStyle={`
                    width: 100%;
                  `}
                />
              </div>
            </InputRow>
          </Row>
        </RowContainer>
        <TravelInputRow>
          <div>
            <div className="datePickerContainer">
              <span>ARRIVAL</span>
              <DatePicker
                error={bookingInfo.checkInDate? bookingInfo.checkInDate >= bookingInfo.checkOutDate: false}
                minDate={moment(new Date())}
                dateValue={moment(bookingInfo.checkInDate)}
                dateOnChange={(date) => {
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: date,
                        checkOutDate: bookingInfo.checkOutDate
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[bookingInfo.roomType.name] = bookingInfo.roomType.roomTypeId
                        prices[bookingInfo.roomType.name] = bookingInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setBookingInfo({...bookingInfo, checkInDate: date, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  })
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 130px;
                  font-size: 18px;
                  font-weight: 600;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>ARRIVAL TIME</span>
              <TimePicker
                TimeValue={moment(bookingInfo.checkInDate)}
                setTime={(e)=>{
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: e,
                        checkOutDate: bookingInfo.checkOutDate
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[bookingInfo.roomType.name] = bookingInfo.roomType.roomTypeId
                        prices[bookingInfo.roomType.name] = bookingInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setBookingInfo({...bookingInfo, checkInDate: e, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  }) 
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
          <div>
            <div className="datePickerContainer">
              <span>DEPARTURE</span>
              <DatePicker
                error={bookingInfo.checkInDate? bookingInfo.checkInDate >= bookingInfo.checkOutDate: false}
                minDate={moment(new Date())}
                dateValue={moment(bookingInfo.checkOutDate)}
                dateOnChange={(date) => {
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: bookingInfo.checkOutDate,
                        checkOutDate: date
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[bookingInfo.roomType.name] = bookingInfo.roomType.roomTypeId
                        prices[bookingInfo.roomType.name] = bookingInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setBookingInfo({...bookingInfo, checkOutDate: date, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  })
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 130px;
                  font-size: 18px;
                  font-weight: 600;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>DEPARTURE TIME</span>
              <TimePicker
                TimeValue={moment(bookingInfo.checkOutDate)}
                setTime={(e)=>{
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: bookingInfo.checkInDate,
                        checkOutDate: e
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[bookingInfo.roomType.name] = bookingInfo.roomType.roomTypeId
                        prices[bookingInfo.roomType.name] = bookingInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setBookingInfo({...bookingInfo, checkOutDate: e, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  }) 
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
        </TravelInputRow>
      </FormContainer>
    </Card>
  )
}

export default BookingInformation;