import React from 'react';
import colors from 'constants/colors';

const SideBarTitleContainer = ({ getRootProps, title }) => {
  return (
    <div
      {...getRootProps({
        style: {
          background: colors.black5,
          color: colors.grey5,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          padding: '8px 19px',
          fontSize: '12px',
          fontWeight: 'bold',
        },
      })}>
      {title}
    </div>
  );
};

export default SideBarTitleContainer;
