import colors from 'constants/colors';
import styled from 'styled-components';

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  padding: 0 0 10px 0;
  box-sizing: border-box;

  @media only screen and (max-width: 1200px) {
    ${({desktop})=>desktop? `display: none;`: null};
  }

  @media only screen and (min-width: 1201px) {
    ${({mobile})=>mobile? `display: none;`: null};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: 240px;
    padding-bottom: 60px;
    @media only screen and (min-height: 1021px) {
      margin-top: 320px;
    }

    @media only screen and (min-height: 941px) and (max-height: 1020px) {
      margin-top: 240px;
    }

    @media only screen and (min-height: 851px) and (max-height: 940px) {
      margin-top: 160px;
    }

    @media only screen and (min-height: 771px) and (max-height: 850px) {
      margin-top: 85px;
    }

    @media only screen and (max-height: 770px) {
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 767px) {
    margin-top: 160px;
    height: 210px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 401px) and (max-width: 500px) {
    margin-top: 80px;
    height: 210px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 375px) and (max-width: 400px)  {
    margin-top: 55px;
    height: 210px;
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 374px) {
    margin-top: 25px;
    height: 210px;
    padding-bottom: 60px;
  }
`;

export const LegendItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 14%;
  height: 120px;

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : 'white'};
  }

  .iconLayout {
    width: 20px;
    height: 20px;
  }

  span {
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: ${colors.grey5};
  }

  @media only screen and (max-width: 1200px) {
    flex-basis: 25%;
    height: 100px;

    .iconContainer {
      margin-bottom: 5px;  
    }
  }

  @media only screen and (max-width: 767px) {

    height: 90px;
    
    .iconContainer {
      width: 30px;
      height: 30px;
    }

    .iconLayout {
      width: 15px;
      height: 15px;
    }

    span{
      font-size: 12px;
    }
  }
`;
