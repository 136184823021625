import React from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import { Container } from './styles';
import colors from 'constants/colors';

const AddNote = ({
  onChangeNote,
  onAddNote,
  note,
}) => {
  return (
    <Container>
      <Input
        mainPage
        title="ADD A NOTE"
        onChange={(e)=>onChangeNote(e.target.value)}
        value={note}
        additionalStyle={`          
          @media only screen and (min-width: 767px) and (max-width: 1200px) {
            span {
              font-size: 13px;
              color: ${colors.white1};
            }
            input {
              height: 74px;
            }
          }

          @media only screen and (max-width: 767px) {
            span {
              font-size: 12px;
              color: ${colors.white1};
            }
            input {
              height: 59px;
            }
          }
        `}
      />
      <Button
        mainPage
        onClick={onAddNote}
        additionalStyle={`
          width:113px;

          @media only screen and (min-width: 767px) and (max-width: 1200px) {
            width: 123px;
          }

          @media only screen and (max-width: 767px) {
            width: 110px;
          }
        `}
      >
        Add Note
      </Button>
    </Container>
  );
};

export default AddNote;
