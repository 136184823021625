import React from 'react';
import { RevenueReportTableContainer } from '../styles';

const RevenueReportTable = () => {
  return (
    <RevenueReportTableContainer>
      <thead>
        <tr>
          <th className="titleCell"></th>
          <th className="compareCell">5/24/2020 — 5/24/2020</th>
          <th>5/24/2020 — 5/24/2020</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="titleHighlight">Occupancy</td>
          <td>62%</td>
          <td>62%</td>
        </tr>
        <tr>
          <td className="title">Available</td>
          <td>123</td>
          <td>123</td>
        </tr>
        <tr>
          <td className="title">Occupied</td>
          <td>456</td>
          <td>456</td>
        </tr>
        <tr>
          <td className="title">Out of Order</td>
          <td>23</td>
          <td>24</td>
        </tr>
        <tr>
          <td className="titleHighlight">Average Room Night</td>
          <td>$123</td>
          <td>$123</td>
        </tr>
        <tr>
          <td className="title">Gross</td>
          <td>$123</td>
          <td>$123</td>
        </tr>
        <tr>
          <td className="title">Net</td>
          <td>$123</td>
          <td>$123</td>
        </tr>
        <tr>
          <td className="titleHighlight">Channel Cost</td>
          <td>($1234)</td>
          <td>($1234)</td>
        </tr>
      </tbody>
    </RevenueReportTableContainer>
  );
};

export default RevenueReportTable;
