import React, { useState } from 'react';
import Note from 'components/Note';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Dropdown from 'components/Dropdown';
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import statusMap from 'constants/statusMap'
import { NotesContainer, NavigatorContainer, Navigator } from './styles';

const StatusHistory = ({ roomStatusHistories }) => {

  const [period, setPeriod ] = useState('This week')

  let filteredArray
  switch(period) {
    case 'This week': 
      filteredArray = roomStatusHistories.filter(roomStatusRecord => moment(roomStatusRecord.createdAt) > moment().startOf('week'))
      break
    default:
      filteredArray = roomStatusHistories
  }
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 5

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const previousPage = () => {
    setPageNumber((pageNumber) => pageNumber-1)
  }

  const nextPage = () => {
    setPageNumber((pageNumber) => pageNumber+1)
  }

  const handles = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => previousPage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <Card
      additionalStyle={`
        min-height: 396px;

        @media only screen and (min-width: 767px) and (max-width: 1200px) {
          min-height: 406px;
        }
      `}
    >
      <CardHeader
        additionalStyle={`
          margin-bottom: 10px;
        `}
      >
        <div className='type'>STATUS HISTORY</div>
        <div className='buttonContainer'>
          <div className='subTitle'>VIEW TIME PERIOD</div>
          <Dropdown
            mainPage
            options={['This week']}
            value={period}
            onChange={(e) => setPeriod(e)}
            additionalStyle={`
              width: 130px;
            `}
          />
        </div>
      </CardHeader>
      <NotesContainer {...handles}>
        {paginate(filteredArray, pageSize, pageNumber).map((history, index) => (
          <Note
            key={index}
            date={moment(history.createdAt).format('M/D/YYYY h:mm A')}
            description={`Marked as ${statusMap[history.status]}`}
            disabled
          />
        ))}
      </NotesContainer>
      {
        filteredArray.length>pageSize ?
          (
            <NavigatorContainer style={{marginTop:'-19px'}}>
              <Navigator
                disabled={pageNumber<=1} 
                onClick={previousPage}
              >
                {'<'} Back
              </Navigator>
              <Navigator
                disabled={pageNumber>= filteredArray.length/pageSize}
                onClick={nextPage}
              >
                Next {'>'}
              </Navigator>
              {/* <Swiper
                disabled={pageNumber<=1}
              >
                {'<'}
              </Swiper>
              <Swiper>
                SWIPE
              </Swiper>
              <Swiper
                disabled={pageNumber>= filteredArray.length/pageSize}
              >
                {'>'}
              </Swiper> */}
            </NavigatorContainer>
          ): null
      }

    </Card>
  );
};

export default StatusHistory;
