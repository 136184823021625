import styled from 'styled-components';
import colors from 'constants/colors';
import ImageBackground from 'assets/icons/ImageBackground.svg'

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${ImageBackground});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid ${colors.grey9};
`;

export const ImageSlideContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  img {
    width: 34px;
    height: 34px;
    position: absolute;
    top: calc(50% - 17px);
    cursor: pointer;
  }

  .left {
    left: 13px;
  }

  .right {
    right: 13px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    img {
      width: 53px;
      height: 53px;
      top: calc(50% - 27px);
    }

    .left {
      left: 20px;
    }

    .right {
      right: 20px;
    }

  }

  @media only screen and (max-width: 767px) {
    img {
      width: 32px;
      height: 32px;
      top: calc(50% - 16px);
    }
  }
`;

export const SlideContent = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  transform: translateX(-${(props) => props.translateWidth}%);
  transition: transform ease-out ${(props) => props.transition}s;
  display: flex;
`;

export const Slide = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  @media only screen and (min-width: 1201px) {
    display: none !important;
  }
`;

export const DotsContainer = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 44%;
  z-index: 100;

  @media only screen and (max-width: 1200px) {
    display: none !important;
  }
`;

export const MobileDotsContainer = styled.div`
  width: 100%;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 40%;
  margin-top: 10px;
  z-index: 100;

  @media only screen and (min-width: 1201px) {
    display: none !important;
  }
`;

export const DotContainer = styled.span`
  width: 13px;
  height: 13px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active
      ? `${colors.white1}`
      : `${colors.black5}`};
`;
