import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FloorCard from './components/FloorCard';
import Alerts from './components/Alerts';
import Messages from './components/Messages';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ROOMS } from 'graphql/service';
import {
  Content,
  ContentColumn,
  Container,
} from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const Home = () => {

  const [eighthFloorRooms, setEighthFloorRooms] = useState({})
  const [ninthFloorRooms, setNinthFloorRooms] = useState({})
  const [tenthFloorRooms, setTenthFloorRooms] = useState({})

  const [getAllRooms,{ data: roomsData, loading, error }] = useLazyQuery(GET_ALL_ROOMS, {
    pollInterval: 60000,
    fetchPolicy: 'no-cache',
    onCompleted: (roomsData) => {
      let newEighthFloorRooms = {}
      let newNinthFloorRooms = {}
      let newTenthFloorRooms = {}
      roomsData.validRooms.forEach(room => {
        switch (room.floor) {
          case '8':
            newEighthFloorRooms[room.roomNumber] = room
            break
          case '9':
            newNinthFloorRooms[room.roomNumber] = room
            break
          case '10':
            newTenthFloorRooms[room.roomNumber] = room
            break
          default:
            break
        }
      })
      setEighthFloorRooms(newEighthFloorRooms)
      setNinthFloorRooms(newNinthFloorRooms)
      setTenthFloorRooms(newTenthFloorRooms)
    },
    onError: (error) => {
      let content = error.message
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  });

  useEffect(() => {
    getAllRooms()
  },[])

  return (
    <>
      <Helmet>
        <title>Home - Rook Hotel PMS</title>
      </Helmet>
      <Header
        additionalStyle={`
          border: none;
        `}
      />
      <Content>
        <ContentColumn>
          <Container>
            <FloorCard eighthFloorRooms={eighthFloorRooms} ninthFloorRooms={ninthFloorRooms} tenthFloorRooms={tenthFloorRooms}/>
          </Container>
        </ContentColumn>
        <ContentColumn desktop>
          <Container>
            <Alerts />
          </Container>
        </ContentColumn>
        <ContentColumn desktop>
          <Container>  
            <Messages />
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  )
}

export default Home;