import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from 'components/Header';
import Button from 'components/Button';
import {
  Content,
  ContentColumn,
  Container
} from 'components/BookingMain/styles';
import PicturesWall from './components/PicturesWall';

const RoomTypeDetails = () => {
  const roomTypeId = useParams().roomTypeId;

  const handleSaveChanges = () => {
    // Handle save changes logic here
  };

  return (
    <>
      <Helmet>
        <title>Room Type Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className='title'>
              Room Type Details
            </div>
            <div className='buttonContainer'>
              <div>
                <Button
                  mainPage
                  success
                  onClick={handleSaveChanges}
                  additionalStyle={`
                    width: 122px;
                    margin-left: 14px;

                    @media only screen and (min-width: 767px) and (max-width: 1200px) {
                      width: 141px;
                    }
      
                    @media only screen and (max-width: 767px) {
                      width: 110px;
                    }
                  `}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </>
        }
      />
      <Content>
        <ContentColumn>
          <Container>
            <PicturesWall />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
          </Container>
        </ContentColumn>
      </Content>
    </>
  );
};

export default RoomTypeDetails;
