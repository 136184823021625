import styled from 'styled-components';
import colors from 'constants/colors';

export const Content = styled.div`
  display: flex;
  background-color: ${colors.black5};
  height: calc(100vh - 50px);
  min-height: 610px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    min-height: 460px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 501px) and (max-width: 767px) {
    min-height: 570px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 401px) and (max-width: 500px) {
    min-height: 490px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 375px) and (max-width: 400px)  {
    min-height: 460px;
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 374px) {
    min-height: 430px;
    padding-bottom: 60px;
  }

  & > div:first-child {
    width: 45%;
    min-width: 680px;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      min-width: 360px;
    }

    & div > h4 {
      color: ${colors.grey12};
      margin-left: 12px;
    }
  }

  & > div:nth-child(2) {
    width: 25%;
    min-width: 240px;
  }

  & > div:nth-child(3) {
    width: 30%;
    min-width: 280px;
  }
`;

export const ContentColumn = styled.div`
  background-color: ${colors.black5};
  height: 100%;
  @media only screen and (max-width: 1200px) {
    ${({ desktop }) => (desktop ? `display: none;` : null)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background-color: ${colors.black5};
  letter-spacing: 2.5px;
  height: 100%;

  .type {
    font-size: 16px;
    font-weight: bold;
    color: ${colors.grey12};
  }
`;