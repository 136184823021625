import React, { useState } from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Notes from 'components/Notes';
import ModalChangeRoom from 'screens/ModalChangeRoom';
import ModalGenerateKey from 'screens/ModalGenerateKey';
import ModalKeyPrinting from 'screens/ModalKeyPrinting';
import { RoomInputRow, RoomInputContainer, NoteHeader } from './styles';
import {
  CREATE_ROOM_NOTE,
  DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID
} from 'graphql/service';
import { useMutation } from '@apollo/client';
import statusMap from 'constants/statusMap'
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const Room = ({
  btnHidden,
  roomNotes,
  roomType,
  roomPrice,
  status,
  roomNumber,
  roomId,
  checkInDate,
  checkOutDate, 
  bookingId,
  refetch,
  addNoteHidden,
  disabled,
  setRatePlanId
}) => {

  const userId = localStorage.getItem('userId')
  const [note, setNote] = useState('');
  const pageSize=3

  const handleChangeRatePlan = (e) => {
    setRatePlanId(e)
  }

  const [addNote] = useMutation(CREATE_ROOM_NOTE, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [removeNote] = useMutation(DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })
  
  const [deviceId, setDeviceId] = useState('Kiosk 1')
  const [KeyPrintModalIsOpen, setKeyPrintModalIsOpen] = useState(false)

  const onAddNote = async () => {
    if (note) {
      addNote({
        variables: {
          input: {
            roomId,
            createdBy: userId,
            notes: note,
            isRead: false
          },
        },
      })
      setNote('')
    } else {
      const content = "Note can't be empty"
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  }

  const onRemoveNote = async (noteId) => {
    removeNote({ variables: { noteId: noteId } })
  }

  return (
    <>
      <Card
        additionalStyle={`
          padding: 10px 15px 15px 15px;
        `}
      >
        <CardHeader>
          <div className='type'>ROOM</div>
          <div className='buttonContainer'>
            {
              btnHidden ? null: (             
                <>
                  <ModalChangeRoom
                    buttonStyle={`
                      width: 133px;

                      @media only screen and (min-width: 767px) and (max-width: 1200px) {
                        width: 160px;
                      }
        
                      @media only screen and (max-width: 767px) {
                        width: 119px;
                      }
                    `}
                    roomNumber={roomNumber}
                    roomId={roomId}
                    roomStatus={statusMap[status]}
                    bookingId={bookingId}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    roomType={roomType} 
                    refetch={refetch}
                  />
                  <ModalGenerateKey
                    buttonStyle={`
                      width: 113px;
                      margin-left: 14px;

                      @media only screen and (min-width: 767px) and (max-width: 1200px) {
                        width: 123px;
                      }
        
                      @media only screen and (max-width: 767px) {
                        width: 110px;
                      }
                    `}
                    roomNumber={roomNumber}
                    deviceId={deviceId}
                    setDeviceId={setDeviceId}
                    setKeyPrintModalIsOpen={setKeyPrintModalIsOpen}
                  />
                  <ModalKeyPrinting
                    isOpen={KeyPrintModalIsOpen}
                    setIsOpen={setKeyPrintModalIsOpen}
                    buttonLabel={""}
                    deviceId={deviceId} 
                  />
                </>
              )
            }
          </div>
        </CardHeader>
        <RoomInputContainer>
          <RoomInputRow>
            <Input
              readOnly={disabled}
              mainPage
              title="ROOM #"
              value={roomNumber}
              additionalStyle={`
                width: 20%;
              `}
            />
            <Dropdown
              disabled={statusMap[status]?.includes('Occupied') || disabled}
              mainPage
              options={[
                'Room Inspected',
                'Room Clean',
                'Out of Order',
                'Room Dirty',
              ]}
              value={statusMap[status]}
              title="STATUS"
              additionalStyle={`
                width: 80%;
              `}
            />
          </RoomInputRow>
          <RoomInputRow>
            <Input
              readOnly={disabled}
              mainPage
              title="PRICE"
              value={roomPrice.toFixed(2)}
              additionalStyle={`
                width: 32%;
                input {
                  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='white' font-size='14' font-weight='600' font-family='Titillium Web'>$</text></svg>");
                  background-repeat: no-repeat;
                  background-position: 10px 50%;
                  padding-left: 22px;
                }
              `}
            />
          </RoomInputRow>
        </RoomInputContainer>
        <NoteHeader>NOTES</NoteHeader>
        <Notes
          notes={roomNotes}
          note={note}
          setNote={setNote}
          onAddNote={onAddNote}
          onRemoveNote={onRemoveNote}
          pageSize={pageSize}
          addNoteHidden={addNoteHidden}
        />
      </Card>
    </>
  );
};

export default Room;
