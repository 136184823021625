/***READERDEMO Enums formatted with proper syntax***/
export const eVisualFieldType = {
  ft_Document_Class_Code: 0,
  ft_Issuing_State_Code: 1,
  ft_Document_Number: 2,
  ft_Date_of_Expiry: 3,
  ft_Date_of_Issue: 4,
  ft_Date_of_Birth: 5,
  ft_Place_of_Birth: 6,
  ft_Personal_Number: 7,
  ft_Surname: 8,
  ft_Given_Names: 9,
  ft_Mothers_Name: 10,
  ft_Nationality: 11,
  ft_Sex: 12,
  ft_Height: 13,
  ft_Weight: 14,
  ft_Eyes_Color: 15,
  ft_Hair_Color: 16,
  ft_Address: 17,
  ft_Donor: 18,
  ft_Social_Security_Number: 19,
  ft_DL_Class: 20,
  ft_DL_Endorsed: 21,
  ft_DL_Restriction_Code: 22,
  ft_DL_Under_21_Date: 23,
  ft_Authority: 24,
  ft_Surname_And_Given_Names: 25,
  ft_Nationality_Code: 26,
  ft_Passport_Number: 27,
  ft_Invitation_Number: 28,
  ft_Visa_ID: 29,
  ft_Visa_Class: 30,
  ft_Visa_SubClass: 31,
  ft_MRZ_String1: 32,
  ft_MRZ_String2: 33,
  ft_MRZ_String3: 34,
  ft_MRZ_Type: 35,
  ft_Optional_Data: 36,
  ft_Document_Class_Name: 37,
  ft_Issuing_State_Name: 38,
  ft_Place_of_Issue: 39,
  ft_Document_Number_Checksum: 40,
  ft_Date_of_Birth_Checksum: 41,
  ft_Date_of_Expiry_Checksum: 42,
  ft_Personal_Number_Checksum: 43,
  ft_FinalChecksum: 44,
  ft_Passport_Number_Checksum: 45,
  ft_Invitation_Number_Checksum: 46,
  ft_Visa_ID_Checksum: 47,
  ft_Surname_And_Given_Names_Checksum: 48,
  ft_Visa_Valid_Until_Checksum: 49,
  ft_Other: 50,
  ft_MRZ_Strings: 51,
  ft_Name_Suffix: 52,
  ft_Name_Prefix: 53,
  ft_Date_of_Issue_Checksum: 54,
  ft_Date_of_Issue_CheckDigit: 55,
  ft_Document_Series: 56,
  ft_RegCert_RegNumber: 57,
  ft_RegCert_CarModel: 58,
  ft_RegCert_CarColor: 59,
  ft_RegCert_BodyNumber: 60,
  ft_RegCert_CarType: 61,
  ft_RegCert_MaxWeight: 62,
  ft_Reg_Cert_Weight: 63,
  ft_Address_Area: 64,
  ft_Address_State: 65,
  ft_Address_Building: 66,
  ft_Address_House: 67,
  ft_Address_Flat: 68,
  ft_Place_of_Registration: 69,
  ft_Date_of_Registration: 70,
  ft_Resident_From: 71,
  ft_Resident_Until: 72,
  ft_Authority_Code: 73,
  ft_Place_of_Birth_Area: 74,
  ft_Place_of_Birth_StateCode: 75,
  ft_Address_Street: 76,
  ft_Address_City: 77,
  ft_Address_Jurisdiction_Code: 78,
  ft_Address_Postal_Code: 79,
  ft_Document_Number_CheckDigit: 80,
  ft_Date_of_Birth_CheckDigit: 81,
  ft_Date_of_Expiry_CheckDigit: 82,
  ft_Personal_Number_CheckDigit: 83,
  ft_FinalCheckDigit: 84,
  ft_Passport_Number_CheckDigit: 85,
  ft_Invitation_Number_CheckDigit: 86,
  ft_Visa_ID_CheckDigit: 87,
  ft_Surname_And_Given_Names_CheckDigit: 88,
  ft_Visa_Valid_Until_CheckDigit: 89,
  ft_Permit_DL_Class: 90,
  ft_Permit_Date_of_Expiry: 91,
  ft_Permit_Identifier: 92,
  ft_Permit_Date_of_Issue: 93,
  ft_Permit_Restriction_Code: 94,
  ft_Permit_Endorsed: 95,
  ft_Issue_Timestamp: 96,
  ft_Number_of_Duplicates: 97,
  ft_Medical_Indicator_Codes: 98,
  ft_Non_Resident_Indicator: 99,
  ft_Visa_Type: 100,
  ft_Visa_Valid_From: 101,
  ft_Visa_Valid_Until: 102,
  ft_Duration_of_Stay: 103,
  ft_Number_of_Entries: 104,
  ft_Day: 105,
  ft_Month: 106,
  ft_Year: 107,
  ft_Unique_Customer_Identifier: 108,
  ft_Commercial_Vehicle_Codes: 109,
  ft_AKA_Date_of_Birth: 110,
  ft_AKA_Social_Security_Number: 111,
  ft_AKA_Surname: 112,
  ft_AKA_Given_Names: 113,
  ft_AKA_Name_Suffix: 114,
  ft_AKA_Name_Prefix: 115,
  ft_Mailing_Address_Street: 116,
  ft_Mailing_Address_City: 117,
  ft_Mailing_Address_Jurisdiction_Code: 118,
  ft_Mailing_Address_Postal_Code: 119,
  ft_Audit_Information: 120,
  ft_Inventory_Number: 121,
  ft_Race_Ethnicity: 122,
  ft_Jurisdiction_Vehicle_Class: 123,
  ft_Jurisdiction_Endorsement_Code: 124,
  ft_Jurisdiction_Restriction_Code: 125,
  ft_Family_Name: 126,
  ft_Given_Names_RUS: 127,
  ft_Visa_ID_RUS: 128,
  ft_Fathers_Name: 129,
  ft_Fathers_Name_RUS: 130,
  ft_Surname_And_Given_Names_RUS: 131,
  ft_Place_Of_Birth_RUS: 132,
  ft_Authority_RUS: 133,
  ft_Issuing_State_Code_Numeric: 134,
  ft_Nationality_Code_Numeric: 135,
  ft_Engine_Power: 136,
  ft_Engine_Volume: 137,
  ft_Chassis_Number: 138,
  ft_Engine_Number: 139,
  ft_Engine_Model: 140,
  ft_Vehicle_Category: 141,
  ft_Identity_Card_Number: 142,
  ft_Control_No: 143,
  ft_Parrent_s_Given_Names: 144,
  ft_Second_Surname: 145,
  ft_Middle_Name: 146,
  ft_RegCert_VIN: 147,
  ft_RegCert_VIN_CheckDigit: 148,
  ft_RegCert_VIN_Checksum: 149,
  ft_Line1_CheckDigit: 150,
  ft_Line2_CheckDigit: 151,
  ft_Line3_CheckDigit: 152,
  ft_Line1_Checksum: 153,
  ft_Line2_Checksum: 154,
  ft_Line3_Checksum: 155,
  ft_RegCert_RegNumber_CheckDigit: 156,
  ft_RegCert_RegNumber_Checksum: 157,
  ft_RegCert_Vehicle_ITS_Code: 158,
  ft_Card_Access_Number: 159,
  ft_Marital_Status: 160,
  ft_Company_Name: 161,
  ft_Special_Notes: 162,
  ft_Surname_of_Spose: 163,
  ft_Tracking_Number: 164,
  ft_Booklet_Number: 165,
  ft_Children: 166,
  ft_Copy: 167,
  ft_Serial_Number: 168,
  ft_Dossier_Number: 169,
  ft_AKA_Surname_And_Given_Names: 170,
  ft_Territorial_Validity: 171,
  ft_MRZ_Strings_With_Correct_CheckSums: 172,
  ft_DL_CDL_Restriction_Code: 173,
  ft_DL_Under_18_Date: 174,
  ft_DL_Record_Created: 175,
  ft_DL_Duplicate_Date: 176,
  ft_DL_Iss_Type: 177,
  ft_Military_Book_Number: 178,
  ft_Destination: 179,
  ft_Blood_Group: 180,
  ft_Sequence_Number: 181,
  ft_RegCert_BodyType: 182,
  ft_RegCert_CarMark: 183,
  ft_Transaction_Number: 184,
  ft_Age: 185,
  ft_Folio_Number: 186,
  ft_Voter_Key: 187,
  ft_Address_Municipality: 188,
  ft_Address_Location: 189,
  ft_Section: 190,
  ft_OCR_Number: 191,
  ft_Federal_Elections: 192,
  ft_Reference_Number: 193,
  ft_Optional_Data_Checksum: 194,
  ft_Optional_Data_CheckDigit: 195,
  ft_Visa_Number: 196,
  ft_Visa_Number_Checksum: 197,
  ft_Visa_Number_CheckDigit: 198,
  ft_Voter: 199,
  ft_Previous_Type: 200,
  ft_FieldFromMRZ: 220,
  ft_CurrentDate: 221,
  ft_Status_Date_of_Expiry: 251,
  ft_Banknote_Number: 252,
  ft_CSC_Code: 253,
  ft_Artistic_Name: 254,
  ft_Academic_Title: 255,
  ft_Address_Country: 256,
  ft_Address_Zipcode: 257,
  ft_eID_Residence_Permit1: 258,
  ft_eID_Residence_Permit2: 259,
  ft_eID_PlaceOfBirth_Street: 260,
  ft_eID_PlaceOfBirth_City: 261,
  ft_eID_PlaceOfBirth_State: 262,
  ft_eID_PlaceOfBirth_Country: 263,
  ft_eID_PlaceOfBirth_Zipcode: 264,
  ft_CDL_Class: 265,
  ft_DL_Under_19_Date: 266,
  ft_Weight_pounds: 267,
  ft_Limited_Duration_Document_Indicator: 268,
  ft_Endorsement_Expiration_Date: 269,
  ft_Revision_Date: 270,
  ft_Compliance_Type: 271,
  ft_Family_name_truncation: 272,
  ft_First_name_truncation: 273,
  ft_Middle_name_truncation: 274,
  ft_Exam_Date: 275,
  ft_Organization: 276,
  ft_Department: 277,
  ft_Pay_Grade: 278,
  ft_Rank: 279,
  ft_Benefits_Number: 280,
  ft_Sponsor_Service: 281,
  ft_Sponsor_Status: 282,
  ft_Sponsor: 283,
  ft_Relationship: 284,
  ft_USCIS: 285,
  ft_Category: 286,
  ft_Conditions: 287,
  ft_Identifier: 288,
  ft_Configuration: 289,
  ft_Discretionary_data: 290,
  ft_Line1_Optional_Data: 291,
  ft_Line2_Optional_Data: 292,
  ft_Line3_Optional_Data: 293,
  ft_EQV_Code: 294,
  ft_ALT_Code: 295,
  ft_Binary_Code: 296,
  ft_Pseudo_Code: 297,
  ft_Fee: 298,
  ft_Stamp_Number: 299,
  ft_GNIB_Number: 340,
  ft_Dept_Number: 341,
  ft_Telex_Code: 342,
  ft_Allergies: 343,
  ft_Sp_Code: 344,
  ft_Court_Code: 345,
  ft_Cty: 346,
  ft_Sponsor_SSN: 347,
  ft_DoD_Number: 348,
  ft_MC_Novice_Date: 349,
  ft_DUF_Number: 350,
  ft_AGY: 351,
  ft_PNR_Code: 352,
  ft_From_Airport_Code: 353,
  ft_To_Airport_Code: 354,
  ft_Flight_Number: 355,
  ft_Date_of_Flight: 356,
  ft_Seat_Number: 357,
  ft_Date_of_Issue_Boarding_Pass: 358,
  ft_CCW_Until: 359,
  ft_Reference_Number_Checksum: 360,
  ft_Reference_Number_CheckDigit: 361,
  ft_Room_Number: 362,
  ft_Religion: 363,
  ft_RemainderTerm: 364,
  ft_Electronic_Ticket_Indicator: 365,
  ft_Compartment_Code: 366,
  ft_CheckIn_Sequence_Number: 367,
  ft_Airline_Designator_of_boarding_pass_issuer: 368,
  ft_Airline_Numeric_Code: 369,
  ft_Ticket_Number: 370,
  ft_Frequent_Flyer_Airline_Designator: 371,
  ft_Frequent_Flyer_Number: 372,
  ft_Free_Baggage_Allowance: 373,
  ft_PDF417Codec: 374,
  ft_Identity_Card_Number_Checksum: 375,
  ft_Identity_Card_Number_CheckDigit: 376,
  ft_Veteran: 377,
  ft_DLClassCode_A1_From: 378,
  ft_DLClassCode_A1_To: 379,
  ft_DLClassCode_A1_Notes: 380,
  ft_DLClassCode_A_From: 381,
  ft_DLClassCode_A_To: 382,
  ft_DLClassCode_A_Notes: 383,
  ft_DLClassCode_B_From: 384,
  ft_DLClassCode_B_To: 385,
  ft_DLClassCode_B_Notes: 386,
  ft_DLClassCode_C1_From: 387,
  ft_DLClassCode_C1_To: 388,
  ft_DLClassCode_C1_Notes: 389,
  ft_DLClassCode_C_From: 390,
  ft_DLClassCode_C_To: 391,
  ft_DLClassCode_C_Notes: 392,
  ft_DLClassCode_D1_From: 393,
  ft_DLClassCode_D1_To: 394,
  ft_DLClassCode_D1_Notes: 395,
  ft_DLClassCode_D_From: 396,
  ft_DLClassCode_D_To: 397,
  ft_DLClassCode_D_Notes: 398,
  ft_DLClassCode_BE_From: 399,
  ft_DLClassCode_BE_To: 400,
  ft_DLClassCode_BE_Notes: 401,
  ft_DLClassCode_C1E_From: 402,
  ft_DLClassCode_C1E_To: 403,
  ft_DLClassCode_C1E_Notes: 404,
  ft_DLClassCode_CE_From: 405,
  ft_DLClassCode_CE_To: 406,
  ft_DLClassCode_CE_Notes: 407,
  ft_DLClassCode_D1E_From: 408,
  ft_DLClassCode_D1E_To: 409,
  ft_DLClassCode_D1E_Notes: 410,
  ft_DLClassCode_DE_From: 411,
  ft_DLClassCode_DE_To: 412,
  ft_DLClassCode_DE_Notes: 413,
  ft_DLClassCode_M_From: 414,
  ft_DLClassCode_M_To: 415,
  ft_DLClassCode_M_Notes: 416,
  ft_DLClassCode_L_From: 417,
  ft_DLClassCode_L_To: 418,
  ft_DLClassCode_L_Notes: 419,
  ft_DLClassCode_T_From: 420,
  ft_DLClassCode_T_To: 421,
  ft_DLClassCode_T_Notes: 422,
  ft_DLClassCode_AM_From: 423,
  ft_DLClassCode_AM_To: 424,
  ft_DLClassCode_AM_Notes: 425,
  ft_DLClassCode_A2_From: 426,
  ft_DLClassCode_A2_To: 427,
  ft_DLClassCode_A2_Notes: 428,
  ft_DLClassCode_B1_From: 429,
  ft_DLClassCode_B1_To: 430,
  ft_DLClassCode_B1_Notes: 431,
  ft_Surname_at_Birth: 432,
  ft_Civil_Status: 433,
  ft_Number_of_Seats: 434,
  ft_Number_of_Standing_Places: 435,
  ft_Max_Speed: 436,
  ft_Fuel_Type: 437,
  ft_EC_Environmental_Type: 438,
  ft_Power_Weight_Ratio: 439,
  ft_Max_Mass_of_Trailer_Braked: 440,
  ft_Max_Mass_of_Trailer_Unbraked: 441,
  ft_Transmission_Type: 442,
  ft_Trailer_Hitch: 443,
  ft_Accompanied_by: 444,
  ft_Police_District: 445,
  ft_First_Issue_Date: 446,
  ft_Payload_Capacity: 447,
  ft_Number_of_Axels: 448,
  ft_Permissible_Axle_Load: 449,
  ft_Precinct: 450,
  ft_Invited_by: 451,
  ft_Purpose_of_Entry: 452,
  ft_Skin_Color: 453,
  ft_Complexion: 454,
  ft_Airport_From: 455,
  ft_Airport_To: 456,
  ft_Airline_Name: 457,
  ft_Airline_Name_Frequent_Flyer: 458,
  ft_In_Tanks: 460,
  ft_Exept_In_Tanks: 461,
  ft_Fast_Track: 462,
  ft_Owner: 463,
  ft_MRZ_Strings_ICAO_RFID: 464,
  ft_Number_of_Card_Issuance: 465,
  ft_Number_of_Card_Issuance_Checksum: 466,
  ft_Number_of_Card_Issuance_CheckDigit: 467,
  ft_Century_Date_of_Birth: 468,
  ft_DLClassCode_A3_From: 469,
  ft_DLClassCode_A3_To: 470,
  ft_DLClassCode_A3_Notes: 471,
  ft_DLClassCode_C2_From: 472,
  ft_DLClassCode_C2_To: 473,
  ft_DLClassCode_C2_Notes: 474,
  ft_DLClassCode_B2_From: 475,
  ft_DLClassCode_B2_To: 476,
  ft_DLClassCode_B2_Notes: 477,
  ft_DLClassCode_D2_From: 478,
  ft_DLClassCode_D2_To: 479,
  ft_DLClassCode_D2_Notes: 480,
  ft_DLClassCode_B2E_From: 481,
  ft_DLClassCode_B2E_To: 482,
  ft_DLClassCode_B2E_Notes: 483,
  ft_DLClassCode_G_From: 484,
  ft_DLClassCode_G_To: 485,
  ft_DLClassCode_G_Notes: 486,
  ft_DLClassCode_J_From: 487,
  ft_DLClassCode_J_To: 488,
  ft_DLClassCode_J_Notes: 489,
  ft_DLClassCode_LC_From: 490,
  ft_DLClassCode_LC_To: 491,
  ft_DLClassCode_LC_Notes: 492,
  ft_BankCardNumber: 493,
  ft_BankCardValidThru: 494,
  ft_TaxNumber: 495,
  ft_HealthNumber: 496,
  ft_GrandfatherName: 497,
  ft_Selectee_Indicator: 498,
  ft_Mother_Surname: 499,
  ft_Mother_GivenName: 500,
  ft_Father_Surname: 501,
  ft_Father_GivenName: 502,
  ft_Mother_DateOfBirth: 503,
  ft_Father_DateOfBirth: 504,
  ft_Mother_PersonalNumber: 505,
  ft_Father_PersonalNumber: 506,
  ft_Mother_PlaceOfBirth: 507,
  ft_Father_PlaceOfBirth: 508,
  ft_Mother_CountryOfBirth: 509,
  ft_Father_CountryOfBirth: 510,
  ft_Date_First_Renewal: 511,
  ft_Date_Second_Renewal: 512,
  ft_PlaceOfExamination: 513,
  ft_ApplicationNumber: 514,
  ft_VoucherNumber: 515,
  ft_AuthorizationNumber: 516,
  ft_Faculty: 517,
  ft_FormOfEducation: 518,
  ft_DNINumber: 519,
  ft_RetirementNumber: 520,
  ft_ProfessionalIdNumber: 521,
  ft_Age_at_Issue: 522,
  ft_Years_Since_Issue: 523,
};

export const eVisualFieldTypeStrings = {
  strDocumentClassCode: 0,
  strIssuingStateCode: 1,
  strDocumentNumber: 2,
  strDateofExpiry: 3,
  strDateofIssue: 4,
  strDateofBirth: 5,
  strPlaceofBirth: 6,
  strPersonalNumber: 7,
  strSurname: 8,
  strGivenNames: 9,
  strMothersName: 10,
  strNationality: 11,
  strSex: 12,
  strHeight: 13,
  strWeight: 14,
  strEyesColor: 15,
  strHairColor: 16,
  strAddress: 17,
  strDonor: 18,
  strSocialSecurityNumber: 19,
  strDLClass: 20,
  strDLEndorsed: 21,
  strDLRestrictionCode: 22,
  strDLUnder21Date: 23,
  strAuthority: 24,
  strSurnameAndGivenNames: 25,
  strNationalityCode: 26,
  strPassportNumber: 27,
  strInvitationNumber: 28,
  strVisaID: 29,
  strVisaClass: 30,
  strVisaSubClass: 31,
  strMRZString1: 32,
  strMRZString2: 33,
  strMRZString3: 34,
  strMRZType: 35,
  strOptionalData: 36,
  strDocumentClassName: 37,
  strIssuingStateName: 38,
  strPlaceOfIssue: 39,
  strOther: 50,
  strMRZStrings: 51,
  strNameSuffix: 52,
  strNamePrefix: 53,
  strDateofIssueCheckDigit: 55,
  strDocumentSeries: 56,
  strRegCertRegNumber: 57,
  strRegCertCarModel: 58,
  strRegCertCarColor: 59,
  strRegCertBodyNumber: 60,
  strRegCertCarType: 61,
  strRegCertMaxWeight: 62,
  strRegCertWeight: 63,
  strAddressArea: 64,
  strAddressState: 65,
  strAddressBuilding: 66,
  strAddressHouse: 67,
  strAddressFlat: 68,
  strPlaceofRegistration: 69,
  strDateofRegistration: 70,
  strResidentFrom: 71,
  strResidentUntil: 72,
  strAuthorityCode: 73,
  strPlaceofBirthArea: 74,
  strPlaceofBirthStateCode: 75,
  strAddressStreet: 76,
  strAddressCity: 77,
  strAddressJurisdictionCode: 78,
  strAddressPostalCode: 79,
  strDocumentNumberCheckDigit: 80,
  strDateofBirthCheckDigit: 81,
  strDateofExpiryCheckDigit: 82,
  strPersonalNumberCheckDigit: 83,
  strFinalCheckDigit: 84,
  strPassportNumberCheckDigit: 85,
  strInvitationNumberCheckDigit: 86,
  strVisaIDCheckDigit: 87,
  strSurnameAndGivenNamesCheckDigit: 88,
  strVisaValidUntilCheckDigit: 89,
  strPermitDLClass: 90,
  strPermitDateofExpiry: 91,
  strPermitIdentifier: 92,
  strPermitDateofIssue: 93,
  strPermitRestrictionCode: 94,
  strPermitEndorsed: 95,
  strIssueTimestamp: 96,
  strNumberofDuplicates: 97,
  strMedicalIndicatorCodes: 98,
  strNonResidentIndicator: 99,
  strVisaType: 100,
  strVisaValidFrom: 101,
  strVisaValidUntil: 102,
  strDurationofStay: 103,
  strNumberofEntries: 104,
  strUniqueCustomerIdentifier: 108,
  strCommercialVehicleCodes: 109,
  strAKADateofBirth: 110,
  strAKASocialSecurityNumber: 111,
  strAKAName: 112,
  strAKANameSuffix: 113,
  strAKANamePrefix: 114,
  strAKANamePrefixDbl: 115,
  strMailingAddressStreet: 116,
  strMailingAddressCity: 117,
  strMailingAddressJurisdictionCode: 118,
  strMailingAddressPostalCode: 119,
  strAuditInformation: 120,
  strInventoryNumber: 121,
  strRaceEthnicity: 122,
  strJurisdictionVehicleClass: 123,
  strJurisdictionEndorsementCode: 124,
  strJurisdictionRestrictionCode: 125,
  strFamilyName: 126,
  strGivenNamesRUS: 127,
  strVisaIDRUS: 128,
  strFathersName: 129,
  strFathersNameRUS: 130,
  strSurnameAndGivenNamesRUS: 131,
  strPlaceOfBirthRUS: 132,
  strAuthorityRUS: 133,
  strIssuingStateCodeNumeric: 134,
  strNationalityCodeNumeric: 135,
  strEnginePower: 136,
  strEngineVolume: 137,
  strChassisNumber: 138,
  strEngineNumber: 139,
  strEngineModel: 140,
  strVehicleCategory: 141,
  strIdentityCardNumber: 142,
  strControlNo: 143,
  strParrentsGivenNames: 144,
  strSecondSurname: 145,
  strMiddleName: 146,
  strRegCertVIN: 147,
  strRegCertVINCheckDigit: 148,
  strRegCertVINChecksum: 149,
  strLine1CheckDigit: 150,
  strLine2CheckDigit: 151,
  strLine3CheckDigit: 152,
  strLine1Checksum: 153,
  strLine2Checksum: 154,
  strLine3Checksum: 155,
  strRegCertRegNumberCheckDigit: 156,
  strRegCertRegNumberChecksum: 157,
  strRegCertVehicleITSCode: 158,
  strCAN: 159,
  strMaritalStatus: 160,
  strCompanyName: 161,
  strSpecialNotes: 162,
  strSurnameofSpose: 163,
  strTrackingNumber: 164,
  strBookletNumber: 165,
  strChildren: 166,
  strCopy: 167,
  strSerialNumber: 168,
  strDossierNumber: 169,
  strAKASurnameAndGivenNames: 170,
  strTerritorialValidity: 171,
  strMRZStringsWithCorrectCheckSums: 172,
  strDLCDLRestrictionCode: 173,
  strDLUnder18Date: 174,
  strDLRecordCreated: 175,
  strDLDuplicateDate: 176,
  strSequenceNumber: 177,
  strMilitaryBookNumber: 178,
  strDestination: 179,
  strBloodGroup: 180,
  strSequenceNumber: 181,
  strRegCertBodyType: 182,
  strRegCertCarMark: 183,
  strTransactionNumber: 184,
  strAge: 185,
  strFolioNumber: 186,
  strVoterKey: 187,
  strAddressMunicipality: 188,
  strAddressLocation: 189,
  strSection: 190,
  strOCRNumber: 191,
  strFederalElections: 192,
  strReferenceNumber: 193,
  strOptionalDataChecksum: 194,
  strOptionalDataCheckDigit: 195,
  strVisaNumber: 196,
  strVisaNumberChecksum: 197,
  strVisaNumberCheckDigit: 198,
  strVoter: 199,
  strPreviousType: 200,
  strFieldFromMRZ: 220,
  strStatusDateofExpiry: 251,
  strBanknoteNumber: 252,
  strCSCCode: 253,
  strArtisticName: 254,
  strAcademicTitle: 255,
  strAdressCountry: 256,
  strAdressZipcode: 257,
  streIDResidencePermit1: 258,
  streIDResidencePermit2: 259,
  streIDPlaceOfBirthStreet: 260,
  streIDPlaceOfBirthCity: 261,
  streIDPlaceOfBirthState: 262,
  streIDPlaceOfBirthCountry: 263,
  streIDPlaceOfBirthZipcode: 264,
  strCDLClass: 265,
  strDLUnder19Date: 266,
  strWeightPound: 267,
  strLimitedDurationDocumentIndicator: 268,
  strEndorsementExpirationDate: 269,
  strRevisionDate: 270,
  strComplianceType: 271,
  strFamilyNameTruncation: 272,
  strFirstNameTruncation: 273,
  strMiddleNameTruncation: 274,
  strExamDate: 275,
  strOrganization: 276,
  strDepartment: 277,
  strPayGrade: 278,
  strRank: 279,
  strBenefitsNumber: 280,
  strSponsorService: 281,
  strSponsorStatus: 282,
  strSponsor: 283,
  strRelationship: 284,
  strUSCIS: 285,
  strCategory: 286,
  strConditions: 287,
  strIdentifier: 288,
  strConfiguration: 289,
  strDiscretionaryData: 290,
  strLine1OptionalData: 291,
  strLine2OptionalData: 292,
  strLine3OptionalData: 293,
  strEQVCode: 294,
  strALTCode: 295,
  strBinaryCode: 296,
  strPseudoCode: 297,
  strFee: 298,
  strStampNumber: 299,
  strSBHSecurityOptions: 300,
  strSBHIntegrityOptions: 301,
  strDateofCreation: 302,
  strValidityPeriod: 303,
  strPatronHeaderVersion: 304,
  strBDBType: 305,
  strBiometricType: 306,
  strBiometricSubtype: 307,
  strBiometricProductID: 308,
  strBiometricFormatOwner: 309,
  strBiometricFormatType: 310,
  strPhone: 311,
  strProfession: 312,
  strTitle: 313,
  strPersonalSummary: 314,
  strOtherValidID: 315,
  strCustodyInfo: 316,
  strOtherName: 317,
  strObservations: 318,
  strTax: 319,
  strDateofPersonalization: 320,
  strPersonalizationSN: 321,
  strOtherPersonName: 322,
  strPersonToNotifyDateofRecord: 323,
  strPersonToNotifyName: 324,
  strPersonToNotifyPhone: 325,
  strPersonToNotifyAddress: 326,
  strDSCertificateIssuer: 327,
  strDSCertificateSubject: 328,
  strDSCertificateValidFrom: 329,
  strDSCertificateValidTo: 330,
  strVRCDataObjectEntry: 331,
  strGNIBNumber: 340,
  strDeptNumber: 341,
  strTelexCode: 342,
  strAllergies: 343,
  strSpCode: 344,
  strCourtCode: 345,
  strCty: 346,
  strSponsorSSN: 347,
  strDoDNumber: 348,
  strMCNoviceDate: 349,
  strDUFNumber: 350,
  strAGY: 351,
  strPNRCode: 352,
  strFromAirportCode: 353,
  strToAirportCode: 354,
  strFlightNumber: 355,
  strDateofFlight: 356,
  strSeatNumber: 357,
  strDateofIssueBoardingPass: 358,
  strCCWUntil: 359,
  strReferenceNumberChecksum: 360,
  strReferenceNumberCheckDigit: 361,
  strRoomNumber: 362,
  strReligion: 363,
  strRemainderTerm: 364,
  strElectronicTicketIndicator: 365,
  strCompartmentCode: 366,
  strCheckInSequenceNumber: 367,
  strAirlineDesignatorofboardingpassissuer: 368,
  strAirlineNumericCode: 369,
  strTicketNumber: 370,
  strFrequentFlyerAirlineDesignator: 371,
  strFrequentFlyerNumber: 372,
  strFreeBaggageAllowance: 373,
  strPDF417Codec: 374,
  strIdentityCardNumberChecksum: 375,
  strIdentityCardNumberCheckDigit: 376,
  strVeteran: 377,
  strDLClassCodeA1From: 378,
  strDLClassCodeA1To: 379,
  strDLClassCodeA1Notes: 380,
  strDLClassCodeAFrom: 381,
  strDLClassCodeATo: 382,
  strDLClassCodeANotes: 383,
  strDLClassCodeBFrom: 384,
  strDLClassCodeBTo: 385,
  strDLClassCodeBNotes: 386,
  strDLClassCodeC1From: 387,
  strDLClassCodeC1To: 388,
  strDLClassCodeC1Notes: 389,
  strDLClassCodeCFrom: 390,
  strDLClassCodeCTo: 391,
  strDLClassCodeCNotes: 392,
  strDLClassCodeD1From: 393,
  strDLClassCodeD1To: 394,
  strDLClassCodeD1Notes: 395,
  strDLClassCodeDFrom: 396,
  strDLClassCodeDTo: 397,
  strDLClassCodeDNotes: 398,
  strDLClassCodeBEFrom: 399,
  strDLClassCodeBETo: 400,
  strDLClassCodeBENotes: 401,
  strDLClassCodeC1EFrom: 402,
  strDLClassCodeC1ETo: 403,
  strDLClassCodeC1ENotes: 404,
  strDLClassCodeCEFrom: 405,
  strDLClassCodeCETo: 406,
  strDLClassCodeCENotes: 407,
  strDLClassCodeD1EFrom: 408,
  strDLClassCodeD1ETo: 409,
  strDLClassCodeD1ENotes: 410,
  strDLClassCodeDEFrom: 411,
  strDLClassCodeDETo: 412,
  strDLClassCodeDENotes: 413,
  strDLClassCodeMFrom: 414,
  strDLClassCodeMTo: 415,
  strDLClassCodeMNotes: 416,
  strDLClassCodeLFrom: 417,
  strDLClassCodeLTo: 418,
  strDLClassCodeLNotes: 419,
  strDLClassCodeTFrom: 420,
  strDLClassCodeTTo: 421,
  strDLClassCodeTNotes: 422,
  strDLClassCodeAMFrom: 423,
  strDLClassCodeAMTo: 424,
  strDLClassCodeAMNotes: 425,
  strDLClassCodeA2From: 426,
  strDLClassCodeA2To: 427,
  strDLClassCodeA2Notes: 428,
  strDLClassCodeB1From: 429,
  strDLClassCodeB1To: 430,
  strDLClassCodeB1Notes: 431,
  strSurnameAtBirth: 432,
  strCivilStatus: 433,
  strNumberofSeats: 434,
  strNumberofStandingPlaces: 435,
  strMaxSpeed: 436,
  strFuelType: 437,
  strECEnvironmentalType: 438,
  strPowerWeightRatio: 439,
  strMaxMassofTrailerBraked: 440,
  strMaxMassofTrailerUnbraked: 441,
  strTransmissionType: 442,
  strTrailerHitch: 443,
  strAccompaniedby: 444,
  strPoliceDistrict: 445,
  strFirstIssueDate: 446,
  strPayloadCapacity: 447,
  strNumberofAxels: 448,
  strPermissibleAxleLoad: 449,
  strPrecinct: 450,
  strInvitedBy: 451,
  strPurposeOfEntry: 452,
  strSkinColor: 453,
  strComplexion: 454,
  strAirportFrom: 455,
  strAirportTo: 456,
  strAirlineName: 457,
  strAirlineNameFrequentFlyer: 458,
  strLicenseNumber: 459,
  strInTanks: 460,
  strExeptInTanks: 461,
  strFastTrack: 462,
  strOwner: 463,
  strMRZLinesICAORFID: 464,
  strNumberOfCardIssuance: 465,
  strNumberOfCardIssuanceChecksum: 466,
  strNumberOfCardIssuanceCheckDigit: 467,
  strCenturyDateOfBirth: 468,
  strDLClassCodeA3From: 469,
  strDLClassCodeA3To: 470,
  strDLClassCodeA3Notes: 471,
  strDLClassCodeC2From: 472,
  strDLClassCodeC2To: 473,
  strDLClassCodeC2Notes: 474,
  strDLClassCodeB2From: 475,
  strDLClassCodeB2To: 476,
  strDLClassCodeB2Notes: 477,
  strDLClassCodeD2From: 478,
  strDLClassCodeD2To: 479,
  strDLClassCodeD2Notes: 480,
  strDLClassCodeB2EFrom: 481,
  strDLClassCodeB2ETo: 482,
  strDLClassCodeB2ENotes: 483,
  strDLClassCodeGFrom: 484,
  strDLClassCodeGTo: 485,
  strDLClassCodeGNotes: 486,
  strDLClassCodeJFrom: 487,
  strDLClassCodeJTo: 488,
  strDLClassCodeJNotes: 489,
  strDLClassCodeLCFrom: 490,
  strDLClassCodeLCTo: 491,
  strDLClassCodeLCNotes: 492,
  strBankCardNumber: 493,
  strBankCardValidThru: 494,
  strTaxNumber: 495,
  strHealthNumber: 496,
  strGrandfatherName: 497,
  strSelecteeIndicator: 498,
  strMotherSurname: 499,
  strMotherGivenName: 500,
  strFatherSurname: 501,
  strFatherGivenName: 502,
  strMotherDateOfBirth: 503,
  strFatherDateOfBirth: 504,
  strMotherPersonalNumber: 505,
  strFatherPersonalNumber: 506,
  strMotherPlaceOfBirth: 507,
  strFatherPlaceOfBirth: 508,
  strMotherCountryOfBirth: 509,
  strFatherCountryOfBirth: 510,
  strDateFirstRenewal: 511,
  strDateSecondRenewal: 512,
  strPlaceOfExamination: 513,
  strApplicationNumber: 514,
  strVoucherNumber: 515,
  strAuthorizationNumber: 516,
  strFaculty: 517,
  strFormOfEducation: 518,
  strDNINumber: 519,
  strRetirementNumber: 520,
  strProfessionalIdNumber: 521,
  strAgeAtIssue: 522,
  strYearsSinceIssue: 523,
  strDLClassCodeBTPFrom: 524,
  strDLClassCodeBTPNotes: 525,
  strDLClassCodeBTPTo: 526,
  strDLClassCodeC3From: 527,
  strDLClassCodeC3Notes: 528,
  strDLClassCodeC3To: 529,
  strDLClassCodeEFrom: 530,
  strDLClassCodeENotes: 531,
  strDLClassCodeETo: 532,
  strDLClassCodeFA1From: 533,
  strDLClassCodeFA1Notes: 534,
  strDLClassCodeFA1To: 535,
  strDLClassCodeFAFrom: 536,
  strDLClassCodeFANotes: 537,
  strDLClassCodeFATo: 538,
  strDLClassCodeFBFrom: 539,
  strDLClassCodeFBNotes: 540,
  strDLClassCodeFBTo: 541,
  strDLClassCodeFFrom: 542,
  strDLClassCodeFNotes: 543,
  strDLClassCodeFTo: 544,
  strDLClassCodeG1From: 545,
  strDLClassCodeG1Notes: 546,
  strDLClassCodeG1To: 547,
  strDLClassCodeHFrom: 548,
  strDLClassCodeHNotes: 549,
  strDLClassCodeHTo: 550,
  strDLClassCodeIFrom: 551,
  strDLClassCodeINotes: 552,
  strDLClassCodeITo: 553,
  strDLClassCodeKFrom: 554,
  strDLClassCodeKNotes: 555,
  strDLClassCodeKTo: 556,
  strDLClassCodeLKFrom: 557,
  strDLClassCodeLKNotes: 558,
  strDLClassCodeLKTo: 559,
  strDLClassCodeNFrom: 560,
  strDLClassCodeNNotes: 561,
  strDLClassCodeNTo: 562,
  strDLClassCodeSFrom: 563,
  strDLClassCodeSNotes: 564,
  strDLClassCodeSTo: 565,
  strDLClassCodeTBFrom: 566,
  strDLClassCodeTBNotes: 567,
  strDLClassCodeTBTo: 568,
  strDLClassCodeTMFrom: 569,
  strDLClassCodeTMNotes: 570,
  strDLClassCodeTMTo: 571,
  strDLClassCodeTRFrom: 572,
  strDLClassCodeTRNotes: 573,
  strDLClassCodeTRTo: 574,
  strDLClassCodeTVFrom: 575,
  strDLClassCodeTVNotes: 576,
  strDLClassCodeTVTo: 577,
  strDLClassCodeVFrom: 578,
  strDLClassCodeVNotes: 579,
  strDLClassCodeVTo: 580,
  strDLClassCodeWFrom: 581,
  strDLClassCodeWNotes: 582,
  strDLClassCodeWTo: 583,
};

export const eGraphicFieldType = {
  gf_Portrait: 201,
  gf_Fingerprint: 202,
  gf_Eye: 203,
  gf_Signature: 204,
  gt_BarCode: 205,
  gt_Proof_Of_Citizenship: 206,
  gt_Document_Front: 207,
  gt_Document_Rear: 208,
  gf_ColorDynamic: 209,
  gf_GhostPortrait: 210,
  gt_Other: 250,
  gf_Finger_LeftThumb: 300,
  gf_Finger_LeftIndex: 301,
  gf_Finger_LeftMiddle: 302,
  gf_Finger_LeftRing: 303,
  gf_Finger_LeftLittle: 304,
  gf_Finger_RightThumb: 305,
  gf_Finger_RightIndex: 306,
  gf_Finger_RightMiddle: 307,
  gf_Finger_RightRing: 308,
  gf_Finger_RightLittle: 309,
};

export const eGraphicFieldTypeStrings = {
  strPortrait: 201,
  strFingerprint: 202,
  strEye: 203,
  strSignature: 204,
  strBarCode: 205,
  strGhostPortrait: 210,
  strOther: 250,
  strfppLeftThumb: 300,
  strfppLeftIndexFinger: 301,
  strfppLeftMiddleFinger: 302,
  strfppLeftRingFinger: 303,
  strfppLeftLittleFinger: 304,
  strfppRightThumb: 305,
  strfppRightIndexFinger: 306,
  strfppRightMiddleFinger: 307,
  strfppRightRingFinger: 308,
  strfppRightLittleFinger: 309,
};

export const eBarCodeModuleType = {
  MODULETYPE_TEXT: 0,
  MODULETYPE_BYTE: 1,
  MODULETYPE_NUM: 2,
  MODULETYPE_SHIFT: 3,
};

export const eBarCodeModuleTypeStrings = {
  strBCMText: 0,
  strBCMByte: 1,
  strBCMNum: 2,
  strBCMShift: 3,
  strBCMAll: 4,
};

export const eRFID_BaudRate = {
  rfbr_106: 1,
  rfbr_212: 2,
  rfbr_424: 4,
  rfbr_848: 8,
};

export const eRFID_BaudRateStrings = {
  str106: 1,
  str212: 2,
  str424: 4,
  str848: 8,
};

export const eRFDataGroups = {
  RFDG_DG1: 1,
  RFDG_DG2: 2,
  RFDG_DG3: 4,
  RFDG_DG4: 8,
  RFDG_DG5: 16,
  RFDG_DG6: 32,
  RFDG_DG7: 64,
  RFDG_DG8: 128,
  RFDG_DG9: 256,
  RFDG_DG10: 512,
  RFDG_DG11: 1024,
  RFDG_DG12: 2048,
  RFDG_DG13: 4096,
  RFDG_DG14: 8192,
  RFDG_DG15: 16384,
  RFDG_DG16: 32768,
  RFDG_SOD: 65536,
  RFDG_USER: 1048576,
  RFDG_DG_All: -1,
};

export const eRFDataGroupsStrings = {
  strDG1: 1,
  strDG2: 2,
  strDG3: 4,
  strDG4: 8,
  strDG5: 16,
  strDG6: 32,
  strDG7: 64,
  strDG8: 128,
  strDG9: 256,
  strDG10: 512,
  strDG11: 1024,
  strDG12: 2048,
  strDG13: 4096,
  strDG14: 8192,
  strDG15: 16384,
  strDG16: 32768,
  strSOD: 65536,
};

export const eRPRM_Authenticity = {
  RPRM_Authenticity_None: 0,
  RPRM_Authenticity_UV_Luminescence: 1,
  RPRM_Authenticity_IR_B900: 2,
  RPRM_Authenticity_Image_Pattern: 4,
  RPRM_Authenticity_Axial_Protection: 8,
  RPRM_Authenticity_UV_Fibers: 16,
  RPRM_Authenticity_IR_Visibility: 32,
  RPRM_Authenticity_OCRSecurityText: 64,
  RPRM_Authenticity_IPI: 128,
  RPRM_Authenticity_IR_Photo: 256,
  RPRM_Authenticity_Photo_Embed_Type: 512,
  RPRM_Authenticity_OVI: 1024,
  RPRM_Authenticity_IR_Luminescence: 2048,
  RPRM_Authenticity_Holograms: 4096,
  RPRM_Authenticity_Photo_Area: 8192,
  RPRM_Authenticity_BackgroundComparison: 16384,
  RPRM_Authenticity_Portrait_Comparison: 32768,
  RPRM_Authenticity_BarcodeFormatCheck: 65536,
};

export const eRPRM_AuthenticityStrings = {
  strUVLuminescence: 1,
  strIRB900: 2,
  strUVImage: 4,
  strAxialProtection: 8,
  strUVFibers: 16,
  strIRVis: 32,
  strSecurityText: 64,
  strIPI: 128,
  strPhotoEmbedType: 512,
  strOVICheck: 1024,
  strHolograms: 4096,
  strPhotoArea: 8192,
  strUVBackgroundComp: 16384,
  strPortraitComparison: 32768,
  strBarcodeFormatCheck: 65536,
};

export const eRPRM_NotificationCodes = {
  RPRM_Notification_Error: 0,
  RPRM_Notification_DeviceDisconnected: 1,
  RPRM_Notification_DocumentReady: 2,
  RPRM_Notification_Scanning: 4,
  RPRM_Notification_DeviceTick: 7,
  RPRM_Notification_Calibration: 8,
  RPRM_Notification_CalibrationProgress: 9,
  RPRM_Notification_CalibrationStep: 10,
  RPRM_Notification_EnumeratingDevices: 12,
  RPRM_Notification_ConnectingDevice: 13,
  RPRM_Notification_DocumentCanBeRemoved: 14,
  RPRM_Notification_LidOpen: 15,
  RPRM_Notification_Processing: 16,
  RPRM_Notification_DownloadingCalibrationInfo: 17,
};

export const eRFID_NotificationCodes = {
  RFID_Notification_Error: 65536,
  RFID_Notification_DocumentReady: 65537,
  RFID_Notification_ReadProtocol4: 65539,
  RFID_Notification_ReadProtocol3: 65546,
  RFID_Notification_Progress: 65547,
  RFID_Notification_TA_Step: 65550,
  RFID_Notification_SM_Required: 65551,
  RFID_Notification_ISOError: 69632,
  RFID_Notification_PA_Request: 77824,
  RFID_Notification_SM_Established: 81935,
  RFID_Notification_PCSC_ReaderDisconnected: 131072,
  RFID_Notification_PCSC_ReaderListChanged: 131073,
  RFID_Notification_PCSC_BytesReceived: 131074,
  RFID_Notification_PCSC_TotalReadingTime: 131075,
  RFID_Notification_PCSC_DataReceived: 131076,
  RFID_Notification_PCSC_BytesSent: 131077,
  RFID_Notification_PCSC_TotalReadingSpeed: 131078,
  RFID_Notification_PCSC_TotalProcessTime: 131079,
  RFID_Notification_PCSC_ReaderListChanging: 131080,
  RFID_Notification_PCSC_ExtLengthSupport: 131088,
  RFID_Notification_PA_CertificateChain: 131089,
  RFID_Notification_PA_CertificateChainItem: 131090,
  RFID_Notification_Scenario: 131104,
  RFID_Notification_PCSC_ReadingDatagroup: 196608,
  RFID_Notification_PCSC_FileNotFound: 262144,
  RFID_Notification_PCSC_EndOfFile: 327680,
  RFID_Notification_PCSC_FileAccessDenied: 393216,
  RFID_Notification_PCSC_ApplicationSelected: 458752,
  RFID_Notification_ACProcedure_Start: 524288,
  RFID_Notification_ACProcedure_Finish: 589824,
  RFID_Notification_PA_SecurityObjectCheck: 655360,
  RFID_Notification_PA_FileCheck: 720896,
  RFID_Notification_PCSC_UpdatingDatagroup: 786432,
  RFID_Notification_AuxiliaryDataValidation: 851968,
  RFID_Notification_RI_SectorID: 917504,
  RFID_Notification_Biometrics_EmptyPlaceholder: 983040,
};

export const eRFID_ResultType = {
  RFID_ResultType_RFID_RawData: 101,
  RFID_ResultType_RFID_TextData: 102,
  RFID_ResultType_RFID_ImageData: 103,
  RFID_ResultType_RFID_BinaryData: 104,
  RFID_ResultType_RFID_OriginalGraphics: 105,
};

export const eRFID_DataFile_Type = {
  dftUnspecified: 0,
  dftPassport_DG1: 1,
  dftPassport_DG2: 2,
  dftPassport_DG3: 3,
  dftPassport_DG4: 4,
  dftPassport_DG5: 5,
  dftPassport_DG6: 6,
  dftPassport_DG7: 7,
  dftPassport_DG8: 8,
  dftPassport_DG9: 9,
  dftPassport_DG10: 10,
  dftPassport_DG11: 11,
  dftPassport_DG12: 12,
  dftPassport_DG13: 13,
  dftPassport_DG14: 14,
  dftPassport_DG15: 15,
  dftPassport_DG16: 16,
  dftPassport_DG17: 17,
  dftPassport_DG18: 18,
  dftPassport_DG19: 19,
  dftPassport_DG20: 20,
  dftPassport_SOD: 21,
  dftPassport_CVCA: 22,
  dftPassport_COM: 23,
  dftID_DG1: 101,
  dftID_DG2: 102,
  dftID_DG3: 103,
  dftID_DG4: 104,
  dftID_DG5: 105,
  dftID_DG6: 106,
  dftID_DG7: 107,
  dftID_DG8: 108,
  dftID_DG9: 109,
  dftID_DG10: 110,
  dftID_DG11: 111,
  dftID_DG12: 112,
  dftID_DG13: 113,
  dftID_DG14: 114,
  dftID_DG15: 115,
  dftID_DG16: 116,
  dftID_DG17: 117,
  dftID_DG18: 118,
  dftID_DG19: 119,
  dftID_DG20: 120,
  dftID_DG21: 121,
  dftDL_COM: 150,
  dftDL_DG1: 151,
  dftDL_DG2: 152,
  dftDL_DG3: 153,
  dftDL_DG4: 154,
  dftDL_DG5: 155,
  dftDL_DG6: 156,
  dftDL_DG7: 157,
  dftDL_DG8: 158,
  dftDL_DG9: 159,
  dftDL_DG10: 160,
  dftDL_DG11: 161,
  dftDL_DG12: 162,
  dftDL_DG13: 163,
  dftDL_DG14: 164,
  dftDL_SOD: 165,
  dftDL_CE: 166,
  dftPACE_CardAccess: 200,
  dftPACE_CardSecurity: 201,
  dftPACE_ChipSecurity: 202,
  dftMIFARE_Data: 300,
  dftMIFARE_Validity: 301,
  dftAuthenticityV2: 302,
  dftATR: 400,
  dft_eSign_PK: 500,
  dft_eSign_SignedData: 501,
  dftCertificate: 600,
  dftMasterList: 601,
  dftDefectList: 602,
  dftApp_Directory: 700,
  dftSession: 701,
  dftUserDefined: 1000,
};

export const eRFID_ErrorCodes = {
  RFID_Error_NoError: 1,
  RFID_Error_AlreadyDone: 2,
  RFID_LAYER6_FILE_EOF1: -2147458430,
  RFID_LAYER6_PWD_DEACTIVATED: -2147458429,
  RFID_LAYER6_PWD_BLOCKED: -2147458112,
  RFID_LAYER6_PWD_SUSPENDED: -2147458111,
  RFID_LAYER6_PWD_BLOCKED_2: -2147456637,
  RFID_LAYER6_PWD_DEACTIVATED_2: -2147456636,
  RFID_LAYER6_PWD_SUSPENDED_2: -2147456635,
  RFID_LAYER6_INCORRECT_PARAMS: -2147456384,
  RFID_LAYER6_FILE_NOT_FOUND: -2147456382,
  RFID_LAYER6_NO_REFERENCE_DATA: -2147456376,
  RFID_LAYER6_FILE_EOF2: -2147456256,
  RFID_Error_GraphManager: -2147418112,
  RFID_Error_NoChipDetected: -2147418111,
  RFID_Error_NotAvailable: -2147418110,
  RFID_Error_InvalidParameter: -2147418108,
  RFID_Error_NotInitialized: -2147418107,
  RFID_Error_NotEnoughMemory: -2147418106,
  RFID_Error_InvalidDirectory: -2147418104,
  RFID_Error_UnknownCommand: -2147418103,
  RFID_Error_FileIOError: -2147418102,
  RFID_Error_Busy: -2147418101,
  RFID_Error_OldFirmware: -2147418100,
  RFID_Error_PCSC_Failed: -2147352576,
  RFID_Error_PCSC_ReaderNotAvailable: -2147352575,
  RFID_Error_PCSC_CantConnectCard: -2147352574,
  RFID_Error_PCSC_CardIsNotConnected: -2147352573,
  RFID_Error_PCSC_OperationCancelled: -2147352572,
  RFID_Error_PCSC_CardIsBusy: -2147352571,
  RFID_Error_PCSC_FailedSCard: -2147352570,
  RFID_Error_PCSC_ExtLe_Failed: -2147352560,
  RFID_LAYER6_PWD_FAILED: -2146409536,
  RFID_Error_NotPerformed: -2097152000,
  RFID_Error_Session_IsClosed: -2097151999,
  RFID_Error_Session_Terminal_UnsupportedOperation: -2097151998,
  RFID_Error_Session_TerminalType_Unknown: -2097151984,
  RFID_Error_Session_TerminalType_BadCertificate: -2097151983,
  RFID_Error_Session_TerminalType_NotSet: -2097151982,
  RFID_Error_Session_ProcedureType_Unknown: -2097151981,
  RFID_Error_Session_ProcedureType_Unsupported: -2097151980,
  RFID_Error_Session_ProcedureType_NotSet: -2097151979,
  RFID_Error_Session_AccessKey_UnknownType: -2097151978,
  RFID_Error_Session_AccessKey_UnsupportedSMType: -2097151977,
  RFID_Error_Session_AccessKey_IncorrectSMType: -2097151976,
  RFID_Error_Session_AccessKey_Restricted: -2097151975,
  RFID_Error_Session_AccessKey_IncorrectData: -2097151974,
  RFID_Error_Session_AccessKey_NotSet: -2097151973,
  RFID_Error_Session_PwdManagement_NotAuthorized: -2097151972,
  RFID_Error_Session_AccessControl_UnknownType: -2097151968,
  RFID_Error_Session_AccessControl_RequiresSM: -2097151967,
  RFID_Error_Session_AccessControl_RequiresPACE: -2097151966,
  RFID_Error_Session_AccessControl_RequiresCAKeys: -2097151965,
  RFID_Error_Session_AccessControl_RequiresTA: -2097151964,
  RFID_Error_Session_AccessControl_RequiresCA: -2097151963,
  RFID_Error_Session_AccessControl_IncorrectOptionCA: -2097151962,
  RFID_Error_Session_AccessControl_CA_Failed: -2097151961,
  RFID_Error_Session_AccessControl_TA_Failed: -2097151960,
  RFID_Error_Session_AccessControl_AA_Failed: -2097151959,
  RFID_Error_Session_AccessControl_RI_Failed: -2097151958,
  RFID_Error_Session_PA_SignatureCheckFailed: -2097151952,
  RFID_Error_Session_PA_HashCheckFailed: -2097151951,
  RFID_Error_Session_InvalidAuxData_DateOfExpiry: -2097151936,
  RFID_Error_Session_InvalidAuxData_DateOfBirth: -2097151935,
  RFID_Error_Session_InvalidAuxData_CommunityID: -2097151934,
  RFID_Error_Session_eSign_RequiresAppSelection: -2097151920,
  RFID_Error_Session_eSign_PIN_NotSet: -2097151919,
  RFID_Error_Session_eSign_PIN_NotVerified: -2097151918,
  RFID_Error_Session_IncorrectData: -2097151904,
  RFID_Error_Session_File_NotEnoughData: -2097086464,
  RFID_Error_Session_File_IncorrectData: -2097020928,
  RFID_Error_Session_File_UnexpectedData: -2096955392,
  RFID_Error_Session_File_Contents_UnexpectedData: -2096889856,
  RFID_Error_Session_File_WrongTag: -2096824320,
  RFID_Error_Session_File_CantUseData: -2096758784,
  RFID_Error_Session_File_CantReadData: -2096693248,
  RFID_Error_Session_File_AccessDenied: -2096627712,
  RFID_LAYER6_SECURITY_MANAGER: -2046820352,
  RFID_LAYER6_APP_SELECTION_FAILURE: -2046820351,
  RFID_LAYER6_MUTUAL_AUTH_MAC_FAIL: -2046820096,
  RFID_LAYER6_MUTUAL_AUTH_ENC_FAIL: -2046820095,
  RFID_LAYER6_MUTUAL_AUTH_FAILURE: -2046820094,
  RFID_LAYER6_SM_DO8E_MISSING: -2046819840,
  RFID_LAYER6_SM_DO87_MISSING: -2046819839,
  RFID_LAYER6_SM_DO99_MISSING: -2046819838,
  RFID_LAYER6_SM_MAC_INCORRECT: -2046819837,
  RFID_LAYER6_SM_DO87_INCORRECT: -2046819836,
  RFID_LAYER6_NON_TLV_RESPONSE_DATA: -2046819584,
  RFID_LAYER6_WRONG_RND_ICC_LENGTH: -2046819583,
  RFID_LAYER6_INT_AUTH_FAILURE: -2046819582,
  RFID_LAYER6_MSE_SET_KAT_FAILURE: -2046819581,
  RFID_LAYER6_MSE_SET_DST_FAILURE: -2046819580,
  RFID_LAYER6_PSO_CERTIFICATE_FAILURE: -2046819579,
  RFID_LAYER6_MSE_SET_AT_FAILURE: -2046819578,
  RFID_LAYER6_GET_CHALLENGE_FAILURE: -2046819577,
  RFID_LAYER6_EXT_AUTH_FAILURE: -2046819576,
  RFID_LAYER6_GENERAL_AUTH_FAILURE: -2046819575,
  RFID_Error_Failed: -1,
};

export const eRPRM_ResultType = {
  RPRM_ResultType_Empty: 0,
  RPRM_ResultType_RawImage: 1,
  RPRM_ResultType_FileImage: 2,
  RPRM_ResultType_MRZ_OCR_Extended: 3,
  RPRM_ResultType_BarCodes: 5,
  RPRM_ResultType_Graphics: 6,
  RPRM_ResultType_MRZ_TestQuality: 7,
  RPRM_ResultType_DocumentTypesCandidates: 8,
  RPRM_ResultType_ChosenDocumentTypeCandidate: 9,
  RPRM_ResultType_DocumentsInfoList: 10,
  RPRM_ResultType_OCRLexicalAnalyze: 15,
  RPRM_ResultType_RawUncroppedImage: 16,
  RPRM_ResultType_Visual_OCR_Extended: 17,
  RPRM_ResultType_BarCodes_TextData: 18,
  RPRM_ResultType_BarCodes_ImageData: 19,
  RPRM_ResultType_Authenticity: 20,
  RPRM_ResultType_EOSImage: 23,
  RPRM_ResultType_BayerImage: 24,
  RPRM_ResultType_MagneticStripe: 25,
  RPRM_ResultType_MagneticStripe_TextData: 26,
  RPRM_ResultType_FieldFileImage: 27,
};

export const eRPRM_Lights = {
  RPRM_Light_OFF: 0,
  RPRM_Light_OVI: 1,
  RPRM_Light_White_Top: 2,
  RPRM_Light_White_Side: 4,
  RPRM_Light_White_Full: 6,
  RPRM_Light_IR_Top: 8,
  RPRM_Light_IR_Side: 16,
  RPRM_Light_IR_Full: 24,
  RPRM_Light_Transmitted: 32,
  RPRM_Light_Transmitted_IR: 64,
  RPRM_Light_UV: 128,
  RPRM_Light_IR_Luminescence: 256,
  RPRM_Light_AXIAL_White_Front: 512,
  RPRM_Light_AXIAL_White_Left: 1024,
  RPRM_Light_AXIAL_White_Right: 2048,
  RPRM_Light_AXIAL_White_Full: 3072,
  RPRM_Light_IR_Bottom: 4096,
  RPRM_Light_White_Bottom: 8192,
  RPRM_Light_AntiStokes: 65536,
  RPRM_Light_White_Front: 8388608,
  RPRM_Light_IR_Front: 16777216,
  RPRM_Light_White_Gray: 33554432,
};

export const eRPRM_SecurityFeatureType = {
  strBlankElement: 0,
  strFillElement: 1,
  strPhotoElement: 2,
  strMRZElement: 3,
  SecurityFeatureType_FalseLuminescence: 4,
  strHoloSimple: 5,
  strHoloVerifyStatic: 6,
  strHoloVerifyMultiStatic: 7,
  strHoloVerifyDinamic: 8,
  strPatternNotInterrupted: 9,
  strPatternNotShifted: 10,
  strPatternSameColors: 11,
  strPatternIRInvisible: 12,
  strPhotoSizeCheck: 13,
  strPortraitVSGhost: 14,
  strPortraitVSRFID: 15,
  strPortraitComparison: 16,
  SecurityFeatureType_Barcode: 17,
  strPatternDifferentLinesThickness: 18,
  strPortraitVSCamera: 19,
  strRFIDVSCamera: 20,
  strGhostPhotoElement: 21,
  SecurityFeatureType_ClearGhostPhoto: 22,
  SecurityFeatureType_InvisibleObject: 23,
  strLowContrastObject: 24,
};

export const eRPRM_FieldVerificationResult = {
  RCF_Disabled: 0,
  RCF_Verified: 1,
  RCF_Not_Verified: 2,
  RCF_Compare_True: 3,
  RCF_Compare_False: 4,
};

export const eRPRM_VideoModes = {
  RPRM_VM_UNDEFINED: 0,
  RPRM_VM_1MP: 1,
  RPRM_VM_3MP: 2,
  RPRM_VM_5MP: 4,
  RPRM_VM_3MP_MRZ: 16,
  RPRM_VM_9MP_2: 32,
  RPRM_VM_9MP: 64,
  RPRM_VM_5MP_1_3: 128,
  RPRM_VM_14MP: 256,
  RPRM_VM_FULL_HD: 512,
  RPRM_VM_10MP: 1024,
  RPRM_VM_10MP_2_5: 2048,
  RPRM_VM_ID1xx_600: 268435456,
  RPRM_VM_300DPI: 536870912,
  RPRM_VM_5MP_EOS: 1073741824,
};

export const eBarCodeResultCodes = {
  bcrc_NoErr: 0,
  bcrc_BARCODE_ERROR_IPDECODE_DLL_Try_Except: -11400,
  bcrc_BARCODE_ERROR_Dllnotinit: -11300,
  bcrc_BARCODE_ERROR_INDEFINITELY_DECODED: -11210,
  bcrc_BARCODE_ERROR_2D_UgolMax: -11204,
  bcrc_BARCODE_ERROR_Find3X8_2D_Y: -11203,
  bcrc_BARCODE_ERROR_Find3X8_2D_X: -11202,
  bcrc_BARCODE_ERROR_FindRowOrColumn: -11201,
  bcrc_BARCODE_ERROR_Decode_1D_BadDecode: -11200,
  bcrc_BARCODE_ERROR_InnerProblem: -11100,
  bcrc_BARCODE_ERROR_NotLoadIpDecodedll: -11012,
  bcrc_BARCODE_ERROR_FInit: -11006,
  bcrc_BARCODE_ERROR_Inputparam: -11001,
  bcrc_IppTryExceptErr: -6025,
  bcrc_IppExecErr: -6024,
  bcrc_NoIPPDLLFound: -6020,
  bcrc_NoUserDLLFound: -6019,
  bcrc_BarCodeDecodeErr: -6010,
  bcrc_BarCodeNotFound: -6008,
  bcrc_TryExceptErr: -6006,
  bcrc_InternalErr: -6005,
  bcrc_RangeErr: -6004,
  bcrc_SizeErr: -6003,
  bcrc_BadArgErr: -6002,
  bcrc_NullPtrErr: -6001,
  bcrc_IPDECODE_ERROR_LOADING_DEV_TABLE: -4512,
  bcrc_IPDECODE_ERROR_INCORRECT_ERROR_LEVEL: -4511,
  bcrc_IPDECODE_ERROR_FAULTROWS: -4505,
  bcrc_IPDECODE_ERROR_FAULTCOLUMNS: -4504,
  bcrc_IPDECODE_ERROR_LARGEERRORS: -4503,
};

export const eBarCodeResultCodesStrings = {
  strDecode_1D_BadDecodeCode: -9002,
  strDecode_1D_BadFindMinX: -9001,
  strDetectImages_BadFindCode: -8003,
  strDetectImages_NoImagesForCalcUgol: -8001,
  strDetect_LocalRoi: -7001,
  strDetect_Ugol: -7000,
  strBad_InputMinX2D: -6003,
  strBad_CodeMirror: -6002,
  strBad_ManyError: -6001,
  strBad_Function_Andrey_DLL_Try: -5006,
  strBad_Function_IPDecodeDLL_Try: -5005,
  strBad_MinY_Try: -5004,
  strBad_Obrez2d_Try: -5003,
  strBad_1D_Barcode1DRead_Try: -5002,
  strBad_Decode_Try: -5001,
  strBad_Detect_Try: -5000,
  strBad_2D_CalcLittleRow: -3002,
  strBad_2D_CalcMuchRow: -3001,
  strBad_1D_Normalization1D: -2002,
  strBad_1D_BinaryNew: -2001,
  strBad_LocateZ: -1006,
  strBad_GetTypeCode: -1005,
  strBad_LocateX: -1004,
  strBad_CreateDataLocateX: -1003,
  strBad_LocateY: -1002,
  strBad_CreateDataLocateY: -1001,
  strBad_FindUgolFirstStolbez: -135,
  strBad_Find3X8_2D: -132,
  strBad_FindRowOrColumn: -131,
  strBad_Obrez2d: -130,
  strBad_MinY: -120,
  strBad_MinX: -110,
  strBad_Ugol_Max: -101,
  strBad_Ugol: -100,
  strBad_Rotate: -5,
  strBad_Resize: -4,
  strBad_Filter: -3,
  strBad_Allocate_Image: -2,
  strBad_Input_Data: -1,
  strIP_BCERROR_NO: 0,
  strIP_BCERROR_NOTINBASE: 1,
  strIP_BCERROR_CHECKSUM: 2,
  strIP_BCERROR_LARGEERRORS: 3,
  strIP_BCERROR_FAULTCOLUMNS: 4,
  strIP_BCERROR_FAULTROWS: 5,
  strIP_BCERROR_FAULTTYPE: 6,
  strIP_BCERROR_UNKNOWNTYPE: 7,
  strIP_BCERROR_CREATEINFO: 8,
  strIP_BCERROR_BADINPUTPARAM: 9,
  strIP_BCERROR_DETECT: 10,
  strIP_BCERROR_MEMStructResult: 11,
  strIP_BCERROR_PixelFormat: 12,
  strIP_BCERROR_Internal: 13,
  strIP_BCERROR_MIRROR: 101,
};

export const eBarcodeType = {
  bct_Unknown: 0,
  bct_Code128: 1,
  bct_Code39: 2,
  bct_EAN: 3,
  bct_ITF: 4,
  bct_PDF417: 5,
  bct_STF: 6,
  bct_MTF: 7,
  bct_IATA: 8,
  bct_CODABAR: 9,
  bct_UPCA: 10,
  bct_CODE93: 11,
  bct_UPCE: 12,
  bct_EAN13: 13,
  bct_QRCODE: 14,
  bct_AZTEC: 15,
  bct_DATAMATRIX: 16,
};

export const eBarcodeTypeStrings = {
  UNKNOWN: 0,
  CODE128: 1,
  CODE39: 2,
  EAN8: 3,
  ITF: 4,
  PDF417: 5,
  STF: 6,
  MTF: 7,
  IATA: 8,
  CODABAR: 9,
  UPCA: 10,
  CODE93: 11,
  UPCE: 12,
  EAN13: 13,
  QRCODE: 14,
  AZTEC: 15,
  ALL_1D: 17,
  CODE11: 18,
};

export const systemNotificationCodes = {
  BatteriesCritical: 0,
  DeviceShutdown: 1,
  SdkRestart: 2,
};

export const languages = {
  Russian: "STR_RU",
  English: "STR_EN",
  German: "STR_DE",
  Chineese: "STR_CH",
  French: "STR_FR",
  Spanish: "STR_ES",
};
