import React from 'react';
import { ItemRendererContainer } from '../../styles';
import randomColor from 'randomcolor';
import colors from 'constants/colors';

const ItemRenderer = ({ itemContext, getItemProps }) => {

  const color = randomColor({ luminosity: 'light', seed: itemContext.title })

  return (
    <div
      {...getItemProps({
        style: {
          background: color,
          color: colors.black,
          border: 'none',
          borderRadius: '16px',
          height: '32px',
          fontWeight: 'bold',
        },
      })}
    >
      <div
        className="rct-item-content"
        style={{
          height: '32px',
          width: '100%'
        }}
      >
        <ItemRendererContainer>
          {itemContext.title}
        </ItemRendererContainer>
      </div>
    </div>
  );
};

export default ItemRenderer;
