import styled from 'styled-components';
import colors from 'constants/colors';

export const AddOnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;

  .label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: ${colors.white1};
    margin-right: 10px;
  }

  .disabled {
    color: ${colors.grey30};
  }
`;
