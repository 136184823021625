import React, { useState, useEffect, useCallback } from 'react';
import PlayButton from 'assets/svg/playButton.svg'
import PauseButton from 'assets/svg/pauseButton.svg'
import WaveForm from './WaveForm/index'
import moment from 'moment';
import { timeFormat } from 'utils/timeFormat';
import { AudioContainer } from './styles';

const Audio = ({createAt, path, staff}) => {

  const [status, setStatus] = useState(false) 
  const [audio, setAudio] = useState()
  const [duration, setDuration] = useState('')

  const audioRef = useCallback(node => {
    if (node !== null) {
      const audioEnded = () => {
        setStatus(false)
      }
      node.onended = audioEnded
      setAudio(node)
    }
  }, [])

  const audioDuration = audio && audio.duration
  useEffect(()=>{
    if(audio) {
      setDuration(timeFormat(audio.duration))
    }
  },[audio, audioDuration])

  const toggleAudio = () => {
    if (audio.paused) {
      audio.play()
      setStatus(true)
    } else {
      audio.pause()
      setStatus(false)
    }
  }

  //const { data } = await getWaveformFile(waveformFileName)
  const data = [
    100,75,50,25,75,
    100,75,25,50,25,75,50,25,75,
    100,75,25,50,25,75,50,25,75,
    100,75,25,50,25,75,50,25,75,
    100,75,25,50,25,75,50,25,75,
    100,75,25,50,25,75,50,25,75,
  ]
  
  const peaks = data.filter(point => point >= 0)
  const ratio = Math.max(...peaks) / 100
  const normalized = peaks.map(point => Math.round(point / ratio))

  return (
    <AudioContainer staff={staff}>
      <div className='createAt'>
        {moment(createAt).format('M/D/YY h:mmA')}
      </div>
      <div className="audioContainer">
        <audio ref={audioRef} className='audio-player' src={path}></audio>
        <div className="controls">
          <button
            className="player-button"
            onClick={toggleAudio}
          >
            <img src={status? PauseButton: PlayButton} alt={'playButton'} />
          </button>
          <WaveForm
            waveformData={normalized}
            audio={audio}
          />
        </div>
        <div className='time'>{duration}</div>
      </div>
    </AudioContainer>
  )
}

export default Audio;
