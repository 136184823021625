import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useAuth from 'customHook/useAuth'
import { Menu, Title, Item, Logout } from './styles'
import Cross from 'assets/svg/cross.svg'

const AccountNav = ({ open, setOpen }) => {

  const history = useHistory()
  const { userLogout } = useAuth();
  const { pathname } = useLocation();

  return (
    <Menu open={open}>
      <Title>
        <img
          src={Cross}
          alt="cross"
          onClick={()=>{
            setOpen(false)
          }}
        />
      </Title>
        <Item
          active={pathname==='/profile'}            
          onClick={()=>{
            history.push('/profile')
          }}
        >
          PROFILE
        </Item>
      <Logout
        onClick={userLogout}
      >
        LOG OUT
      </Logout>
    </Menu>
  );
};

export default AccountNav;
