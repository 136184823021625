import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import ShowInformation from 'components/ShowInformation';

const MetaInformation = ({created, origin}) => {

  return (
    <Card
      additionalStyle={`
        height: 139px;
      `}
    >
      <CardHeader>
        <div className='type'>META INFORMATION</div>
      </CardHeader>
      <FormContainer>
        <ShowInformation title="CREATED" description={created} />
        <ShowInformation title="ORIGIN" description={origin} />
      </FormContainer>
    </Card>
  )
}

export default MetaInformation;
