import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.grey3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  color: ${colors.white1};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
`;
