import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  width: 100%;  
  background-color: ${colors.black5};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.grey5};
  padding: 15px;

  @media only screen and (min-width: 1201px) {
    display: none;
  }
`;

export const ViewingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 1.5px;
`;

export const RemainingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 1.5px;
  margin-top: 25px;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  color: ${colors.grey25};
  margin-bottom: 10px;

  @media only screen and (max-width: 767px) {
    font-size: 19px;
  }
`;

export const RemainingItem = styled.div`
  width: 100%;
  padding: 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.grey10};
  cursor: pointer;

  div {
    letter-spacing: 0.5px;
    font-size: 15px;
    color: ${colors.white1};

    :nth-child(1) {
      font-weight: 300;
    }

    :nth-child(2) {
      font-weight: 600;
    }

    @media only screen and (max-width: 767px) {
      font-size: 13px;
    }
  }

  ${({ additionalStyle }) => additionalStyle};
`;
