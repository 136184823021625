import React from 'react';
import { Container } from './styles';

const ShowInformation = ({ title, description, width, additionalStyle }) => (
  <Container width={width} additionalStyle={additionalStyle}>
    <div className='title'>{title}</div>
    <div className='info'>{description}</div>
  </Container>
);

export default ShowInformation;