import React, { useState } from 'react';
import Note from 'components/Note';
import AddNote from 'components/AddNote';
import moment from 'moment'
import { useSwipeable } from 'react-swipeable';
import { NotesContainer, NavigatorContainer, Navigator } from './styles';

const Notes = ({
  notes=[],
  note,
  setNote,
  onAddNote,
  onRemoveNote,
  pageSize,
  addNoteHidden,
  disabled
}) => {

  const [pageNumber, setPageNumber] = useState(1)

  const paginate = (array, page_size, page_number) => {
    const newArray = [...array]
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return newArray.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const previousPage = () => {
    setPageNumber((pageNumber) => pageNumber-1)
  }

  const nextPage = () => {
    setPageNumber((pageNumber) => pageNumber+1)
  }

  const handles = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => previousPage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <>
      {
        notes.length>pageSize ? 
          (
            <NavigatorContainer>
              <Navigator
                disabled={pageNumber<=1} 
                onClick={previousPage}
              >
                {'<'} Back
              </Navigator>
              <Navigator
                disabled={pageNumber>= notes.length/pageSize}
                onClick={nextPage}
              >
                Next {'>'}
              </Navigator>
              {/* <Swiper
                disabled={pageNumber<=1}
              >
                {'<'}
              </Swiper>
              <Swiper>
                SWIPE
              </Swiper>
              <Swiper
                disabled={pageNumber>= notes.length/pageSize}
              >
                {'>'}
              </Swiper> */}
            </NavigatorContainer>            
          ): null 
      }
      <NotesContainer {...handles}>
        {
          notes
            ? paginate(notes, pageSize, pageNumber).map((note) => {
              return (
                <Note
                  key={note.noteId || note.createdAt}
                  date={moment(note.createdAt).format('M/D/YYYY h:mm A')}
                  author={note.createdBy?.fullName}
                  description={note.notes}
                  onRemove={() => onRemoveNote(note.noteId || note.createdAt)}
                  disabled={disabled}
                />
              )
            })
            : null
        }
      </NotesContainer>
      {
        !addNoteHidden? 
          <AddNote
            onChangeNote={setNote}
            onAddNote={onAddNote}
            note={note}
          />: null
      }
    </>
  );
};

export default Notes;
