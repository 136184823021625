import React, { useState } from 'react';
import Notes from 'components/Notes';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const GeneralGroupNotesLocal = ({ userId, fullName }) => {

  const [note, setNote] = useState('');
  const pageSize = 3

  const [groupNotes, setGroupNotes] = useState(JSON.parse(localStorage.getItem('group_notes') || '[]'))
  const addNote = (note) => {
    const groupNote = {}
    groupNote.notes= note
    groupNote.createdAt = Date.now()
    groupNote.createdBy = userId
    const newArray = [...groupNotes, groupNote]
    localStorage.setItem('group_notes', JSON.stringify(newArray))
    setGroupNotes(newArray)
  }

  const onAddNote = () => {
    if (note) {
      addNote(note)
      setNote('');
    } else {
      const content = "Note can't be empty"
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  }

  const onRemoveNote = (noteId) => {
    const newArray = groupNotes.filter(groupNote=> groupNote.createdAt !== noteId)
    localStorage.setItem('group_notes', JSON.stringify(newArray))
    setGroupNotes(newArray)
  }

  return (
    <Card>
      <CardHeader>
        <div className='type'>GENERAL GROUP NOTES</div>
      </CardHeader>
      <Notes
        notes={groupNotes}
        note={note}
        setNote={setNote}
        onAddNote={onAddNote}
        onRemoveNote={onRemoveNote}
        pageSize={pageSize}
        author={fullName}
      />
    </Card>
  );
};

export default GeneralGroupNotesLocal;
