import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Button from 'components/Button'

const WorkSchedule = () => (
  <Card
    additionalStyle={`
      height: 360px;
    `}
  >
    <CardHeader>
      <div className='type'>WORK SCHEDULE (?)</div>
      <div className='buttonContainer'>
        <Button
          mainPage
          additionalStyle={`
            height: 36px;
            width: 113px;
          `}
        >
          Edit Schedule
        </Button>
      </div>
    </CardHeader>
  </Card>
);

export default WorkSchedule;
