import React, { useState, useEffect } from 'react';
import { message } from 'antd'
import { getMessageTimeOut } from 'utils/util';

const ErrorBoundary = ({ children }) =>{
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error) => {
      // You can log the error to an error reporting service
      const content = `Error caught by error boundary: ${error.error}`;
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

    if (hasError) {
      return <h1>Something went wrong. Please Try Again</h1>;
    } else {
      return <>{children}</>
    }
}

export default ErrorBoundary;
