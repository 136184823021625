import styled from 'styled-components';
import colors from 'constants/colors';

export const ReconciliationTableContainer = styled.table`
  width: 100%;
  color: white;
  border-collapse: collapse;
  font-size: 14px;

  tr {
    height: 40px;
    &:nth-child(odd) {
      background-color: ${colors.black7};
    }

    &:nth-child(even) {
      background-color: ${colors.grey3};
    }
  }

  th {
    height: 50px;
    vertical-align: bottom;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: 8px 15px;
    border-bottom: 2px solid ${colors.white1};
    border-top: 1px solid ${colors.grey9};
  }

  td {
    border: 1px solid ${colors.grey23};
    padding: 8px 15px;
    :first-child {
      border-left: none;
    }
  }

  .date-column {
    width: 14%;
  }

  .customer-column {
    width: 27%;
  }

  .amountPaid-column {
    width: 9%;
  }

  .tax-column {
    width: 7%;
  }
`;

export const SubHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;

  .datePickerContainer {
    width: 21%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .datePicker {
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      font-size: 12px;
      margin-right: 5px;
      color: white;
    }
  }
`;
