import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useMutation } from '@apollo/client';
import { CREATE_CHARGE } from 'graphql/service';
import { Head, Container, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalAddCharge = ({hidden, buttonStyle, groupId, bookingId, refetch}) => {

  const hstRate = 0.13
  const [isOpen, setIsOpen] = useState(false);
  const [chargeType, setChargeType] = useState('other')
  const [chargeDetails, setChargeDetails] = useState('')
  const [chargePrice, setChargePrice] = useState('')
  const [createCharge] = useMutation(CREATE_CHARGE, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handleAddCharge = () => {

    createCharge({
      variables: {
        input: {
          charge: {
            type: chargeType,
            note: chargeDetails,
            price: chargePrice,
            hst: chargePrice * hstRate,
            totalPrice: chargePrice * (1 + hstRate),
            bookingId: bookingId
          }
        },
      },
    });
    setIsOpen(false)
  }
  
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Add Charge"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 444px;
        .modal-content{
          height: 373px;
          .close {
            z-index: 200;
          }          
          .modal-body {
            position: relative;
            top: 20px;
            height: 100%;
          }
        }
        top: 139px;
      `}
    >
      <Head>Add Charge</Head>
      <Container>
        <TextArea      
          title="CHARGE DETAILS"
          rows={4}
          value={chargeDetails}
          onChange={(e) => setChargeDetails(e.target.value)}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
      </Container>
      <Footer>
        <Input
          value={chargePrice}
          title="PRICE"
          onChange={(e) => setChargePrice(e.target.value)}
          additionalStyle={`
            width: 36%;
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleAddCharge}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalAddCharge;
