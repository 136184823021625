import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import ModalRefund from 'screens/ModalRefund';
import { PrimaryContainer, Container, Header, Warning } from './styles';
import ShowInformation from 'components/ShowInformation';
import moment from 'moment';
import checkMark from 'assets/svg/checkMark.svg'

const TransactionHistory = ({transactions, refetch}) => {

  const transactionList = transactions.map((transaction, index) => {

    return (
      <Container key={index}>
        <ShowInformation
          width={'30%'}
          title="DATE"
          description={moment(transaction.createAt).format('MM/DD/YYYY HH:mm A')}
          additionalStyle={`          
            @media only screen and (max-width: 767px) {
              width: 100%;
            }
          `}
        />
        <ShowInformation
          width={'30%'}
          title="AMOUNT"
          description={transaction.amount}
          additionalStyle={`          
            @media only screen and (max-width: 767px) {
              width: 23%;
            }
          `}
        />
        <ShowInformation
          width={'20%'}
          title="CARD NUMBER"
          description={transaction.creditCardNumber}
          additionalStyle={`
            background-image: url(${checkMark});
            background-repeat: no-repeat;
            background-position: 150px 90%;

            @media only screen and (max-width: 767px) {
              width: 70%;
            }
          `}
        />
        <ModalRefund
          buttonStyle={`
            width: 94px;

            @media only screen and (min-width: 767px) and (max-width: 1200px) {
              width: 112px;
            }
          
            @media only screen and (max-width: 767px) {
              width: 76px;
            }
          `}
          transactionId={transaction.transactionId}
          refetch={refetch}
        />
      </Container>
    )
  })

  return (
    <Card
      additionalStyle={`
        padding: 25px 25px 10px 15px;
      `}  
    >
      <CardHeader>
        <div className='type'>TRANSACTION HISTORY</div>
      </CardHeader>
      {
        transactionList.length? transactionList: 
          <Warning>No transaction has been added.</Warning> 
      }
    </Card>
  )
}

export default TransactionHistory;