import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import colors from 'constants/colors';
import KeyPrinting from 'assets/svg/KeyPrinting.svg';
import { Container } from './styles';

const ModalKeyPrinting = ({
  hidden,
  buttonStyle,
  buttonLabel="Key Printing",
  deviceId,
  isOpen,
  setIsOpen,
}) => {

  const handleFinishPrint = () => {
    setIsOpen(false)
  }
  
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 398px;
        .modal-content{
          height: 274px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            top: 40px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <img src={KeyPrinting} alt={'keyPrinting'} />
        <div>Your key is being printed at {deviceId}.</div>
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleFinishPrint}
        >
          Finish
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalKeyPrinting;
