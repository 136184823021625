import styled from 'styled-components';
import colors from 'constants/colors';

export const PicturesWallContainer = styled.div`
  width: 100%;
`;

export const UploadButton = styled.div`
  color: ${colors.white1};
  div {
    margin-top: 8px;
  }
`;

export const ModalContainer = styled.div`
  height: 100%;
  justify-content: center;
  text-align: center;

  img {
    margin-top: -20px;
  }

  div {
    text-align: left;
    color: ${colors.white1};
    font-size: 22px;
    font-weight: 600;
    margin: 10px 0 10px 0;
  }
`;
