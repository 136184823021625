import React, { useState } from 'react';
import Button from 'components/Button';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import {
  ImageContainer,
  ImageSlideContainer,
  SlideContent,
  Slide,
  ButtonContainer,
  DotsContainer,
  MobileDotsContainer,
  DotContainer
} from './styles'
import LeftSwipeIcon from 'assets/svg/leftSwipeIcon.svg'
import RightSwipeIcon from 'assets/svg/rightSwipeIcon.svg'

const Review = ({imageSources, handleFlagAsInvalid, handleApprove}) => {

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    // At last image, going back to first one
    if (activeIndex === imageSources.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      })
    }
    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: activeIndex + 1,
    })
  }

  const prevSlide = () => {
    // At first image, going back to last one
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: imageSources.length - 1,
        activeIndex: imageSources.length - 1,
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: activeIndex - 1,
    })
  }

  return (
    <Card
      additionalStyle={`
        height: 406px;

        @media only screen and (min-width: 767px) and (max-width: 1200px) {
          height: 613px;
          margin-bottom: 30px;
        }

        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }
      `}
    >
      <CardHeader
        additionalStyle={`
          @media only screen and (max-width: 767px) {
            margin: 15px;
            flex-direction: column;
            justify-content: center;
          }          
        `}
      >
        <div className='type'>REVIEW PHOTOS</div>
        <DotsContainer>
          {
            imageSources.map((item, index) => (
              <DotContainer key={item} active={activeIndex === index} />
            ))
          }
        </DotsContainer>
        <ButtonContainer>
          <Button
            mainPage
            danger
            onClick={handleFlagAsInvalid}
            additionalStyle={`
              width: 157px;

              @media only screen and (max-width: 767px) {
                width: 129px;
              }
            `}
          >
            Flag as invalid
          </Button>
          <Button
            mainPage
            onClick={handleApprove}
            additionalStyle={`
              width: 141px;
              margin-left: 14px;

              @media only screen and (max-width: 767px) {
                width: 110px;
              }
            `}
          >
            Approve
          </Button>
        </ButtonContainer>
      </CardHeader>
      <ImageContainer>
        <ImageSlideContainer>
          <SlideContent
            width={`${imageSources.length * 100}%`}
            translateWidth={(translate * 100) / imageSources.length}
            transition={transition}
          >
            {
              imageSources.map((image, index) => (
                <div
                  key={image + index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Slide key={image + index} image={image}/>
                </div>
              ))
            }
          </SlideContent>
          <img
            className='left'
            src={LeftSwipeIcon}
            alt={'left'}
            onClick={prevSlide}
          />
          <img
            className='right'
            src={RightSwipeIcon}
            alt={'right'}
            onClick={nextSlide}
          />
        </ImageSlideContainer>
      </ImageContainer>
      <MobileDotsContainer>
        {
          imageSources.map((item, index) => (
            <DotContainer key={item} active={activeIndex === index} />
          ))
        }
      </MobileDotsContainer>
    </Card>
  )
}

export default Review;
