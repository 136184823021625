import styled, { css } from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${colors.white1}
`;

export const List = styled.ul`
  flex: 25%;
  border: 1px solid #e6e3e3;
  float: right;
  margin: 10px;
  justify-content: flex-start;
  align-items: center;
`;

export const ListItems = styled.li`
  cursor: pointer;
  list-style-type: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    padding: 10px;
    font-weight: bold;
    text-align: center;
  }
  ${(props) =>
    props.done &&
    css`
      background-color: ${colors.brown};
    `}
`;
