import styled from 'styled-components';
import colors from 'constants/colors';

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentLeft = styled.div`
  display: flex;
  background-color: ${colors.black5};
  width: 80%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    display: block;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 436px;
  width: 100%;
  justify-content: space-between;
  background-color: ${colors.black5};
  padding: 15px;
  column-gap: 15px;


  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: 100%;
    background-color: ${colors.black5};
    display: block;
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 767px) {
    height: 100%;
    background-color: ${colors.black5};
    display: block;
    padding-bottom: 60px;
  }
`;

export const ContentRight = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  top: 50px;
  background-color: ${colors.black5};
  width: 20%;
  padding: 15px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
