import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;

  & > div {
    width: 100%;
  }

  button {
    float: right;
    margin-top: 15px;
  }
`;
