import React from 'react';
import { LegendItemContainer } from './style';

export const LegendItem = ({
  backgroundColor,
  iconComponent,
  legendTitle,
  legendDescription,
}) => {
  return (
    <LegendItemContainer backgroundColor={backgroundColor}>
      <div className='iconContainer'>{iconComponent}</div>
      <span>{legendTitle ? legendTitle : 'error'}</span>
      <span>{legendDescription ? legendDescription : null}</span>
    </LegendItemContainer>
  );
};
