import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  height: 54px;
  margin-bottom: 3px;
`;

export const RemoveContainer = styled.div`
  background-color: ${colors.grey2};
  padding: 5px;
  width: 5%;
  display: flex;
  cursor: pointer;

  img {
    width: 9px;
    height: 9px;
    margin: 5px auto;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${colors.grey3};
  padding: 5px 10px;
  width: ${(props) => props.disabled ? '100%' :'95%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Header = styled.div`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: ${colors.white1};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
`;

export const Content = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .highLight {
    color: ${colors.blue2}
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  background-color: ${colors.grey3};
  justify-content: flex-end;
  align-items: center;
  width: 35%;
  height: 100%;
  padding: 10px;

  @media only screen and (min-width: 1201px) {
    .mobileButtonTitle {
      display: none;
    }
    .padButtonTitle {
      display: none;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    .buttonTitle {
      display: none;
    }
    .mobileButtonTitle {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .padButtonTitle {
      display: none;
    }
    .buttonTitle {
      display: none;
    }
  }
`;
