import styled from 'styled-components';
import colors from 'constants/colors';

export const FooterBar = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 60px;
  background: ${colors.black10};
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  z-index: 3000;

  @media only screen and (min-width: 1201px) {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    padding: 0;
  }

  ${(props) => props.additionalStyle};
`;

export const Navigator = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  letter-spacing: 1.5px;
  font-weight: bold;
  color: ${(props) => (props.active ? colors.white1: colors.grey11)};
  background-color: ${(props) => (props.active ? colors.grey2: colors.black10)};
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    ${(props) => (props.active ?`filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);`: null)};
  }
`;