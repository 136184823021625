import styled from 'styled-components';
import colors from 'constants/colors';

export const RevenueReportSubHeader = styled.div`
  width: 100%;
  height: 50px;
  justify-content: flex-start;

  .dropDownContainer {
    display: flex;
    align-items: center;
    width: 295px;
    margin-left: 20px;
    margin-right: 35px;
    
    .title {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 2.5px;
      color: ${colors.grey5};
      margin-right: 5px;
    }
    
    .select {
      height: 40px;
      width: 239px;
      background-position: 95% 50%;
      background-size: 11px 11px;
    }
  }
`;

export const RevenueReportTableContainer = styled.table`
  width: 100%;
  color: ${colors.white1};
  font-size: 14px;

  th {
    border-top: 1px solid ${colors.grey23};
    border-bottom: 1px solid ${colors.white1};
    border-left: 1px solid ${colors.white1};
    padding: 5px 10px;
    height: 42px;
  }

  td {
    border-left: 1px solid ${colors.white1};
    border-bottom: 1px solid ${colors.grey23};
    padding: 5px 20px;
    height: 44px;
    :first-child {
      border-left: none;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: ${colors.black7}
    }
    tr:nth-child(even) {
      background-color: ${colors.grey3}
    }
  }

  .titleCell {
    width: 14%;
    border-left: none;
  }

  .compareCell {
    width: 15%;
    padding-left: 20px;
  }

  .title {
    color: ${colors.grey5};
    text-align: right;
  }

  .titleHighlight {
    font-weight: bold;
    text-align: right;
  }
`;
