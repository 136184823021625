import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 15px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${colors.grey2};
  color: ${colors.white1};
  overflow: ${(props) => (props.overflow ? props.overflow : null)};
  ${(props) => (props.width ? `min-width: ${props.width}; max-width: ${props.width};` : null)};

  ${({ additionalStyle }) => additionalStyle};
`;
