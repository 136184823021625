import styled from 'styled-components';

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 40px;

  & > div:nth-child(1) {
    flex-basis: 55%;
  }

  & > div:nth-child(2) {
    flex-basis: 45%;
    display: flex;
    column-gap: 40px;
  }

  @media only screen and (max-width: 767px) {

    flex-direction: column;
    row-gap: 10px;
  }
`;