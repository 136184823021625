import { gql } from '@apollo/client';

//Queries
export const GET_USER_INFO_BY_USER_ID = gql`
  query getUserInfoByUserId($userId: String!) {
    userById(id: $userId) {
      fullName
      email
      phoneNumber
      address
    }
  }
`;

export const GET_ALL_ROOMS = gql`
  query getAllRooms {
    validRooms {
      roomId: id
      roomNumber
      floor
      roomStatusHistories {
        status
        createdAt
        isCurrent
      }
      roomType {
        name
      }
    }
  }
`;

export const GET_ROOM_BY_ROOM_NUMBER = gql`
  query getRoomByRoomNumber($roomNumber: String!) {
    roomByNumber(roomNumber: $roomNumber) {
      roomId: id
      roomType {
        roomTypeId: id
        name
        hasWindows
        pricePerNight
      }
      roomBookings {
        booking {
          bookingId: id
          checkInDate
          checkOutDate
          fullName
        }
      }
      roomStatusHistories {
        status
        createdAt
      }
      roomNotes {
        noteId: id
        notes
        createdAt
        createdBy {
          fullName
        }
      }
    }
  }
`;

export const GET_ALL_ROOM_NOTES = gql`
  query getAllRoomNotes {
    roomNotes {
      noteId: id
      notes
      createdAt
      isRead
      room {
        roomId: id
        roomNumber
      }
      createdBy {
        createdById: id
        fullName
      }
    }
  }
`;

export const GET_ALL_STAFFS = gql`
  query getAllStaffs {
    staffs {
      staffId: id
      user {
        fullName
        email
        phoneNumber
        createdAt
        userRoles {
          name
        }
      }
    }
  }
`;

export const GET_STAFF_INFO_BY_STAFF_ID = gql`
  query getStaffInfoByStaffId($staffId: String!) {
    staffByID(id: $staffId) {
      user {
        userId: id
        fullName
        email
        phoneNumber
        address
        status
        userRoles {
          name
        }
      }
      staffNotes {
        noteId: id
        notes
        createdAt
        createdBy {
          fullName
        }
      }
    }
  }
`;

export const GET_ALL_VALID_BOOKINGS = gql`
  query getAllValidBookings {
    validBookings {
      bookingId: id
      email
      phoneNumber
      fullName
      reservationCode
      group {
        groupId: id
        name
      }
      checkInDate
      checkedInAt
      checkOutDate
      checkedOutAt
      roomBookings {
        room{
          roomNumber
        }
      }
    }
  }
`;

export const GET_BOOKING_DETAILS_BY_BOOKING_ID = gql`
  query getBookingDetailsByBookingId($bookingId: String!) {
    bookingByID(id: $bookingId) {
      bookingId: id
      email
      fullName
      phoneNumber
      reservationCode
      checkInDate
      checkedInAt
      checkOutDate
      checkedOutAt
      status
      isDeleted
      createdAt
      roomBookings {
        # ratePlanId
        pricePerNight
        room {
          roomId: id
          roomNumber
          roomType {
            roomTypeId: id
            name
            hasWindows
          }
          roomStatusHistories {
            status
            createdAt
            isCurrent
          }
          roomNotes {
            noteId: id
            notes
            createdAt
            createdBy {
              createdById: id
              fullName
            }
          }
        }
      }
      group {
        groupId: id
        primaryBookingCode
        primaryBookingStatus
        name
        ratePlan {
          ratePlanId: id
          ratePlan {
            title
          }
        }
      }
      tenantGroup {
        isPrimaryTenant 
        tenant {
          tenantId: id
          user {
            userId: id
          }
        }
      }
      bookingNotes {
        noteId: id
        notes
        createdAt
        createdBy {
          fullName
        }
      }
    }
  }
`;

export const GET_GROUP_DETAILS_BY_GROUP_ID = gql`
  query getGroupDetailsByGroupId($groupId: String!) {
    groupByID(id: $groupId) {
      groupId: id
      name
      primaryBookingCode
      primaryBookingStatus
      numberOfGuests
      pointOfContactName
      # pointOfContactOrganization
      groupNotes {
        noteId: id
        notes
        createdAt
        createdBy {
          fullName
        }
      }
      coupon {
        couponId: id
        rateType
        discount
      }
      bills {
        billId: id
        description
        MAT
        HST
        subTotal
        total
        amountToPay
        amountToPayAfterReview
        ratePlanAmount
        depositSubTotal
        depositHSTAmount
        discountAmount
        addonPrice
        depositMATAmount
        depositRatePlanAmount
        depositAmountToPay
        depositTotal
      }
      bookings {
        bookingId: id
        email
        phoneNumber
        fullName
        createdAt
        checkInDate
        checkOutDate
        isDeleted
        reservationCode
        roomBookings {
          # ratePlanId
          pricePerNight
          room {
            roomId: id
            roomNumber
            barrierFree
            roomType {
              roomTypeId: id
              name
              bed
              hasWindows
              capacity
            }
            roomStatusHistories {
              status
              createdAt
              isCurrent
            }
            roomNotes {
              noteId: id
              notes
              createdAt
              createdBy {
                fullName
              }
            }
          }
        }
      }
      paymentCards {
        paymentCardId: id
        holderName
        billingAddress
        transaction {
          creditCardNumber: field1008
          cardExpirationDate: field0004
        }
        isPrimary
      }
    }
  }
`;

export const GET_TENANT_INFO_BY_BOOKING_ID = gql`
  query getTenantInfoByBookingId($bookingId: String!) {
    bookingByID(id: $bookingId) {
      tenantGroup {
        isPrimaryTenant
        group {
          groupId: id
        }
        tenant {
          tenantId: id
          user {
            userId: id
            fullName
            email
            phoneNumber
            address
          }
        }
      }
    }
  }
`;

export const GET_TENANT_INFO_BY_TENANT_ID = gql`
  query getTenantInfoByTenantId($tenantId: String!) {
    tenantByID(id: $tenantId) {
      user {
        userId: id
        fullName
        email
        phoneNumber
        address
      }
      tenantNotes {
        noteId: id
        notes
        createdAt
        createdBy {
          fullName
        }
      }
      groups {
        group {
          groupId: id
          bookings {
            bookingId: id
            checkOutDate
            checkInDate
            roomBookings {
              room {
                roomType {
                  name
                }
              }
            }
          }
        }
      }
      paymentCards {
        paymentCardId: id
        holderName
        billingAddress
        transaction {
          creditCardNumber: field1008
          cardExpirationDate: field0004
        }
        isPrimary
      }
    }
  }
`;

export const GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE = gql`
  query getAvailableRoomsByRoomTypeAndDateRange($input: RoomAvailabilityInput!) {
    roomsAvailability(data: $input) {
      roomId: id
      roomNumber
      roomStatusHistories {
        status
        createdAt
        isCurrent
      }
      roomType {
        name
      }
    }
  }
`;

export const GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE = gql`
  query getAvailableRoomTypesByDateRange($input: RoomTypeByDateRangeInput!) {
    availableRoomTypesByDateRange(data: $input) {
      roomTypeId: id
      name
      hasWindows
      pricePerNight
      availableRooms
    }
  }
`;

// Mutations
export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    authenticate(data: $input) {
      accessToken
    }
  }
`;

export const UPDATE_USER_INFO_BY_USER_ID = gql`
  mutation updateUserInfoByUserId($userId: String!, $input: UpdateUserInput!) {
    updateUser(id: $userId, data: $input) {
      id
    }
  }
`;

export const UPDATE_ROOM_STATUS = gql`
  mutation updateRoomStatus ($input: CreateRoomStatusHistoryInput!) {
    createRoomStatusHistory(data: $input) {
      id
    }
  }
`;

export const CREATE_ROOM_NOTE = gql`
  mutation createRoomNote($input: CreateRoomNoteInput!) {
    createRoomNote(data: $input) {
      id
    }
  }
`;

export const UPDATE_ROOM_NOTE_BY_ROOM_NOTE_ID = gql`
  mutation updateRoomNoteByRoomNoteId($noteId: String!,$input: CreateRoomNoteInput!) {
    updateRoomNote(id: $noteId, data: $input) {
      id
    }
  }
`;

export const DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID = gql`
  mutation deleteRoomNoteByRoomNoteId($noteId: String!) {
    deleteRoomNote(id: $noteId) {
      id
    }
  }
`;

export const CREATE_BOOKING_NOTE = gql`
  mutation createBookingNote($input: CreateBookingNoteInput!) {
    createBookingNote(data: $input) {
      id
    }
  }
`;

export const DELETE_BOOKING_NOTE_BY_BOOKING_NOTE_ID = gql`
  mutation deleteBookingNoteByBookingNoteId($noteId: String!) {
    deleteBookingNote(id: $noteId) {
      id
    }
  }
`;

export const CREATE_GROUP_NOTE = gql`
  mutation createGroupNote($input: CreateGroupNoteInput!) {
    createGroupNote(data: $input) {
      id
    }
  }
`;

export const DELETE_GROUP_NOTE_BY_GROUP_NOTE_ID = gql`
  mutation deleteGroupNoteByGroupNoteId($noteId: String!) {
    deleteGroupNote(id: $noteId) {
      id
    }
  }
`;

export const CREATE_TENANT_NOTE = gql`
  mutation createTenantNote($input: CreateTenantNoteInput!) {
    createTenantNote(data: $input) {
      id
    }
  }
`;

export const DELETE_TENANT_NOTE_BY_TENANT_NOTE_ID = gql`
  mutation deleteTenantNoteByTenantNoteId($noteId: String!) {
    deleteTenantNote(id: $noteId) {
      id
    }
  }
`;

export const CREATE_STAFF_NOTE = gql`
  mutation createStaffNote($input: CreateStaffNoteInput!) {
    createStaffNote(data: $input) {
      id
    }
  }
`;

export const DELETE_STAFF_NOTE_BY_STAFF_NOTE_ID = gql`
  mutation deleteStaffNoteByStaffNotesId($noteId: String!) {
    deleteStaffNote(id: $noteId) {
      id
    }
  }
`;

export const UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID = gql`
  mutation updateBookingRoomByRoomIdAndBookingId($input: IsRoomAvailableInput!) {
    updateRoomBooking(data: $input) {
      id
    }
  }
`;

export const CREATE_GROUP_BOOKING = gql`
  mutation createGroupBooking($input: [GroupTenantBookingInput!]!) {
    createGroupBooking(data: $input) {
      tenantGroup {
        group {
          groupId: id
        }
      }
    }
  }
`;

export const DELETE_BOOKING_BY_BOOKING_ID = gql`
  mutation deleteBookingByBookingId($bookingId: String!) {
    deleteBooking(id: $bookingId) {
      status
    }
  }
`;

export const DELETE_GROUP_BY_GROUP_ID = gql`
  mutation deleteGroupByGroupId($groupId: String!) {
    deleteGroup(id: $groupId) {
      isDeleted
    }
  }
`;

export const UPDATE_BOOKING_BY_BOOKING_ID = gql`
  mutation updateBookingByBookingId($bookingId: String!, $input: UpdateBookingInput!) {
    updateBooking(id: $bookingId, data: $input) {
      id
    }
  }
`;

export const UPDATE_GROUP_BY_GROUP_ID = gql`
  mutation updateGroupByGroupId($groupId: String!, $input: UpdateGroupInput!) {
    updateGroup(id: $groupId, data: $input) {
      id
    }
  }
`;

export const CREATE_BOOKING_CHECK_IN = gql`
  mutation createBookingCheckIn($input: CreateBookingCheckInInput!) {
    createBookingCheckIn(data: $input) {
      id
    }
  }
`;

export const CREATE_BOOKING_CHECK_OUT = gql`
  mutation createBookingCheckOut($input: CreateBookingCheckOutInput!) {
    createBookingCheckIn(data: $input) {
      id
    }
  }
`;

export const CREATE_PAYMENT_CARD = gql`
  mutation createPaymentCard($input: CreatePaymentCard!) {
    createTenantPaymentCard(data: $input) {
      id
    }
  }
`;

export const DELETE_CREDIT_CARD = gql`
  mutation deleteCreditCard($paymentCardId: String!) {
    removePaymentCard(id: $paymentCardId) {
      id
    }
  }
`;

export const SET_CREDIT_CARD_PRIMARY = gql`
  mutation setCreditCardPrimary($input: SetPrimaryPaymentCard!) {
    setPrimaryPaymentCard(data: $input) {
      id
    }
  }
`;

//FIXME: The following APIs do not working properly
//Queries
export const GET_ALL_NEW_MESSAGES = gql`
  query getAllNewMessages {
    messages {
      messageId: id
      notes
      createdAt
      type
      room {
        roomNumber
      }
      tenant {
        tenantId: id
        user {
          fullName
        }
      }
    }
  }
`;

export const GET_RATE_PLAN_BY_RATE_PLAN_ID = gql`
  query getRatePlanByRatePlanId($ratePlanId: String!) {
    ratePlansCacheByRatePlanId(id: $ratePlanId) {
      discount
    }
  }
`;

export const GET_RATE_PLAN_ID_BY_RATE_PLAN = gql`
  query getRatePlanIdByRatePlan($discount: BigFloat!, $roomTypeId: String!) {
    allRatePlansCachesList(filter: {discount: {equalTo: $discount}, roomTypeId: {equalTo: $roomTypeId}}) {
      ratePlanId
    }
  }
`;

export const GET_EVENT_HISTORY_BY_BOOKING_ID = gql`
  query getEventHistoryByBookingId($bookingId: String!) {
    eventHistoryByBookingID(bookingId: $bookingId) {      
      eventHistoryId: id
      event
      description
      createdAt
    }
  }
`;

export const GET_ALL_BOOKING_ADD_ONS = gql`
  query getAllBookingAddOns {
    validBookingAddons {
      addOnId: id
      title
      label
      description
      pricePerNight
      isOneTimePriced
    }
  }
`;

// Mutation
export const UPDATE_TENANT_INFO_BY_TENANT_ID = gql`
  mutation updateTenantInfoByTenantId($tenantId: String!, $input: UpdateTenantByTenantIdInput!) {
    updateTenant(id: $tenantId, data: $input) {
      id
    }
  }
`;

export const DELETE_TENANT_BY_TENANT_ID = gql`
  mutation deleteTenantByTenantId($tenantId: String!) {
    deleteTenant(id: $tenantId) {
      id
    }
  }
`;

export const UPDATE_STAFF_INFO_BY_STAFF_ID = gql`
  mutation updateStaffInfoByStaffId($staffId: String!, $input: CreateStaffInput!) {
    updateStaff(id: $staffId, data: $input) {
      id
    }
  }
`;

export const CREATE_CHARGE = gql`
  mutation createCharge($input: CreateChargeInput!) {
    createCharge(data: $input) {
      id
    }
  }
`;

export const SEARCH = gql`
  query search($input: SearchInput!) {
    search(data: $input) {
      id
    }
  }
`;

export const CREATE_REFUND = gql`
  mutation createRefund($input: CreateRefundInput!) {
    createRefund(data: $input) {
      id
    }
  }
`;

export const CREATE_CREDIT = gql`
  mutation createCredit($input: CreateDiscountInput!) {
    createCredit(data: $input) {
      id
    }
  }
`;

export const UNLOCK_DOOR = gql`
  mutation unlockDoor($roomNumber: String!) {
    unlockDoor(roomNumber: $roomNumber) {
      id
    }
  }
`;

export const INVALIDATE_KEY = gql`
  mutation invalidateKey($roomNumber: String!) {
    invalidateKey(roomNumber: $roomNumber) {
      id
    }
  }
`;

export const GENERATE_KEY = gql`
  mutation generateKey($input: GenerateKeyInput!) {
    generateKey(data: $input) {
      id
    }
  }
`;

export const CREATE_TENANT = gql`
  mutation createTenant($input: CreateTenantInput!) {
    createTenant(data: $input) {
      tenant {
        userId
        tenantId
      }
    }
  }
`;
