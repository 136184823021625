import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  
  & > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
