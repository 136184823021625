import styled from "styled-components";
import colors from "constants/colors";

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  h1 {
    color: ${colors.white1};
  }
`;
