import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as WarningSVG } from 'assets/svg/Warning.svg';
import { Container } from './styles';

const ImagesReview = ({bookingId}) => {
  
  const history = useHistory()

  return (
    <Container>
      <WarningSVG />
      <div
        className='warning'
      >
        Guest check-in is not reviewed.
      </div>
      <div
        className='highLight'
        onClick={() => {
          history.push(`/review-photos/${bookingId}`)
        }}
      >
        Review now {'>'}
      </div>
    </Container>
  )
}

export default ImagesReview;
