export const creditCardMask = {
  mask: '9999 9999 9999 9999',
};

export const expiryDateMask = {
  mask: [/[0-1]/, /[0-9]/, "/", /[2-9]/, /[0-9]/],
};

export const cvvMask = {
  mask: '9999',
};
