import styled from 'styled-components';
import colors from 'constants/colors';

export const BurgerMenuContainer = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 15px 0 15px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  
  div {
    width: 24px;
    height: 3px;
    background-color: ${colors.white1};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }

  img {
    ${(props) => (props.open ?`filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);`: null)};
  }

  @media only screen and (max-width: 1200px) {
    z-index: 5500;
  }
`;