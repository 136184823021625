import styled from 'styled-components';
import Search from 'assets/svg/Search.svg'
import colors from 'constants/colors';

export const StaffsContainer = styled.div`
  position: fixed;
  top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.black5};
  height: 100vh;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: calc(100vh - 110px);
  }

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 95px);
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 83px;
    padding: 20px 15px; 
    border-bottom: 1px solid ${colors.grey6};

    .title {
      white-space: nowrap;
      color: ${colors.white1};
      font-size: 17px;
      font-weight: bold;
      margin-right: 20px;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    padding: 0 15px;

    .header {
      padding-left: 0px;
      height: 100px;
    }
  }

  @media only screen and (max-width: 767px) {

    padding: 0 15px;

    .header {
      padding-left: 0px;
      height: 63px;
    }
  }
`;

export const StyledInput = styled.input`
  width: 35%;
  height: 49px;
  color: ${colors.white1};
  font-size: 18px;
  font-weight: 600;
  display: block;
  background-image: url(${Search});
  background-color: ${colors.grey28};
  background-repeat: no-repeat;
  background-position: 2% 50%;
  padding: 0 25px 0 40px;
  border: none;
  outline: none;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    width: 60%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledTable = styled.table`
  width: 100%;

  thead {
    height: 49px;
    vertical-align: bottom;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.white1};
    border-bottom: 1px solid ${colors.white1};

    .role {
      width: 10%;
      padding: 10px 20px;
    }

    .name {
      width: 28%;
      padding: 10px 20px;
    }

    .link {
      display: none;
    }

    .phone {
      width: 12%;
      padding: 10px 20px;
    }

    .email {
      width: 20%;
      padding: 10px 20px;
    }

    .created {
      width: 15%;
      padding: 10px 20px;
    }

    .lastLogin {
      width: 15%;
      padding: 10px 20px;
    }

    @media only screen and (min-width: 767px) and (max-width: 1200px) {

      font-size: 15px;

      .phone, .email, .created, .lastLogin {
        display: none;
      }

      .role {
        padding-left: 0px;
        width: 30%;
      }

      .name {
        width: 60%;
      }

      .link {
        width: 10%;
        display: table-cell;
      }
    }

    @media only screen and (max-width: 767px) {

      font-size: 15px;

      .phone, .email, .created, .lastLogin {
        display: none;
      }

      .role {
        padding-left: 0px;
        width: 20%;
      }

      .name {
        width: 80%;
      }
    }
  }
`;

export const StyledItem = styled.tr`
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.white1};
  background-color: ${colors.black5};
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid ${colors.grey23};
  padding-left: 5px;

  td {
    position: relative;
    padding: 0 0 0 20px;
  }
  
  .link {
    color: ${colors.blue1};
    display: none;
  }

  :hover {
    td:nth-child(1) {
      :before {
        content: '';
        position: absolute;
        top: 0px;
        left: 5px;
        width: 4px;
        height: 36px;
        background-color: ${colors.blue1};
      }
    }
    background-color: ${colors.black9};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    height: 48px;
    font-size: 14px;

    .phone, .email, .created, .lastLogin {
      display: none;
    }

    .role {
      padding-left: 0px;
    }

    .link {
      display: table-cell;
    }

    :hover {
      td:nth-child(1) {
        :before {
          content: none;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {

    height: 48px;
    font-size: 13px;

    .phone, .email, .created, .lastLogin {
      display: none;
    }

    .role {
      padding-left: 0px;
    }

    :hover {
      td:nth-child(1) {
        :before {
          content: none;
        }
      }
    }
  }
`;

export const NavigatorContainer = styled.div`
  width: 100%;
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40%;
  background-color: ${colors.grey2};

  .pageControl {
    display: flex;
  }

  .totalResults {
    position: absolute;
    left: 20px;
    font-size: 11px;
    font-weight: 600;
    color: ${colors.white1};
  }

  .totalPages {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.blue1};
  }

  input {
    width: 25px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin-right: 5px;
    border: none;

    :focus-visible {
      outline: none;
    }

    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    padding: 0 30%;

    .totalResults {
      left: 35px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 0 20%;
    .totalResults {
      display: none;
    }
  }
`;

export const Navigator = styled.div`
  color: ${colors.blue1};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  ${(props) => props.disabled ? `
    pointer-events:none;
    color: ${colors.grey8}
  `: null }
`;
