import styled from 'styled-components';
import colors from 'constants/colors';

export const MessageContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 60%;
  float: ${({staff})=> staff? 'left': 'right'};

  .createAt {
    text-align: ${({staff})=> staff? 'left': 'right'};
    font-size: 16px;
    font-weight: 600;
    color: ${colors.grey27};
    margin-bottom: 7px;
  }

  .content {
    font-size: 15px;
    color: ${colors.black5};
    background-color: ${({staff})=> staff? colors.green7: colors.blue8};
    border-radius: 11px;
    padding: 14px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 767px) {
    .createAt {
      font-size: 11px;
      margin-bottom: 3px;
    }

    .content {
      font-size: 13px;
    }
  }
`;
