import React, { useState } from 'react';
import { BurgerMenuContainer } from './styles';
import MainNav from 'components/MainNav';
import BurgerMenuIcon from 'assets/svg/BurgerMenu.svg';

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <BurgerMenuContainer open={open} onClick={() => setOpen(!open)}>
        <img src={BurgerMenuIcon} alt={'BurgerMenu'} />
      </BurgerMenuContainer>
      <MainNav open={open} setOpen={setOpen} />
    </>
  );
};

export default BurgerMenu;


