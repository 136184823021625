import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  margin: 0px auto 13px auto;
  ${(props) => props.scale? `transform: scale(${props.scale});`: null};
  
  @media only screen and (min-width: 1201px) {

    @media only screen and (min-height: 871px) {
      ${
        ({ desktop }) => (desktop? `
          top: 140px;
          transform: scale(2);
        `: null)
      };
    }

    @media only screen and (min-height: 801px) and (max-height: 870px) {
      ${
        ({ desktop }) => (desktop? `
          top: 100px;
          transform: scale(1.75);
        `: null)
      };
    }

    @media only screen and (min-height: 731px) and (max-height: 800px) {
      ${
        ({ desktop }) => (desktop? `
          top: 65px;
          transform: scale(1.5);
        `: null)
      };
    }

    @media only screen and (max-height: 730px) {
      ${
        ({ desktop }) => (desktop? `
          top: 30px;
          transform: scale(1.25);
        `: null)
      };
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    @media only screen and (min-height: 1021px) {
      ${
        ({ desktop }) => (desktop? `
          top: 140px;
          transform: scale(2);
        `: null)
      };
    }

    @media only screen and (min-height: 941px) and (max-height: 1020px) {
      ${
        ({ desktop }) => (desktop? `
          top: 100px;
          transform: scale(1.75);
        `: null)
      };
    }

    @media only screen and (min-height: 851px) and (max-height: 940px) {
      ${
        ({ desktop }) => (desktop? `
          top: 65px;
          transform: scale(1.5);
        `: null)
      };
    }

    @media only screen and (min-height: 771px) and (max-height: 850px) {
      ${
        ({ desktop }) => (desktop? `
          top: 30px;
          transform: scale(1.25);
        `: null)
      };
    }

    @media only screen and (max-height: 770px) {
      ${
        ({ desktop }) => (desktop? `
          top: -10px;
        `: null)
      };
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 767px) {
    ${
      ({ desktop }) => (desktop? `
        top: 65px;
        transform: scale(1.5);
      `: null)
    };
  }

  @media only screen and (min-width: 401px) and (max-width: 500px) {
    ${
      ({ desktop }) => (desktop? `
        top: 30px;
        transform: scale(1.2);
      `: null)
    };
  }

  @media only screen and (min-width: 375px) and (max-width: 400px)  {
    ${
      ({ desktop }) => (desktop? `
        top: 20px;
        transform: scale(1.1);
      `: null)
    };
  }

  .top{
    display: flex;
  }

  .middle {
    display: flex;

    .left {
      display: flex;
      flex-direction: column;
    }

    .right {
      display: flex;
      flex-direction: column;
    }
  }

  .bottom {
    display: flex;
    align-items: flex-end;
  }
`;

export const FloorImageCenterContent = styled.div`
  display: flex;
  padding: 5px 8px;
  width: 211px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  & > div {
    margin: 0 auto;
  }

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div:first-child {
      width: 56px;
      height: 84px;
      margin: 20px auto 0 auto;
      position: relative;
      box-sizing: border-box;
      top:-68px;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
  }
`;

export const EightfloorInnerLeftBox = styled.div`
  position: relative;
  width: 57px;
  top: -12px;
  right: 0px;

  .saunaRoom {
    display: flex;
    width: 61px;
    height: 26px;
    margin: 2px;
  }
`;

export const EightfloorInnerCenterBox = styled.div`
  position: relative;
  left: -5px;
  top: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  & > div {
    display: flex;
    margin: 2px;
  }

  .row1 {
    flex-direction: row;
    width: 100%;
    height: 40px;
    & > div:first-child {
      flex: 1;
      width: 100%;
      height: 100%;
      background-color: ${colors.grey20};
      margin-right: 2px;
    }
    & > div:last-child {
      flex: 2;
      width: 100%;
      height: 100%;
      background-color: ${colors.grey19};
      margin-left: 2px;
    }
  }

  .row2 {
    width: 100%;
    height: 47px;
    background-color: ${colors.grey20};
  }

  .row3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 21px;
    background-color: ${colors.grey21};
  }
`;

export const CenterBoxContainer = styled.div`
  position: relative;
  top: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  & > div {
    display: flex;
    margin: 2px;
  }

  .row1 {
    flex-direction: row;
    width: 100%;
    height: 40px;
    & > div:first-child {
      flex: 1;
      width: 100%;
      height: 100%;
      background-color: ${colors.grey20};
      margin-right: 2px;
    }
    & > div:last-child {
      flex: 2;
      width: 100%;
      height: 100%;
      background-color: ${colors.grey19};
      margin-left: 2px;
    }
  }

  .row2 {
    width: 100%;
    height: 47px;
    background-color: ${colors.grey20};
  }

  .row3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 21px;
    background-color: ${colors.grey21};
  }

  .row4 {
    position: absolute;
    top: 139px;
    flex-direction: row;

    & > div:nth-child(1) {
      position: relative;
      left: -8px;
    }

    & > div:nth-child(2){
      position: relative;
      right: -8px;
    }
  }
`;

export const EightfloorInnerRightBox = styled.div`
  position: relative;
  width: 44px;
  top: -25px;
  left: -5px;
  
  .saunaRoom {
    display: flex;
    width: 48px;
    height: 41px;
    margin: 2px;
  }

  .femaleLockerRoom {
    width: 100%;
    height: 76px;
    margin: 2px;
  }
`;

