import styled from 'styled-components';
import colors from 'constants/colors';

export const Menu = styled.div`
  z-index: 5000;
  list-style: none;
  display: flex;
  background-color: ${colors.black3};
  flex-flow: column nowrap;
  position: fixed;
  transform: ${({ open }) => (open? 'translateX(0)': 'translateX(100%)')};
  top: 0;
  right: 0;
  width: 400px;
  transition: transform 0.3s ease-in-out;
  padding: 50px 0 50px 0;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    width: 100%;
    padding: 70px 0 40px 0;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 60px 0 30px 0;
  }
`;

export const Title = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
  line-height: 50px;
  font-size: 30px;
  font-weight: 300;
  color: ${colors.white1};

  img {
    width:20px;
    height:20px;
    cursor: pointer;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    left: 27px;
    top: 10px;
  }

  @media only screen and (max-width: 767px) {
    left: 20px;
    top: 0;
    img {
      width:15px;
      height:15px;
    }
  }
`;

export const Item = styled.div`
  padding: 8px 22px;
  margin: 0 0 5px 0;
  color: ${({ active }) => (active ? colors.white1 : colors.grey24)};
  background-color: ${colors.black3};
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: ${colors.white1};
    background-color: ${colors.black9};
    border-left: 5px solid ${colors.blue1};
    padding-left: 17px;
  }

  @media only screen and (max-width: 1200px) {
    ${({ desktop }) => (desktop ? `display: none;` : null)};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 22px;
    font-weight: 600;
    padding: 6px 27px;
    :hover {
      padding-left: 22px;
    }
  }

  @media only screen and (max-width: 767px) {
    font-size: 22px;
    font-weight: 600;
    padding: 4px 20px;
    :hover {
      padding-left: 15px;
    }
  }
`;


export const SubMenuItem = styled.div`
  padding: 8px 22px;
  cursor: pointer;
  color: ${({ active }) => (active ? colors.white1 : colors.grey24)};
  background-color: ${colors.black3}; 
  font-size: 16px;
  font-weight: 300; 
  margin-left:30px;

  :hover {
    color: ${colors.white1};
    background-color: ${colors.black9};
    border-left: 5px solid ${colors.blue1};
    padding-left: 17px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 6px 27px;
    font-size: 18px;
    :hover {
      padding-left: 22px;
    }
  }

  @media only screen and (max-width: 767px) {
    font-size: 15px;
    padding: 4px 20px;
    margin-left: 20px;
    :hover {
      padding-left: 15px;
    }
  }
`;