import React from 'react';
import { ItemRendererContainer } from '../styles';
import IncreaseArrow from 'assets/icons/increaseArrow.svg'
import DecreaseArrow from 'assets/icons/decreaseArrow.svg'

const ItemRenderer = ({ itemContext, getItemProps }) => {
  const [ a, b ] = itemContext.title.split(' ')
  return (
    <div
      {...getItemProps({
        style: {
          background: 'transparent !important',
          border: 'transparent !important',
        },
      })}
    >
      <div
        className="rct-item-content"
        style={{
          maxHeight: '44px',
          height: '44px',
        }}
      >
        <ItemRendererContainer>
          <div className="row">
            <div>{a}</div>
            {
              b ? 
              <div className="increase">
                <img src={IncreaseArrow} alt={'increaseArrow'}/> {b}
              </div>:
              <div className="decrease">
                <img src={DecreaseArrow} alt={'decreaseArrow'} /> {b}
              </div>
            }
          </div>
        </ItemRendererContainer>
      </div>
    </div>
  )
}

export default ItemRenderer;
