const statusMap = {
  OCCUPIED: 'Occupied',
  OCCUPIED_CHECK_OUT_TODAY: 'Occupied(Check out today)',
  OCCUPIED_STAYOVER: 'Occupied(Stayover, Action Req)',
  INSPECTED: 'Room Inspected',
  CLEAN: 'Room Clean',     
  OUT_OF_ORDER: 'Out of Order',
  DIRTY: 'Room Dirty'
};

export default statusMap;
