import React from 'react';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import { TimePickerContainer } from './styles';
import { TimeIcon } from 'assets/icons';
import moment from 'moment';

const TimePicker = ({
  disabled,
  error,
  TimeValue,
  setTime,
  additionalStyle,
  variant,
}) => {

  return (
    <TimePickerContainer additionalStyle={additionalStyle}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          slots={{
            openPickerIcon: TimeIcon
          }}
          disabled={disabled}
          fullWidth
          label=" "
          value={TimeValue}
          format="hh:mm a"
          onChange={(e)=> {
            setTime(moment(e))
          }}
          renderInput={(props) => 
            <TextField
              {...props}
              variant={variant}
              error={error}
              autoComplete="off"
              InputProps={{
                ...props.InputProps,
                notched: false,
                id: "TimePickerInput",
              }}
            />            
          }
        />
      </LocalizationProvider>
    </TimePickerContainer>
  );
};

export default TimePicker;
