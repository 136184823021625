import React from "react";
import Card from "components/Card";
import CardHeader from "components/CardHeader";
import ModalAddCredit from 'screens/ModalAddCredit';
import ModalAddCharge from 'screens/ModalAddCharge';
import { dateDiff } from "utils/util";
import { BillOfSaleContainer, BillItemContainer } from "./styles";

const BillOfSale = ({ group, refetch, groupId }) => {

  const bill = group.bills[0]
  const billArray = []
  
  group.bookings.forEach((booking) => {
    const nights = dateDiff(new Date(booking.checkInDate), new Date(booking.checkOutDate))
    const roomType = booking.roomBookings[0]?.room?.roomType
    billArray.push(
      <BillItemContainer
        key={booking.bookingId}
      >
        <div className='description'>
          {`1 ${booking.roomBookings[0]?.room?.roomType?.name} -
          ${nights} night${nights > 1 ? 's,' : ', '}
            ${roomType?.bed || 'Queen bed'}
             w/ ${roomType?.hasWindows? 'windows': 'skylight'}`}
        </div>
        <div className='price'>
          {'$' + (booking.roomBookings[0].pricePerNight * nights).toFixed(2)}
        </div>
      </BillItemContainer>
    )
  })

  if(bill.ratePlanAmount > 0) {
    billArray.push(
      <BillItemContainer
        key={'ratePlan'}
      >
        <div className='description'>
          {'Advanced Purchase (10% off)'}
        </div>
        <div className='price'>
          {'-$' + bill.ratePlanAmount.toFixed(2)}
        </div>
      </BillItemContainer>
    )
  }

  if(group.coupon) {
    billArray.push(
      <BillItemContainer
        key={'coupon'}
      >
        <div className='description'>
          {`Coupon (${group.coupon?.rateType==='FIXED_RATE'? '-$'+ group.coupon?.discount + ' off': group.coupon?.discount + '% off'})`}
        </div>
        <div className='price'>
          {'-$' + bill.subTotal.toFixed(2)}
        </div>
      </BillItemContainer>
    )
  }

  billArray.push(
    <BillItemContainer
      key={'MAT'}
    >
      <div className='description'>
        {'Municipal Accommodation Tax'}
      </div>
      <div className='price'>
        {'$' + bill.MAT.toFixed(2)}
      </div>
    </BillItemContainer>
  )

  //FIXME: Add on bill is not handled properly by backend yet
  if(bill.addonPrice > 0) {
    const addOnQuantity = {}
    addOnQuantity['Parking'] = group.bookings?.reduce((acc, curr)=> 
        acc + curr?.addons?.filter((addon) => addon?.bookingAddOn?.label === 'Parking')?.quantity
    , 0) || 0

    group.bookings.forEach((booking) => {
      billArray.push(
        <BillItemContainer
          key={'addOnFee'}
        >
          <div className='description'>
            {bill.description}
          </div>
          <div className='price'>
            {'$' + bill.addonPrice.toFixed(2)}
          </div>
        </BillItemContainer>
      )
    })
  }

  billArray.push(
    <BillItemContainer
      key={'HST'}
    >
      <div className='description'>
        {'HST'}
      </div>
      <div className='price'>
        {'$' + bill.HST.toFixed(2)}
      </div>
    </BillItemContainer>
  )

  return (
    <Card
      additionalStyle={`
        @media only screen and (max-width: 767px) {

          .type {
            width: 100%;
            margin-bottom: 10px; 
          }

          .buttonContainer {
            width: 100%;
            justify-content: space-between;
          }
          
          flex-direction: column;
          justify-content: center;
        }          
      `}
    >
      <CardHeader>
        <div className='type'>BILL OF SALE</div>
        <div className='buttonContainer'>
          <ModalAddCredit
            buttonStyle={`
              width: 113px;
              margin-left: 14px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 122px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 95px;
                margin-left: 10px;
              }
            `}
            groupId={groupId}
            refetch={refetch}
          />
          <ModalAddCharge
            buttonStyle={`
              width: 113px;
              margin-left: 14px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 122px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 95px;
                margin-left: 10px;
              }
            `}
            groupId={groupId}
            refetch={refetch}
          />
        </div>
      </CardHeader>
      <div>
        {billArray}
      </div>
      <BillOfSaleContainer>
        <div className="total">TOTAL</div>
        <div className="value">
          {"$" + bill.total.toFixed(2) || "$0:00"}                  
        </div>
      </BillOfSaleContainer>
    </Card>
  )
}

export default BillOfSale;
