import React from 'react';
import Room from 'screens/Home/components/Room';
import { Container, CenterBoxContainer, FloorImageCenterContent } from './styles';

const TenthFloor = ({ scale, desktop, backgroundColor, rooms }) => {

  return (
    <Container scale={scale} desktop={desktop} backgroundColor={backgroundColor}>
      <div className='top'>
        <Room className="room10_32" width="46px" height="48px" roomData={rooms['1032']} roomNumber='1032' floorBackgroundColor={backgroundColor} />
        <Room className="room10_33" width="23px" height="43px" roomData={rooms['1033']} roomNumber='1033' floorBackgroundColor={backgroundColor} />
        <Room className="room10_35" width="26px" height="43px" roomData={rooms['1035']} roomNumber='1035' floorBackgroundColor={backgroundColor} />
        <Room width="84px" height="43px" disabled={true} />
        <Room className="room10_3" width="25px" height="43px" roomData={rooms['1003']} roomNumber='1003' floorBackgroundColor={backgroundColor} />
        <Room className="room10_5" width="25px" height="43px" roomData={rooms['1005']} roomNumber='1005' floorBackgroundColor={backgroundColor} />
        <Room className="room10_6" width="46px" height="48px" roomData={rooms['1006']} roomNumber='1006' floorBackgroundColor={backgroundColor} />
      </div>
      <div className='middle'>
        <div className='left'>
          <Room className="room10_31" width="42px" height="23px" roomData={rooms['1031']} roomNumber='1031' floorBackgroundColor={backgroundColor} />
          <Room className="room10_30" width="42px" height="24px" roomData={rooms['1030']} roomNumber='1030' floorBackgroundColor={backgroundColor} />
          <Room className="room10_29" width="42px" height="35px" roomData={rooms['1029']} roomNumber='1029' floorBackgroundColor={backgroundColor} />
          <Room className="room10_28" width="42px" height="35px" roomData={rooms['1028']} roomNumber='1028' floorBackgroundColor={backgroundColor} />
          <Room className="room10_27" width="42px" height="24px" roomData={rooms['1027']} roomNumber='1027' floorBackgroundColor={backgroundColor} />
          <Room className="room10_25" width="42px" height="23px" roomData={rooms['1025']} roomNumber='1025' floorBackgroundColor={backgroundColor} />
        </div>
        <FloorImageCenterContent>
          <div>
            <Room className="room10_34" width="40px" height="41px" roomData={rooms['1034']} roomNumber='1034' floorBackgroundColor={backgroundColor} />
            <Room className="room10_36" width="40px" height="38px" roomData={rooms['1036']} roomNumber='1036' floorBackgroundColor={backgroundColor} />
            <Room className="room10_37" width="40px" height="38px" roomData={rooms['1037']} roomNumber='1037' floorBackgroundColor={backgroundColor} />
            <Room className="room10_26" width="40px" height="38px" roomData={rooms['1026']} roomNumber='1026' floorBackgroundColor={backgroundColor} />
          </div>
          <div>
            {/* ceneter box */}
            <div>
            <CenterBoxContainer>
                <div className="row1">
                  <div></div>
                  <div></div>
                </div>
                <div className="row2"></div>
                <div className="row3">
                  <Room className="room10_54" roomData={rooms['1054']} roomNumber='1054' roomName="leftElevator" disabled={true} />
                  <Room className="room10_55" roomData={rooms['1055']} roomNumber='1055' roomName="rightElevator" disabled={true} />
                </div>
                <div className="row4">
                  <Room className="room10_20"width="33px" height="40px" roomData={rooms['1020']} roomNumber='1020' floorBackgroundColor={backgroundColor} />
                  <Room className="room10_18" width="33px" height="40px" roomData={rooms['1018']} roomNumber='1018' floorBackgroundColor={backgroundColor} />
                </div>
              </CenterBoxContainer>
            </div>

          </div>
          <div>
            <Room className="room10_4" width="40px" height="41px" roomData={rooms['1004']} roomNumber='1004' floorBackgroundColor={backgroundColor} />
            <Room className="room10_2" width="40px" height="38px" roomData={rooms['1002']} roomNumber='1002' floorBackgroundColor={backgroundColor} />
            <Room className="room10_1" width="40px" height="38px" roomData={rooms['1001']} roomNumber='1001' floorBackgroundColor={backgroundColor} />
            <Room className="room10_12" width="40px" height="38px" roomData={rooms['1012']} roomNumber='1012' floorBackgroundColor={backgroundColor} />
          </div>
        </FloorImageCenterContent>
        <div className='right'>
          <Room className="room10_7" width="42px" height="23px" roomData={rooms['1007']} roomNumber='1007' floorBackgroundColor={backgroundColor} />
          <Room className="room10_8" width="42px" height="24px" roomData={rooms['1008']} roomNumber='1008' floorBackgroundColor={backgroundColor} />
          <Room className="room10_9" width="42px" height="35px" roomData={rooms['1009']} roomNumber='1009' floorBackgroundColor={backgroundColor} />
          <Room className="room10_10" width="42px" height="35px" roomData={rooms['1010']} roomNumber='1010' floorBackgroundColor={backgroundColor} />
          <Room className="room10_11" width="42px" height="24px" roomData={rooms['1011']} roomNumber='1011' floorBackgroundColor={backgroundColor} />
          <Room className="room10_13" width="42px" height="23px" roomData={rooms['1013']} roomNumber='1013' floorBackgroundColor={backgroundColor} />
        </div>
      </div>
      <div className='bottom'>
        <Room className="room10_24" width="46px" height="48px" roomData={rooms['1024']} roomNumber='1024' floorBackgroundColor={backgroundColor} />
        <Room className="room10_23" width="22px" height="43px" roomData={rooms['1023']} roomNumber='1023' floorBackgroundColor={backgroundColor} />
        <Room className="room10_22" width="22px" height="43px" roomData={rooms['1022']} roomNumber='1022' floorBackgroundColor={backgroundColor} />
        <Room className="room10_21" width="23px" height="43px" roomData={rooms['1021']} roomNumber='1021' floorBackgroundColor={backgroundColor} />
        <Room className="room10_19" width="41px" height="43px" roomData={rooms['1019']} roomNumber='1019' floorBackgroundColor={backgroundColor} />
        <Room className="room10_17" width="23px" height="43px" roomData={rooms['1017']} roomNumber='1017' floorBackgroundColor={backgroundColor} />
        <Room className="room10_16" width="22px" height="43px" roomData={rooms['1016']} roomNumber='1016' floorBackgroundColor={backgroundColor} />
        <Room className="room10_15" width="22px" height="43px" roomData={rooms['1015']} roomNumber='1015' floorBackgroundColor={backgroundColor} />
        <Room className="room10_14" width="46px" height="48px" roomData={rooms['1014']} roomNumber='1014' floorBackgroundColor={backgroundColor} />
      </div>
    </Container>
  )
}

export default TenthFloor