import React from 'react';
import { Container, StaffInformationImagesItem } from './styles';
import SvgImageFace from 'assets/svg/Face.svg';
import ModalViewImage from 'screens/ModalViewImage';
import Image from 'assets/icons/Male.svg'
import colors from 'constants/colors';

const StaffInformationImages = () => {

  return (
    <Container>
      <div>VIEW IMAGES:</div>
      <StaffInformationImagesItem>
        <ModalViewImage
          buttonLabel={'Staff Photo 1'}
          fullName={'FirstName LastName'}
          image={Image}
          buttonStyle={`
            background-color: transparent;
            background-image: url(${SvgImageFace});
            background-repeat: no-repeat;
            background-position: 0 40%;
            padding-left: 30px;
            color: ${colors.green1};
          `}
        />  
      </StaffInformationImagesItem>
    </Container>
  )
}

export default StaffInformationImages;
