import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetRedux } from 'redux/actions'
import { useLazyQuery, useMutation } from '@apollo/client';
import { client } from 'graphql/apolloClient';
import { LOGIN, GET_USER_INFO_BY_USER_ID } from 'graphql/service';
import jwt_decode from "jwt-decode"
import { message } from 'antd'
import { getMessageTimeOut } from 'utils/util';


/**
 * A custom hook for auth includes:
 * - authProvide
 * - useAuthContext
 */

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const history = useHistory();
  const dispatch = useDispatch()
  const [ validToken, setValidToken ] = useState(localStorage.getItem('token'))
  const [ userId, setUserId ] = useState(localStorage.getItem('userId'))

  const [getUserInfoByUserId, { data, loading, error }] = useLazyQuery(GET_USER_INFO_BY_USER_ID, 
    {
      onCompleted: (userInfoData)=>{
        if (userInfoData?.userById?.fullName) {
          localStorage.setItem('fullName', userInfoData.userById.fullName)
        } else {
          localStorage.clear()
          setUserId(null)
          setValidToken(null)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  );

  const [login, { data: loginData, loading: loginLoading, error: loginError }]  = useMutation(
    LOGIN,
    { 
      onCompleted: (loginData) => {
        if(loginData) {
          const token = loginData.authenticate?.accessToken        
          const decoded = jwt_decode(token)
          if(decoded.user_type === 'TENANT') {
            const content = 'Unauthorized User Type!'
            message.error({
              content,
              duration: getMessageTimeOut(content),
              onClick: () => {
                message.destroy()
              }
            })
            userLogout()
          } else {
            localStorage.setItem('userId', decoded.sub)
            localStorage.setItem('token', token)
            setUserId(decoded.sub)
            setValidToken(token)
            history.push('/')
          }
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  useEffect(()=>{
    if(userId) {
      getUserInfoByUserId({ variables: { userId: userId } });
    }
  },[userId])

  const userLogout = async () => {

    localStorage.clear()
    client.clearStore()
    setValidToken(null)
    dispatch(resetRedux())
    message.success('Log out successfully!')
    history.push('/login')
  }

  const userLogin = async (email, password, setPasswordError) => {

    login({
      variables: {
        input: {
          login: email,
          password: password
        }
      }
    })

    if (loginError) {
      setPasswordError(true)
    }
  }

  const memoedValue = useMemo(
    () => ({
      validToken,
      userLogin,
      userLogout
    }));

  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  )
}


const useAuth = () => {
  return useContext(AuthContext);
}  

export default useAuth
