import styled from 'styled-components';
import colors from 'constants/colors';

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${colors.black5};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    display: block;
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 767px) {
    display: block;
    padding-bottom: 60px;
  }
`;

export const ContentColumn = styled.div`
  width: 50%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 15px;
  background-color: ${colors.black5};
  letter-spacing: 2.5px;

  @media only screen and (min-width: 1201px){
    ${({ mobile }) => (mobile? `display: none;` : null)};
  }
  @media only screen and (max-width: 1200px) {
    ${({ desktop }) => (desktop? `display: none;` : null)};
  }
`;

export const DesktopCommunicationContainer = styled.div`
  flex-basis: 100%;
  padding: 15px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  & > div:first-child {
    background-color: ${colors.grey3};
  }

  & > div {
    color: ${colors.white1};
    background-color: ${colors.grey2};
    display: flex;
    flex-wrap: wrap;
  }
`;

export const MobileCommunicationContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  height: 92px;
  padding: 0 30px;

  img {
    margin-right: 10px;  
  } 

  @media only screen and (min-width: 1201px) {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    height: 107px;
  }
`;
