import styled from 'styled-components';
import colors from 'constants/colors';

export const AccountMenuContainer = styled.div`

  width: 48px;
  height: 48px;
  position: absolute;
  top: 0px;
  right: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  div {
    width: 24px;
    height: 3px;
    background-color: ${colors.white1};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }

  img {
    ${(props) => (props.open ?`filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);`: null)};
  }

  @media only screen and (max-width: 1200px) {
    right: 54px;
    z-index: 5500;
  }
`;