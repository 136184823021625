import styled from 'styled-components';
import colors from 'constants/colors';

export const PhoneNumberInputContainer = styled.div`

  width: 100%;
  position: relative;

  label {
    width: 100%;
    display: block;
    font-size: ${(props) => (props.mainPage ? '10px' : '11px')};
    font-weight: ${(props) => (props.mainPage ? '600' : '300')};
    color: ${(props) => (props.error? colors.red1:props.mainPage ? colors.grey5 : colors.white1)};
    letter-spacing: 2.5px;
    margin-bottom: 5px;
    white-space: nowrap;
    text-align: ${(props) => props.labelPosition === 'center'? 'center': 'left'};
  }

  :focus-visible {
    outline: none;
  }

  .iti {
    width: 100%;

    .iti__country-container {
      :focus-visible {
        outline: none;
      }

      button {
        :focus-visible, :focus {
          outline: none;
        }

        .iti__selected-country-primary {
          :focus-visible {
            outline: none;
          }

          :hover {
            background-color: ${colors.grey3} !important;
          }
        }
      }

      .iti__dropdown-content {
        border: none;

        input {
          font-size: 14px;
          font-weight: 600;
          font-family: 'Titillium Web', sans-serif;
          color: ${colors.white1};
          background-color: ${colors.black5};

          padding-left: 10px;

          :focus-visible {
            outline: none;
            background-color: ${colors.black5};
          }
        }

        ul {
          max-height: 290px;
          position: absolute;
          overflow-y: scroll;
          top: ${(props) => (props.title ? '60px' : '40px')};
          letter-spacing: 0.5px;
          width: 100%;
          border: solid ${colors.grey1};
          border-width: 0 1px 1px 1px; 
          z-index: 2500;

          li {
            width: 100%;
            height: 36px;
            line-height: 35px;
            font-size: 14px;
            font-weight: normal;
            padding-left: 10px;
            color: ${colors.white1};
            background-color: ${colors.grey17};
            overflow: hidden;

            .highLight {
              color: ${colors.white1};
            }

            :hover {
              color: ${colors.blue1};
              border-left: 5px solid ${colors.blue1};
              background-color: ${colors.black9};
              padding-left: 5px;

              .highLight {
                font-weight: bold;
                color: ${colors.blue1};
              }
            }
          }

          ::-webkit-scrollbar { 
            width: 0 !important; 
          }

          ::-moz-scrollbar {
            width: 0 !important; 
          }
        }
      }
    }

    input {
      font-size: ${(props) => (props.mainPage ? '14px' : '18px')};
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      border: 1px solid
        ${(props) => props.error? colors.red1: (props.borderColor? props.borderColor : colors.grey1)};
      color: ${(props) => props.inputTextColor? props.inputTextColor : colors.white1};
      padding-left: 10px;
      background-color: ${(props) => (props.mainPage ? 'transparent' : colors.grey17)};

      :focus-visible {
        outline: none;
        background-color: ${colors.black5};
      }
    }
  }


  .warning {
    width: ${(props) => (props.width? props.width: null)};
    height: 20px;
    position: absolute;
    color: ${colors.red1};
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    span {
      font-size: 11px;
    }

    .PhoneInput {
      height: 46px;
    }
    
    .PhoneInputCountry {
      height: 46px;
    }

    input {
      height: 46px;
    }
  }

  @media only screen and (max-width: 767px) {

    span {
      font-size: 11px;
    }

    .PhoneInput {
      height: 44px;
    }

    .PhoneInputCountry {
      height: 44px;
    }

    input {
      height: 44px;
    }
  }

  ${(props) => props.additionalStyle};
`;