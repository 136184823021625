import React, { useEffect } from 'react';
import { PriceTableContainer } from '../style';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const PriceTable = ({ checkInDate, checkOutDate }) => {

  useEffect(() => {
    if (moment(checkInDate) >= moment(checkOutDate)) {
      const content = 'Check out date must be greater than check in date'
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  }, [checkInDate, checkOutDate]);

  const generalDates = () => {
    const length = moment(checkOutDate).diff(moment(checkInDate), 'days');
    const dates = [];
    for (let index = 0; index <= length; index++) {
      dates.push(moment(checkInDate).add(index, 'days').format('M/DD/YYYY'))
    }
    return dates;
  }

  return (
    <PriceTableContainer>
      <thead>
        <tr>
          <th className="titleColumn"></th>
          {
            generalDates().map((date, index) => (
              <th className="dataColumn" key={`${date}+${index}`}>{date}</th>
            ))
          }
          <th className="dataColumn"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="title titleBorder">Occupancy</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn">62%</td>
          <td className="commonColumn"></td>
        </tr>
        <tr>
          <td className="title titleBorder">Rook</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 1</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 2</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr className="bottomRow">
          <td className="subTitle title bottomBorder">Rate Plan 3</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn"></td>
        </tr>
        <tr>
          <td className="title titleBorder">Bishop</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 1</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 2</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr className="bottomRow">
          <td className="subTitle title bottomBorder">Rate Plan 3</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn"></td>
        </tr>
        <tr>
          <td className="title titleBorder">Rook Inside</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 1</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 2</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr className="bottomRow">
          <td className="subTitle title bottomBorder">Rate Plan 3</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn"></td>
        </tr>
        <tr>
          <td className="title titleBorder">Rook BF</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 1</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr>
          <td className="subTitle title titleBorder">Rate Plan 2</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn">$123</td>
          <td className="commonColumn"></td>
        </tr>
        <tr className="bottomRow">
          <td className="subTitle title bottomBorder">Rate Plan 3</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn">$123</td>
          <td className="bottomColumn"></td>
        </tr>
      </tbody>
    </PriceTableContainer>
  );
};

export default PriceTable;
