import moment from 'moment';

//fake data generator for testing timeline
const generateFakeData = () => {
  const groups = [
    {
      id: '1',
      title: 'Expedia',
      height: 44,
    },
    {
      id: '2',
      title: 'Booking.com',
      height: 44,
    },
    {
      id: '3',
      title: 'Web Direct',
      height: 44,
    },
    {
      id: '4',
      title: 'Lorem Ipsum',
      height: 44,
    },
    {
      id: '5',
      title: 'Lorem Ipsum',
      height: 44,
    },
  ];

  const items = [
    {
      id: '10',
      group: '2',
      title: '8% 10%',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    {
      id: '11',
      group: '1',
      title: '8% 10%',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    {
      id: '12',
      group: '3',
      title: '8% 10%',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    {
      id: '13',
      group: '5',
      title: '8% 10%',
      start: moment().add(2, 'days').startOf('day'),
      end: moment().add(2, 'days').endOf('day'),
    },
    {
      id: '14',
      group: '4',
      title: '8% 10%',
      start: moment().add(2, 'days').startOf('day'),
      end: moment().add(2, 'days').endOf('day'),
    },
  ];

  return { groups, items };
};

export default generateFakeData;
