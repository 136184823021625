import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Container, RemainingContainer, Title, RemainingItem } from './styles';

const SideBar = ({remaining, bookingId}) => {  

  const history = useHistory()

  return (
    <Container>
      {
        remaining.length? (
          <>
            <RemainingContainer>
              <Title>Now Viewing</Title>
              { 
                remaining.filter(item=>item.bookingId===bookingId).map((item, index) => {
                  return (
                      <RemainingItem key={index}>
                        <div>Arrived {moment(item.checkinByCheckinId?.createAt).format('D/M/YYYY @ h:mm A')}</div>
                        <div>{item.name}</div>
                      </RemainingItem>
                  )               
                })
              }
            </RemainingContainer>
            <RemainingContainer>
              <Title>Remaining ({remaining.length-1})</Title>
              {
                remaining.filter(item=>item.bookingId!==bookingId).map((item, index) =>  {
                  return (
                    <RemainingItem
                      key={index}
                      onClick={()=>{
                        history.push(`/review-photos/${item.bookingId}`)
                      }}
                    >
                      <div>Arrived 21/6/2020 @ 5:13 PM</div>
                      <div>{item.name}</div>
                    </RemainingItem>
                  )
                })
              }
            </RemainingContainer>
          </>
        ): (
          <>
            <RemainingContainer>
              <Title>Now Viewing</Title>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
            </RemainingContainer>
            <RemainingContainer>
              <Title>Remaining (4)</Title>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
              <RemainingItem>
                <div>Arrived 21/6/2020 @ 5:13 PM</div>
                <div>Jane Doe</div>
              </RemainingItem>
            </RemainingContainer>
          </>
        )
      }
    </Container>
  )
}

export default SideBar;
