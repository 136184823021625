import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.grey2};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    ${({ mobile }) => (mobile? `
      height: calc(100vh - 140px);
    `: null
    )};
  }

  @media only screen and (max-width: 767px) {
    ${({ mobile }) => (mobile? `
      height: calc(100vh - 125px);
    `: null
    )};
  }
`;

export const AlertContainer = styled.div`
  height: calc(100% - 80px);
  overflow: auto;
  letter-spacing: 0.5px;

  ::-webkit-scrollbar { 
    width: 0 !important; 
  }

  ::-moz-scrollbar {
    width: 0 !important; 
  }
  
  & > div {
    .link {
      width: 10%;
      height: 100%;
      min-width: 25px;
      max-width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 10px;
        height: 15px;
        display: block;
        position: relative;
      }
    }
  }

  & > div:nth-child(odd) {
    background-color: ${colors.grey3};

    .link {
      background-color: ${colors.grey2};
    }
  }

  & > div:nth-child(even) {
    background-color: ${colors.grey2};

    .link {
      background-color: ${colors.grey4};
    }
  }

  & > div:last-child {
    text-align: center;
    width: 100%;
    margin: 30px 0px;
    font-size: 12px;
    font-weight: 600;
    color: ${colors.grey16};
    background-color: ${colors.grey2};

    ${(props)=> props.mobile? `display: none`: null};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    ${({ mobile }) => (mobile? `
      height: calc(100vh - 220px);
    `: null
    )};
  }

  @media only screen and (max-width: 767px) {
    ${({ mobile }) => (mobile? `
      height: calc(100vh - 205px);
    `: null
    )};
  }
`;


