import React, { useRef, useEffect, useState, useCallback } from 'react'
import waveformAvgChunker from './waveformAvgChunker'
import useSetTrackProgress from 'customHook/useSetTrackProgress'
import useWindowDimensions from 'customHook/useWindowDimensions'
import { WaveformContainer } from './styles'
import colors from 'constants/colors'

const Waveform = ({ waveformData, audio }) => {

  const pointCoordinates = ({
    index,
    pointWidth,
    pointMargin,
    canvasHeight,
    amplitude,
  }) => {
    const pointHeight = Math.round((amplitude / 100) * canvasHeight)
    const verticalCenter = Math.round((canvasHeight - pointHeight) / 2)
    return [
      index * (pointWidth + pointMargin), // x starting point
      (canvasHeight - pointHeight) - verticalCenter, // y starting point
      pointWidth, // width
      pointHeight, // height
    ]
  }
  
  const paintCanvas = ({
    canvasRef,
    waveformData,
    canvasHeight,
    pointWidth,
    pointMargin,
    playingPoint,
    hoverXCoord,
  }) => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    // On every canvas update, erase the canvas before painting
    // If you don't do this, you'll end up stacking waveforms and waveform
    // colors on top of each other
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    waveformData.forEach(
      (p, i) => {
        ctx.beginPath()
        const coordinates = pointCoordinates({
          index: i,
          pointWidth,
          pointMargin,
          canvasHeight,
          amplitude: p,
        })
        ctx.rect(...coordinates)
        const withinHover = hoverXCoord >= coordinates[0]
        const alreadyPlayed = i < playingPoint

        if (withinHover) {
          ctx.fillStyle = alreadyPlayed ? colors.purple1 : colors.pink1
        } else if (alreadyPlayed) {
          ctx.fillStyle = colors.black5
        } else {
          ctx.fillStyle = colors.green8
        }
        ctx.fill()
      }
    )
  }

  const canvasRef = useRef()
  const { width } = useWindowDimensions();
  let waveformWidth
  if(width>=768) {
    waveformWidth = ((width - 60) * 0.44) - 100
  } else {
    waveformWidth = width - 160
  }
  const displayableChunks = 50
  const canvasHeight = 56
  const pointWidth = waveformWidth / displayableChunks / 2
  const pointMargin = waveformWidth / displayableChunks / 2

  const chunkedData = waveformAvgChunker(waveformData, displayableChunks)

  const [currentTime, setCurrentTime] = useState(0)
  const [trackPaused, setTrackPaused] = useState(true)

  const audioCurrentTime = audio && audio.currentTime
  const audioPaused = audio && audio.paused

  useEffect(() => {
    if(audio) {
      setTimeout(() => {
        if(!audio.paused)
        setCurrentTime(audio.currentTime)
        setTrackPaused(audio.paused)
      }, 500)
    }
  }, [audio, audioCurrentTime, audioPaused])

  const [trackProgress, setTrackProgress] = useState(0)
  const [hoverXCoord, setHoverXCoord] = useState()
  const playingPoint = (trackProgress * waveformWidth / 100) / (pointWidth + pointMargin)

  const paintWaveform = useCallback(() => {
    paintCanvas({
      canvasRef,
      waveformData: chunkedData,
      canvasHeight,
      pointWidth,
      pointMargin,
      playingPoint,
      hoverXCoord,
    })
  }, [playingPoint])

  useSetTrackProgress({
    trackProgress,
    setTrackProgress,
    trackDuration: audio?.duration,
    currentTime,
    trackPaused
  })

  useEffect(() => {
    if (canvasRef.current) {
      paintWaveform()
    }
  }, [canvasRef])

  useEffect(() => {
    paintWaveform()
  }, [playingPoint])

  const setDefaultX = useCallback(() => {
		setHoverXCoord()
	}, [])

	const handleMouseMove = useCallback((e) => {

		setHoverXCoord(
			e.clientX - canvasRef.current.getBoundingClientRect().left,
		)
	}, [])

  const seekTrack = (e) => {

    const xCoord = e.clientX - canvasRef.current.getBoundingClientRect().left
    if(audio.duration) {
      const time = (xCoord * audio.duration) / waveformWidth
      audio.currentTime = time;
    }
  }

  return (
    <WaveformContainer>
      <canvas
        className={'canvas'}
        style={{ height: canvasHeight }}
        ref={canvasRef}
        height={canvasHeight}
        width={waveformWidth}
        onBlur={setDefaultX}
        onMouseOut={setDefaultX}
        onMouseMove={handleMouseMove}
        onClick={seekTrack}
      />
    </WaveformContainer>
  )
}

export default Waveform