import styled from 'styled-components'
import colors from 'constants/colors'

export const AlertBoxContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 54px;
  box-sizing: border-box;
  border-collapse: collapse;
  width: 285px;  
  display: ${(props) => (props.active ? 'inline-block': 'none')};
  z-index: 2000;
`;

export const AlertItem = styled.div`
  display: flex;
  width: 100%;
  height: 81px;
  background-color: ${colors.black5};
  border: solid ${colors.black4};
  border-width: 1px;
  cursor: pointer;
  
  .contentBox {
    font-size: 12px;
    padding: 10px 5px;
    width: 247px;
    position: relative;

    & > div {
      margin-left: 10px;
    }

    .alertType {
      color: ${(props) => props.color};
      font-weight: 600;
      margin: 0px 0px 2px 10px;
    }
    .content {
      display: flex;
      flex-direction: column;
      height: 35px;
      justify-content: center;
      .alertTitle {
        font-weight: 600;
        color: ${colors.white1};
      }

      .dateTime {
        color: ${colors.grey5};

        & > span:nth-child(2) {
          position: relative;
          top: 1px;
          color: ${colors.red3};
        }
      }
    }
  }

  .link {
    width: 36px;
    display: flex;
    align-items:center;
    justify-content: center;
    background-color: ${colors.black8};
    cursor: pointer;
  }
`;