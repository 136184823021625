import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_TENANT_INFO_BY_TENANT_ID, UPDATE_USER_INFO_BY_USER_ID } from 'graphql/service';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

/**
 * A custom hook for valid form
 * @param {Object} customerInfo - an object has customer information
 * @returns
 * customerInfo,
 * customerInfoError,
 * updateCustomerInfo
 */

export const useCustomerForm = (setCustomerInfoError) => {

  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)

  const [updateUserInfoByUserId, { data: updateUserInfoData }] = useMutation(
    UPDATE_USER_INFO_BY_USER_ID,
    {
      onCompleted: (updateUserInfoData)=> {
        if(updateUserInfoData?.updateUser?.id) {
          message.success('Tenant Info updated successfully!')  
        } else {
          const content = 'Update failed'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
  const [updateTenantInfoByTenantId, { data: updateTenantInfoData }] = useMutation(
    UPDATE_TENANT_INFO_BY_TENANT_ID
  )

  //function for valid form, make sure nonempty field in the form
  const isValidForm = () => {

    let result = true
    if (!customerInfo.billingAddress) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, billingAddressError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, billingAddressError: false}))
    }

    if (!customerInfo.fullName) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, fullNameError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, fullNameError: false}))
    }

    if (!customerInfo.email) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, emailError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, emailError: false}))
    }

    if (!customerInfo.phoneNumber) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, phoneNumberError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, phoneNumberError: false}))
    }

    return result
  }

  //update function for valid form
  const updateCustomerInfo = () => {

    if (isValidForm()) {

      const userId = customerInfo.userId;
      const tenantId = customerInfo.tenantId;
      const fullName = customerInfo.fullName
      const email = customerInfo.email
      const phoneNumber = customerInfo.phoneNumber
      const billingAddress = customerInfo.billingAddress
      const travelDocumentNumber = customerInfo.travelDocumentNumber
      const travelDocumentType = customerInfo.travelDocumentType

      updateUserInfoByUserId({
        variables: {
          userId,
          input: {
            fullName,
            email,
            username: email,
            phoneNumber,
            address: billingAddress
          }
        }
      }) 

      // updateTenantInfoByTenantId({
      //   variables: {
      //     tenantId,
      //     input: {
      //       travelDocumentNumber
      //       travelDocumentType
      //     },
      //   },
      // })

    } else {
      const content = 'Please fill the form!'
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  }

  return {
    updateCustomerInfo
  }
}
