import React, { useState } from 'react';
import { AccountMenuContainer } from './styles';
import AccountNav from 'components/AccountNav';
import Avatar from 'assets/svg/Avatar.svg';

const AccountMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AccountMenuContainer open={open} onClick={() => setOpen(!open)}>
        <img src={Avatar} alt={'avatar'} />
      </AccountMenuContainer>
      <AccountNav open={open} setOpen={setOpen} />
    </>
  );
};

export default AccountMenu;


