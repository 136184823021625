import React from 'react';
import Card from 'components/Card';
import TextArea from 'components/TextArea';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { MessageFormContainer } from './styles';

const SendCustomerMessage = () => {
  return (
    <Card
      additionalStyle={`
        padding: 15px 24px;
      `}
    >
      <MessageFormContainer>
        <TextArea      
          mainPage
          resize={'vertical'}
          title="SEND A MESSAGE TO THIS CUSTOMER"
          onChange={(e) => { }}
          additionalStyle={`
            flex-basis: 79%;
          `}
        />
        <Dropdown
          mainPage
          options={['Text Message']}
          value="Text Message"
          title="SEND AS"
          onChange={(e) => { }}
          additionalStyle={`
            flex-basis: 12%;
            margin-left: 2%;
            .select {
              height: 47px;
            }
          `}
        />
        <div>
          <Button
            mainPage
            additionalStyle={`
              height: 47px;
              width: 65px;
              margin-top: 20px;
            `}
          >
            Send
          </Button>
        </div>
      </MessageFormContainer>
    </Card>
  )
}

export default SendCustomerMessage;
