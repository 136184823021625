import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { PrimaryContainer, Container, Header, Warning } from './styles';
import ShowInformation from 'components/ShowInformation';
import checkMark from 'assets/svg/checkMark.svg'
import { useMutation } from '@apollo/client';
import { DELETE_CREDIT_CARD, SET_CREDIT_CARD_PRIMARY } from 'graphql/service';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const CreditCards = ({paymentCards, refetch}) => {

  const [deleteCreditCard] = useMutation(DELETE_CREDIT_CARD, {
    onCompleted: (data) => {
      refetch()
      message.success('Delete credit card successfully')
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [setCreditCardPrimary] = useMutation(SET_CREDIT_CARD_PRIMARY, {
    onCompleted: (data) => {
      refetch()
      message.success('Set primary credit card successfully')
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handlePrimaryCard = (paymentCardId) => {

    setCreditCardPrimary({
      variables: {
        input: {
          paymentCardId: paymentCardId
        }
      }
    })
  }

  const handleDeleteCard = (paymentCardId) => {

    deleteCreditCard({
      variables: {
        paymentCardId: paymentCardId
      }
    })
  }

  const primaryCard = paymentCards.find((paymentCard) => paymentCard.isPrimary)

  const paymentCardList = paymentCards.map((paymentCard, index) => {
    if(!paymentCard.isPrimary) {
      return (
        <Container key={index}>
          <Header>
            <div
              className="primaryButton"
              onClick={() => handlePrimaryCard(paymentCard.paymentCardId)}
            >
              Make this card my primary card
            </div>
            <div
              className="warningButton"
              onClick={() => handleDeleteCard(paymentCard.paymentCardId)}
            >
              Delete Card
            </div>
          </Header>
          <ShowInformation
            width={'40%'}
            title="NAME"
            description={paymentCard.holderName}
            additionalStyle={`          
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
          />
          <ShowInformation
            width={'45%'}
            title="CREDIT CARD"
            description={paymentCard.transaction.creditCardNumber}
            additionalStyle={`
              background-image: url(${checkMark});
              background-repeat: no-repeat;
              background-position: 150px 90%;

              @media only screen and (max-width: 767px) {
                width: 70%;
              }
            `}
          />
          <ShowInformation
            width={'11%'}
            title="EXPIRY"
            description={paymentCard.transaction.cardExpirationDate}
            additionalStyle={`          
              @media only screen and (max-width: 767px) {
                width: 23%;
              }
            `}
          />
          <ShowInformation
            width={'4%'}
            title="CVV"
            description="***"
            additionalStyle={`          
              @media only screen and (max-width: 767px) {
                width: 7%;
              }
            `}
          />
          <ShowInformation
            width={'100%'}
            title="ADDRESS"
            description={paymentCard.billingAddress}
          />
        </Container>
      )
    }
    return null; // Add this line to fix the eslint error
  })

  return (
    <Card
      additionalStyle={`
        padding: 25px 25px 10px 15px;
      `}  
    >
      <CardHeader>
        <div className='type'>CREDIT CARDS</div>
      </CardHeader>
      {primaryCard &&
        (<PrimaryContainer>
          <Header>
            <div>
              <span className='status'>Status: </span>
              <span className='info'>Primary Card</span>
            </div>
          </Header>
          <ShowInformation
            width={'40%'}
            title="NAME"
            description={primaryCard.holderName}
            additionalStyle={`          
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
          />
          <ShowInformation
            width={'45%'}
            title="CREDIT CARD"
            description={primaryCard.transaction.creditCardNumber}
            additionalStyle={`
              background-image: url(${checkMark});
              background-repeat: no-repeat;
              background-position: 150px 90%;

              @media only screen and (max-width: 767px) {
                width: 70%;
              }
            `}
          />
          <ShowInformation
            width={'11%'}
            title="EXPIRY"
            description={primaryCard.transaction.cardExpirationDate}
            additionalStyle={`          
              @media only screen and (max-width: 767px) {
                width: 23%;
              }
            `}
          />
          <ShowInformation
            width={'4%'}
            title="CVV"
            description="***"
            additionalStyle={`          
              @media only screen and (max-width: 767px) {
                width: 7%;
              }
            `}
          />
          <ShowInformation
            width={'100%'}
            title="ADDRESS"
            description={primaryCard.billingAddress}
          />
        </PrimaryContainer>)
      }
      {
        paymentCards.length? paymentCardList: 
          <Warning>No card has been added.</Warning> 
      }
    </Card>
  )
}

export default CreditCards;