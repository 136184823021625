import React from 'react';
import { LegendItem } from './LegendItem';
import { LegendContainer } from './style';
import colors from 'constants/colors';
import { Person, Flag, Hand, OutOfOrderIcon, Splash } from 'assets/icons';

const Legend = ({desktop, mobile}) => {
  return (
    <LegendContainer desktop={desktop} mobile={mobile}>
      <LegendItem
        backgroundColor={colors.blue6}
        iconComponent={<Person className="iconLayout" />}
        legendTitle="Occupied"
      />
      <LegendItem
        backgroundColor={colors.pink1}
        iconComponent={<Person className="iconLayout" />}
        legendTitle="Occupied"
        legendDescription="(Check out today)"
      />
      <LegendItem
        backgroundColor={colors.red2}
        iconComponent={<Person className="iconLayout" />}
        legendTitle="Occupied"
        legendDescription="(Stayover, Action Req)"
      />
      <LegendItem
        backgroundColor={colors.green6}
        iconComponent={<Flag className="iconLayout" />}
        legendTitle="Room Inspected"
      />
      <LegendItem
        backgroundColor={colors.blue7}
        iconComponent={<Hand className="iconLayout" />}
        legendTitle="Room Clean"
      />
      <LegendItem
        backgroundColor={colors.black6}
        iconComponent={<OutOfOrderIcon className="iconLayout" />}
        legendTitle="Out of Order"
      />
      <LegendItem
        backgroundColor={colors.brown2}
        iconComponent={<Splash className="iconLayout" />}
        legendTitle="Room Dirty"
      />
    </LegendContainer>
  );
};

export default Legend;
