import React from 'react';
import { Container } from './styles';

const TextArea = ({
  onChange,
  value,
  title,
  placeholder,
  rows,
  additionalStyle,
  required,
  name,
  mainPage,
  resize, 
}) => {
  return (
    <Container
      additionalStyle={additionalStyle}
      mainPage={mainPage}
      resize={resize}
    >
      {title && <span>{title}</span>}
      <textarea
        name={name}
        value={value}
        rows={rows}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </Container>
  );
}

export default TextArea;

