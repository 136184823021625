import styled from 'styled-components';

export const RoomInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  column-gap: 10px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 25px;
  }
`;

export const RoomInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  column-gap: 10px;
  
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const NoteHeader = styled.div`
  font-size: 14px;
  padding: 10px 0px;
`;
