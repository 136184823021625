import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import { StylesProvider } from '@mui/styles'
import { ApolloProvider } from '@apollo/client'
import { client } from 'graphql/apolloClient'
import { AuthProvider } from 'customHook/useAuth'
import ErrorBoundary from 'components/ErrorBoundary'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <StylesProvider injectFirst>
              <AuthProvider>
                <App />
              </AuthProvider>
            </StylesProvider>
          </ApolloProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
