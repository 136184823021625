import React, { useState } from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { PicturesWallContainer, UploadButton, ModalContainer } from './styles';
import { PlusOutlined } from '@ant-design/icons';
import MainModal from 'components/ModalMain';
import { reqDeleteRoomTypeImage } from 'ajax';
import colors from 'constants/colors';
import { Upload, message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const getBase64 = (file) => {
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const PicturesWall = ({roomTypeId}) => {

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([
    {
      uid: 'a',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: 'b',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    }
  ])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  }

  const handleChange = async ({ file, fileList }) => {
        
    if (file.status==='done') {
        const result = file.response
        if (result.status===0) {
            message.success('Picture uploaded successfully!')
            const {name, url} = result.data
            const newFile = fileList[fileList.length-1]
            newFile.name = name
            newFile.url = url
        } else {
          const content = 'Failed to upload image'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
    } else if (file.status==='removed') {
        const result = await reqDeleteRoomTypeImage(file.name)
        if (result.status===0) {
            message.success('Image deleted successfully!')
        } else {
          const content = 'Failed to delete image'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
    }
            
    setFileList(fileList)
  }

  const uploadButton = (
    <UploadButton>
      <PlusOutlined />
      <div>Upload</div>
    </UploadButton>
  )

  return (
    <PicturesWallContainer>
      <Card>
        <CardHeader>
          <div className='type'>Room Images</div>
        </CardHeader>
        <Upload
        accept='image/*'
        action={(process.env.REACT_APP_DEVELOPMENT==='development' ? process.env.REACT_APP_REMOTE_ENDPOINT: '/') + 'api/files/room-types/upload-image/' + roomTypeId}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 6 ? null : uploadButton}
      </Upload>
      <MainModal
        isOpen={previewOpen}
        setIsOpen={setPreviewOpen}
        className="modal-success"
        additionalStyle={`
          max-width: 452px;
          .modal-content{
            .close {
              z-index: 200;
            }   
            .modal-body{
              position: relative;
              top: 15px;
              height: 100%;
            }
          }
          background: ${colors.grey3};
          top: 179px;
        `}
      >
        <ModalContainer>
          <img src={previewImage} alt={'room type'} width={'100%'} height={'100%'}/>
          <div>{previewTitle}</div>
        </ModalContainer>
      </MainModal>
      </Card>
    </PicturesWallContainer>
  )
}

export default PicturesWall;
