import styled from 'styled-components';
import colors from 'constants/colors';

export const RoomDetailsHeaderContainer = styled.div`
  display: flex;
  background-color: ${colors.black5};
  color: ${colors.white1};  
  padding: 15px;
  flex-direction: column;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    margin-bottom: -15px;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: -30px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.5px;

  .title {
    flex-basis: 30%;
    min-width: 110px;
    display: flex;

    .roomNumber {
      margin-right: 10px;

      @media only screen and (max-width: 767px) {
        margin-right: 5px;
      }
    }

    .secondary {
      white-space: nowrap;
      color: ${colors.grey12};
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 23px;
    border-bottom: 1px solid ${colors.grey9};
    .title {
      display: block;
    }
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
    padding: 15px;
    background: ${colors.grey2};
    border-bottom: 1px solid ${colors.grey9};
    .title {
      display: block;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  .buttonContainer {
    display: flex;
    align-items: flex-end;
    height: 80px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    justify-content: flex-end;
    .buttonContainer {
      height: 62px;
    }
  }

  @media only screen and (max-width: 767px) {
    background: ${colors.grey2};
    justify-content: center;
    padding: 8px 15px;
    .buttonContainer {
      width: 100%;
      justify-content: space-around;
      height: 38px;
    }
  }
`;
