import React, { useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import ModalOverridePrice from 'screens/ModalOverridePrice';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE } from 'graphql/service';
import { useQuery } from '@apollo/client';
import colors from 'constants/colors';
import { Row, TravelInputRow, InputRow } from './styles'
import { ADVANCED, RESERVATION } from 'constants/ratePlanId';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import moment from 'moment';

const GroupBookingSetUp = ({
  btnInfo,
  groupName,
  checkInDate,
  checkOutDate,
  roomQuantity,
  roomTypeId,
  ratePlanId,
  price,
  setGroupName,
  setCheckInDate,
  setCheckOutDate,
  setRoomQuantity,
  setRoomTypeId,
  setRatePlanId,
  setOverrideReason,
  setPrice
}) => {

  const [types, setTypes] = useState({})
  const [prices, setPrices] = useState({})  
  const [optionArray, setOptionArray] = useState([])

  const createNumberArray = (arraylength) => {
    if (arraylength < 1) {
      return [];
    }
  
    return Array.from({ length: arraylength }, (_, index) => index + 1);
  }

  useQuery(
    GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE, 
    {
      variables: {
        input: {
          checkInDate,
          checkOutDate,
          roomQuantity,
          guestQuantity: 1,
          isWheelchairAccessible: false
        }
      }, 
      onCompleted: (availableRoomTypesData)=> {
        const types = {}
        const prices = {}
        if(availableRoomTypesData) {
          availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
            if(type.name!=='Amenities') {
              types[type.name] = type
              prices[type.roomTypeId] = type.pricePerNight
            }        
          })
          setTypes(types)
          setPrices(prices)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const handleChangeRoomType = (e) => {
    setRoomTypeId(types[e].roomTypeId)
    setPrice(prices[types[e].roomTypeId])
    setOptionArray(createNumberArray(types[e].availableRooms))
  }

  const handleChangeRatePlan = (e) => {
    setRatePlanId(e)
  }



  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader>
        <div className='type'>GROUP BOOKING SET UP</div>
        <div className='buttonContainer'>{btnInfo}</div>
      </CardHeader>
      <FormContainer>
        <Row>
          <Dropdown
            mainPage
            options={optionArray}
            value={roomQuantity}
            title="# OF ROOMS"
            onChange={(e) => setRoomQuantity(e)}
            additionalStyle={`
              flex-basis: 10%;
              margin-right: 10px;

              @media only screen and (max-width: 767px) {
                flex-basis: 40%;
              }
            `}
          />
          <Dropdown
            mainPage
            options={Object.keys(types)}
            value={Object.keys(types).find(key => types[key].roomTypeId === roomTypeId)}
            title="ROOM TYPE"
            onChange={handleChangeRoomType}
            additionalStyle={`
              flex-basis: 30%;

              @media only screen and (max-width: 767px) {
                flex-basis: 60%;
              }
            `}
          />
        </Row>
        <Row>
          <Input
            mainPage
            title="GROUP NAME"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            additionalStyle={`
              flex-basis: 100%;
            `}
          />
        </Row>
        <Row>
          <TravelInputRow>
            <div>
              <div className="datePickerContainer">
                <span>ARRIVAL</span>
                <DatePicker
                  error={checkInDate? checkInDate >= checkOutDate: false}
                  minDate={moment(new Date())}
                  dateValue={moment(checkInDate)}
                  dateOnChange={setCheckInDate}
                  additionalStyle={`
                    width: 100%;
                    min-width: 130px;
                    font-size: 18px;
                    font-weight: 600;
                    border: 1px solid ${colors.grey1};

                    input {
                      padding-left: 40px;
                      padding-right: 0px;
                    }
                  `}
                />
              </div>
              <div className="timePickerContainer">
                <span>ARRIVAL TIME</span>
                <TimePicker
                  TimeValue={moment(checkInDate)}
                  setTime={setCheckInDate}
                  additionalStyle={`
                    width: 100%;
                    min-width: 110px;
                    border: 1px solid ${colors.grey1};

                    input{
                      padding-left: 40px;
                      padding-right: 0px;
                    }
                  `}
                />
              </div>
            </div>
            <div>
              <div className="datePickerContainer">
                <span>DEPARTURE</span>
                <DatePicker
                  error={checkInDate? checkInDate >= checkOutDate: false}
                  minDate={moment(new Date())}
                  dateValue={moment(checkOutDate)}
                  dateOnChange={setCheckOutDate}
                  additionalStyle={`
                    width: 100%;
                    min-width: 130px;
                    font-size: 18px;
                    font-weight: 600;
                    border: 1px solid ${colors.grey1};

                    input {
                      padding-left: 40px;
                      padding-right: 0px;
                    }
                  `}
                />
              </div>
              <div className="timePickerContainer">
                <span>DEPARTURE TIME</span>
                <TimePicker
                  TimeValue={moment(checkOutDate)}
                  setTime={setCheckOutDate}
                  additionalStyle={`
                    width: 100%;
                    min-width: 110px;
                    border: 1px solid ${colors.grey1};
                    
                    input{
                      padding-left: 40px;
                      padding-right: 0px;
                    }
                  `}
                />
              </div>
            </div>
          </TravelInputRow>
        </Row>
        <Row>
          <InputRow>
            <div>
              <Dropdown
                mainPage
                options={[{label:'Advanced', value: ADVANCED}, {label:'Reservation', value: RESERVATION}]}
                value={ratePlanId===ADVANCED? 'Advanced': 'Reservation'}
                title="RATE PLAN"
                onChange={handleChangeRatePlan}
                additionalStyle={`
                  width: 100%;              
                `}
            />
            </div>
            <div>
              <div className="price">
                <div>PRICE</div>
                {
                  !price===prices[roomTypeId]? <div>${price}<span className='highLight'>(Overridden)</span></div>: <div>{price? '$' + price: null}</div>
                }
              </div>
              {
                !price===prices[roomTypeId]? 
                  <Button
                    mainPage
                    onClick={() => {
                      setPrice('')
                      setOverrideReason('')
                    }}
                    additionalStyle={`
                      width: 78px;
                      margin-top: 20px;
                      margin-left: 5px;

                      @media only screen and (min-width: 767px) and (max-width: 1200px) {
                        width: 89px;
                      }

                      @media only screen and (max-width: 767px) {
                        width: 79px;
                      }
                    `}
                  >
                    Revert
                  </Button>:
                  <ModalOverridePrice
                    mainPage
                    price={price}
                    setPrice={setPrice}
                    setOverrideReason={setOverrideReason}
                    buttonLabel={'Override'}
                    buttonStyle={`
                      width: 108px;
                      margin-top: 20px;
                      margin-left: 5px;

                      @media only screen and (min-width: 767px) and (max-width: 1200px) {
                        width: 123px;
                      }

                      @media only screen and (max-width: 767px) {
                        width: 110px;
                      }
                    `}
                  />
              }
            </div>
          </InputRow>
        </Row>
      </FormContainer>
    </Card>
  )
}

export default GroupBookingSetUp;
