export const timeFormat = (seconds) => {
  if(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds / 60 % 60);
    const s = Math.floor(seconds % 60);

    const result = (h? h + ':': '') + m + ':' + (s-10>=0 ? s: "0"+ s)
    return result
  } else {
    return 'N/A'
  }
}