import styled from 'styled-components'
import colors from 'constants/colors'

export const MessageBoxContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 91px;
  box-sizing: border-box;
  border-collapse: collapse;
  width: 285px;  
  display: ${(props) => (props.active ? 'inline-block': 'none')};
  z-index: 2000;
`;

export const MessageItem = styled.div`
  display: flex;
  width: 100%;
  height: 81px;
  background-color: ${colors.black9};
  border: solid ${colors.black4};
  border-width: 1px;
  color: ${colors.white1};
  cursor: pointer;

  .contentBox {
    font-size: 12px;
    padding: 10px 5px;
    width: 238px;
    position: relative;

    & > div {
      margin-left: 10px;
    }

    .content {
      font-weight: 600;

      .messageWriter {
        margin-right: 10px;
      }

      .roomNumber {
        color: ${colors.blue1};
      }
    }

    .dateTime {
      color: ${colors.grey15};
    }

    .message {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .link {
    min-width: 45px;
    display: flex;
    align-items:center;
    justify-content: center;
    background-color:  ${colors.blue1};
    cursor: pointer;
  }
`;