import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34%;
  margin-top: 105px;

  input {
    height:51px;
    font-size: 16px;
    font-weight: normal;
    margin-top: 24px;
  }

  button {
    height: 51px;
    width: 89px;
    margin-top: 24px;
  }

  .brand {
    margin-top: 11px;
    color: ${colors.white1};
    .rook {
      font-size: 33px;
      font-weight: bold;
    }

    .hotel {
      font-size: 33px;
      font-weight: 300;
    }
  }


  .highLight {
    margin-top: 16px;
    font-size: 16px;
    color: ${colors.blue1};
    cursor: pointer;
  }
  
  .errorMessage {
    width: 100%;
    margin-bottom: 17px;
    color: ${colors.red1};
    align-items: flex-start;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    margin-top: 113px;
    width: 62%;

    input { 
      height: 53px;
      font-size: 20px;
      margin-top: 26px;
    }

    button {
      height: 50px;
      width: 103px;
      margin-top: 17px;
    }

    .highLight {
      font-size: 20px;
      margin-top: 33px;
    }
  }

  @media only screen and (max-width: 767px) {
    margin-top: 70px;
    width: 77%;

    input { 
      height: 45px;
      font-size: 15px;
      margin-top: 21px;
    }

    button {
      height: 47px;
      width: 92px;
      margin-top: 18px;
    }

    .rook {
      font-size: 19px;
    }

    .hotel {
      font-size: 19px;
    }

    .highLight {
      font-size: 15px;
    }
  }
`;