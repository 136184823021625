import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreateBooking from 'screens/CreateBooking';
import GroupDetails from 'screens/GroupDetails';
import BookingDetails from 'screens/BookingDetails';
import BookingCancelled from 'screens/BookingCancelled';
import Login from 'screens/Login';
import Staffs from 'screens/Staffs';
import TenantDetails from 'screens/TenantDetails';
import StaffDetails from 'screens/StaffDetails';
import ReviewPhotos from 'screens/ReviewPhotos';
import Home from 'screens/Home';
import Rooms from 'screens/Rooms';
import RoomDetails from 'screens/RoomDetails';
import RoomTypeDetails from 'screens/RoomTypeDetails';
import SiteMap from 'screens/SiteMap';
import Bookings from 'screens/Bookings';
import OccupancyReport from 'screens/OccupancyReport';
import BookingsReport from 'screens/BookingsReport';
import DailyReconciliation from 'screens/DailyReconciliation';
import RevenueReport from 'screens/RevenueReport';
import RoomNightReport from 'screens/RoomNightReport';
import Communications from 'screens/Communications';
import SearchResults from 'screens/SearchResults';
import PassportScanner from 'screens/PassportScanner';
import useAuth from 'customHook/useAuth';

const App = () => {
  
  const  { validToken } = useAuth()

  return (
    <Switch>
      {
        validToken?
          <> 
            <Route path="/login" exact component={Login} />
            <Route path="/site-map" exact component={SiteMap} />
            <Route path="/" exact component={Home} />
            <Route path="/bookings" exact component={Bookings} />
            <Route path="/create-booking" exact component={CreateBooking} />
            <Route path="/rooms" exact component={Rooms} />
            <Route path="/room/:roomNumber" exact component={RoomDetails} />
            <Route path="/staff" exact component={Staffs} />
            <Route path="/occupancy-report" exact component={OccupancyReport} />
            <Route path="/revenue-report" exact component={RevenueReport} />
            <Route path="/daily-reconciliation" exact component={DailyReconciliation} />
            <Route path="/bookings-report" exact component={BookingsReport} />
            <Route path="/room-night-report" exact component={RoomNightReport} />
            <Route path="/group-details/:groupId" exact component={GroupDetails} />
            <Route path="/booking-details/:bookingId" exact component={BookingDetails} />
            <Route path="/booking-cancelled/:bookingId" exact component={BookingCancelled} />
            <Route path="/review-photos/:bookingId" exact component={ReviewPhotos} />
            <Route path="/staff-details/:staffId" exact component={StaffDetails} />
            <Route path="/tenant-details/:tenantId" exact component={TenantDetails} />
            <Route path="/room-type-details/:roomTypeId" exact component={RoomTypeDetails} />
            <Route path="/guest-communications/:tenantId" exact component={Communications} />
            <Route path="/staff-communications/:staffId" exact component={Communications} />
            <Route path="/search-results" exact component={SearchResults} />
            <Route path="/passport-scanner" exact component={PassportScanner} />
          </> :
          <>
            <Redirect to="/login" />
            <Route path="/login" exact component={Login} />
          </>
      }
    </Switch>
  );
};

export default App;
