
import { useEffect } from 'react'

const useSetTrackProgress = ({
  trackProgress,
  trackDuration,
  currentTime,
  setTrackProgress,
  trackPaused,
}) => {

  useEffect(() => {        
    const clamp = (val, min, max) => Math.min(Math.max(val, min), max)

    let animation
    if (!trackPaused) {
      animation = window.requestAnimationFrame(() => {
        const trackProgressPerc = currentTime * 100 / trackDuration
        setTrackProgress(
          clamp(
            trackProgressPerc,
            0, 100,
          ),
        )
      })
    }
    return () => {
      window.cancelAnimationFrame(animation)
    }
  }, [trackPaused, trackDuration, currentTime, trackProgress])
}

export default useSetTrackProgress;