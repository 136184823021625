import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setGuest } from 'redux/actions'
import { Container } from './styles';
import ArrowIcon from 'assets/icons/arrow.png';
import ModalReplyFromDashboard from 'screens/ModalReplyFromDashboard';

const MessageItem = ({
  fullName,
  roomNumber,
  tenantId,
  type,
  date,
  message,
  mobile
}) => {

  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Container mobile={mobile}>
      <div
        className='messageBody'
        onClick={() => {
          if(!mobile) {
            history.push({pathname: `/guest/${tenantId}`, state: {communication: true}})
          } else {
            dispatch(setGuest(tenantId, type))
          }
        }}
      >
        <div className='messageInfo'>
          <span className='fullName'>{fullName}</span>
          <span className='roomNumber'>{`Room ${roomNumber}`}</span>
        </div>
        <div>
          <span className='messageType'>{type}</span>
          <span className='date'>{date}</span>
        </div>
        <div className='message'>{message}</div>
      </div>
      <ModalReplyFromDashboard
        buttonLabel={" "}
        buttonStyle={`
          width: 100%;
          height: 100%;
          background: url(${ArrowIcon}) no-repeat 50% 50%;
        `}
        tenantId={tenantId}
      />
    </Container>
  )
}

export default MessageItem;
