import { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * A custom hook for valid form
 * @param {Object} paymentInfo - an object has payment information
 * @returns
 * paymentInfo,
 * paymentError,
 * updatePaymentInfo
 */

export const usePaymentForm = (creditCardType) => {

  const paymentInfo = useSelector(state => state.paymentInfoReducer.paymentInfo)
  const [paymentError, setPaymentError] = useState({
    cardHolderNameError: false,    
    creditCardNumberError: false,
    cardExpirationDateError: false,
    cardCVVError: false,
    billingAddressError: false,
  })

  //function for valid form, make sure nonempty field in the form
  const isValidatePaymentInfo = () => {

    let result = true
    if (!paymentInfo.cardHolderName) {
      result = false;
      setPaymentError((paymentError)=>({...paymentError, cardHolderNameError: true}))
    } else {
      if(paymentError.cardHolderNameError) {
        setPaymentError((paymentError)=>({...paymentError, cardHolderNameError: false}))
      }
    }

    if (!creditCardType.length) {
      result = false;
      setPaymentError((paymentError)=>({...paymentError, creditCardNumberError: true}))
    } else {
      if(paymentError.creditCardNumberError) {
        setPaymentError((paymentError)=>({...paymentError, creditCardNumberError: false}))
      }
    }

    if (paymentInfo.cardExpirationDate.length < 5) {
      result = false;
      setPaymentError((paymentError)=>({...paymentError, cardExpirationDateError: true}))
    } else {
      if(paymentError.cardExpirationDateError) {
        setPaymentError((paymentError)=>({...paymentError, cardExpirationDateError: false}))
      }
    }

    if (paymentInfo.cardCVV.length < 3) {
      result = false;
      setPaymentError((paymentError)=>({...paymentError, cardCVVError: true}))
    } else {
      if(paymentError.cardCVVError) {
        setPaymentError((paymentError)=>({...paymentError, cardCVVError: false}))
      }
    }

    if (paymentInfo.billingAddress.length < 3) {
      result = false;
      setPaymentError((paymentError)=>({...paymentError, billingAddressError: true}))
    } else {
      if(paymentError.billingAddressError) {
        setPaymentError((paymentError)=>({...paymentError, billingAddressError: false}))
      }
    }

    return result
  }
  
  //helper parse function to parse google address component
  // const parseStringAddress = (stringAddress) => {
  //   const parts = stringAddress.split(', ');
  //   const streetAddress = parts[0]
  //   const city = parts[1]
  //   const province = parts[2].split(' ')[0]
  //   const postalCode = parts[2].split(' ')[1] + ' ' + parts[2].split(' ')[2]
  //   const country = parts[3]
  
  //   return {
  //     streetAddress,
  //     city,
  //     province,
  //     postalCode,
  //     country,
  //   };
  // }

  return {
    isValidatePaymentInfo,
    paymentError,
    setPaymentError
  }
}
