import React from 'react';
import { MessageContainer } from './styles';
import moment from 'moment';

const Message = ({createAt, content, staff}) => {

  return (
    <MessageContainer staff={staff}>
      <div className='createAt'>
        {moment(createAt).format('M/D/YY h:mmA')}
      </div>
      <div className='content'>
        {content}
      </div>
    </MessageContainer>
  )
}

export default Message;
