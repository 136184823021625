import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_TENANT_INFO_BY_BOOKING_ID } from 'graphql/service';
import { useCustomerForm } from 'customHook/useCustomerForm';
import { setCustomerInfo } from 'redux/actions'
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import GuestInformation from 'components/BookingMain/GuestInformation';
import SideBar from './components/SideBar';
import { Viewing, Remaining } from './components/MobileRemaining';
import Review from './components/Review';
import Image1 from 'assets/icons/Male.svg'
import Image2 from 'assets/icons/Female.svg'
import Image3 from 'assets/icons/Gym.svg'
import {
  MainContainer,
  Content,
  ContentLeft,
  ContentRight,
} from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ReviewPhotos = () => {

  const bookingId = useParams().bookingId
  const dispatch = useDispatch()
  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)

  const { data: bookingDetailsData, loading, error, refetch } = useQuery(GET_TENANT_INFO_BY_BOOKING_ID, {
    variables: { bookingId: bookingId },
    onCompleted: (bookingDetailsData) => {
      if(bookingDetailsData) {
        const newCustomer = {...customerInfo}
        const { tenantGroup: { tenant, isPrimaryTenant } } = bookingDetailsData.bookingByID   
        const {
          tenantId,
          user: {
            userId,
            fullName,
            email,
            phoneNumber,
            address
          }
        } = tenant
        newCustomer.userId = userId
        newCustomer.tenantId = tenantId
        newCustomer.isPrimaryTenant = isPrimaryTenant
        newCustomer.fullName = fullName
        newCustomer.email = email
        newCustomer.phoneNumber = phoneNumber
        newCustomer.billingAddress = address
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = ''
        dispatch(setCustomerInfo(newCustomer))
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [customerInfoError, setCustomerInfoError] = useState({})
  const { updateCustomerInfo } = useCustomerForm(setCustomerInfoError);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleFlagAsInvalid = () => {

  }

  const handleApprove = () => {

  }
  
  return (
    <>
      <Helmet>
        <title>Review Photos - Rook Hotel PMS</title>
      </Helmet>
      <Header
        mobileSubHeaderHidden
        subHeader={
          <>
            <div className='title'>
              Review Photos
            </div>
            <div
              className='buttonContainer'
              style={{paddingRight: '20%'}}
            >
              <Button
                mainPage
                danger
                onClick={handleFlagAsInvalid}
                additionalStyle={`
                  width: 115px;
                `}
              >
                Flag as invalid
              </Button>
              <Button
                mainPage
                onClick={handleApprove}
                additionalStyle={`
                  width: 81px;
                  margin-left: 14px;
                  margin-right: 7px;
                `}
              >
                Approve
              </Button>
            </div>
          </>
        }
      />
      <MainContainer>
        <ContentLeft>
          <Content>
            <Viewing remaining={[]} bookingId={bookingId} />
            <Review imageSources={[Image1,Image2,Image3]} handleFlagAsInvalid={handleFlagAsInvalid} handleApprove={handleApprove}/>
            <GuestInformation customerInfoError={customerInfoError} />
            <Remaining remaining={[]} bookingId={bookingId} />
          </Content>
        </ContentLeft>
        <ContentRight>
          <SideBar remaining={[]} bookingId={bookingId} />
        </ContentRight>
      </MainContainer>
      <Footer />
    </>
  );
};

export default ReviewPhotos;
