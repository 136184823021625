import React from 'react'
import { Helmet } from 'react-helmet'
import Header from 'components/Header'
import Footer from 'components/Footer'
import ManageRooms from '../Home/components/ManageRooms'
import { RoomsContainer } from './styles'

const Rooms = () => {

  return (
    <>
      <Helmet>
        <title>Rooms - Rook Hotel PMS</title>
      </Helmet>
      <Header />
      <RoomsContainer>
        <ManageRooms />
      </RoomsContainer>
      <Footer />
    </>
  );
};

export default Rooms;
