import moment from 'moment';

const generateFakeData = () => {
  const groups = [
    {
      id: '1',
      title: 'Rook',
      height: 70
    },
    {
      id: '2',
      title: 'Rook Inside',
      height: 70
    },
    {
      id: '3',
      title: 'Bishop',
      height: 70
    },
    {
      id: '4',
      title: 'Queen',
      height: 70
    },
    {
      id: '5',
      title: 'King',
      height: 70
    },
  ];

  const items = [
    {
      id: '10',
      group: '2',
      title: '4/8 50% $123.45 10%',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    {
      id: '11',
      group: '1',
      title: '4/8 50% $123.45 10%',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    {
      id: '12',
      group: '3',
      title: '4/8 50% $123.45 10%',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
  ];

  return { groups, items };
};

export default generateFakeData;
