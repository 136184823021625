export const scrollDown = (position, duration) => {
  let startTime
  //easing func
  const startPos = window.scrollY
  const easeInOutCubic = (t) => {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2
  }
  // create recursive function to call every frame
  const scroll = (timestamp) => {
    startTime = startTime || timestamp
    const elapsed = timestamp - startTime
    const top = startPos + (position - startPos) * easeInOutCubic(elapsed / duration)
    window.scroll({top: top})
    elapsed <= duration && window.requestAnimationFrame(scroll);
  }
  // call recursive function
  if (startPos !== position) window.requestAnimationFrame(scroll);
}
