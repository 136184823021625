import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  background-color: ${colors.grey3};
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  color: ${colors.white1};

  .title {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    color: ${colors.grey5}
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const CustomerInformationImagesItem = styled.div`
  color: ${colors.green1};
  display: flex;
  padding: 10px;
  margin: 0 8px;
  cursor: pointer;
  span {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  & > svg {
    margin-right: 5px;
  }
`;
