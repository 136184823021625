import styled from 'styled-components';
import colors from 'constants/colors';
import SoundTrack from 'assets/svg/soundTrack.svg'

export const AudioContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 44%;
  float: ${({staff})=> staff? 'left': 'right'};
  clear: both;
  
  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .createAt {
    text-align: ${({staff})=> staff? 'left': 'right'};
    font-size: 16px;
    font-weight: 600;
    color: ${colors.grey27};
    margin-bottom: 5px;
  }

  .audioContainer {
    position: relative;
    width: 100%;
    height: 98px;
    background-color: ${({staff})=> staff? colors.green7: colors.blue8};
    border-radius: 14px;
    padding: 15px 20px;
    margin-bottom: 30px;

    .controls {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .player-button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        margin-right: 10px;

        img{
          width: 50px;
          height: 50px;
        }
        
        :focus {
          outline: none;
        }
      }
    }

    .time {
      font-size: 13px;
      font-weight: 600;
      padding-left: 60px;
    }
  }
`;
