import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from 'redux/actions'
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import ModalCheckIn from 'screens/ModalCheckIn';
import ModalCheckOut from 'screens/ModalCheckOut';
import ModalBookingCancel from 'screens/ModalBookingCancel';
import TravelArrangements from 'components/BookingMain/TravelArrangements';
import GroupBookingInfo from 'components/BookingMain/GroupBookingInfo';
import Room from 'components/BookingMain/Room';
import GuestInformation from 'components/BookingMain/GuestInformation';
import ImagesReview from 'components/BookingMain/ImagesReview';
import AddOns from 'components/BookingMain/AddOns';
import GeneralBookingNotes from 'components/BookingMain/GeneralBookingNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import ActivityHistory from 'components/BookingMain/ActivityHistory';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  GET_BOOKING_DETAILS_BY_BOOKING_ID,
  GET_GROUP_DETAILS_BY_GROUP_ID,
  UPDATE_BOOKING_BY_BOOKING_ID,
  UPDATE_GROUP_BY_GROUP_ID,
} from 'graphql/service';
import { useCustomerForm } from 'customHook/useCustomerForm';
import { message } from 'antd';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import { RESERVATION } from 'constants/ratePlanId';
import moment from 'moment';
import { getMessageTimeOut } from 'utils/util';


const BookingDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history= useHistory()
  const bookingId = useParams().bookingId
  const dispatch = useDispatch()
  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)
  const [groupId, setGroupId] = useState('')
  const [updateGroupByGroupId] = useMutation(UPDATE_GROUP_BY_GROUP_ID)

  //GroupBookingInfo
  const [groupName, setGroupName] = useState('')
  const [tenantList, setTenantList] = useState({})
  const [getGroupDetailsByGroupId, { data: groupData, loading: groupLoading, error: groupError }] = useLazyQuery(
    GET_GROUP_DETAILS_BY_GROUP_ID,
    {
      onCompleted: (groupData) => {
        if(groupData) {
          const newList = []
          if(groupData.groupByID.bookings) {
            groupData.groupByID.bookings.forEach((booking) => {
              newList.push({label: booking?.tenantGroup?.tenant?.user?.fullName, value: booking.bookingId})
            })
          }
          setTenantList(newList)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
  
  //room 
  const [ratePlanId, setRatePlanId] = useState(RESERVATION)
  const [roomId, setRoomId] = useState('')
  const [roomNumber, setRoomNumber] = useState('')
  const [roomType, setRoomType] = useState('')
  const [roomPrice, setRoomPrice] = useState(0)
  const [roomStatus, setRoomStatus] = useState('')
  const [roomNotes, setRoomNotes] = useState([])

  //TravelArrangements
  const [checkInDate, setCheckInDate] = useState(moment().hour(15).minute(0).second(0))
  const [checkOutDate, setCheckOutDate] = useState(moment().add(1,'days').hour(11).minute(0).second(0))
  const [updateBookingByBooingId] = useMutation(UPDATE_BOOKING_BY_BOOKING_ID)

  //GeneralBookingNotes
  const [bookingNotes, setBookingNotes] = useState([])

  const { data: bookingDetailsData, loading, error, refetch } = useQuery(GET_BOOKING_DETAILS_BY_BOOKING_ID, {
    variables: { bookingId: bookingId },
    onCompleted: (bookingDetailsData) => {
      if(bookingDetailsData) {
        const newCustomer = {...customerInfo}
        const {
          email,
          phoneNumber,
          fullName,
          address, 
          tenantGroup,
          group,
          isDeleted,
          checkInDate,
          checkOutDate,
          roomBookings,
          bookingNotes
        } = bookingDetailsData.bookingByID
        if(isDeleted) {
          const content = "This booking have been cancelled already."
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
          history.push(`/booking-cancelled/${bookingId}`)
        }
        setCheckInDate(checkInDate)
        setCheckOutDate(checkOutDate)
        setBookingNotes(bookingNotes)
        const {
          //ratePlanId,
          pricePerNight,
          room: {
            roomId,
            roomNumber,
            roomType,
            roomNotes,
            roomStatusHistories
          }
        } = roomBookings[0]
        //setRatePlanId(ratePlanId)
        setRoomId(roomId)
        setRoomNumber(roomNumber)
        setRoomType(roomType)
        setRoomPrice(pricePerNight)
        setRoomNotes(roomNotes)
        setRoomStatus(roomStatusHistories?.[0]?.status)
        const { groupId, name: groupName } = group
        setGroupName(groupName) 
        setGroupId(groupId) 
        newCustomer.userId = tenantGroup?.tenant.user.userId || ''
        newCustomer.tenantId = tenantGroup?.tenant.tenantId || ''
        newCustomer.fullName = fullName
        newCustomer.email = email
        newCustomer.phoneNumber = phoneNumber
        newCustomer.billingAddress = address || ''
        //FIXME: document value
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = '' 
        dispatch(setCustomerInfo(newCustomer))
        getGroupDetailsByGroupId({
          variables: { groupId: groupId}
        })
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [customerInfoError, setCustomerInfoError] = useState({})
  const { updateCustomerInfo } = useCustomerForm(setCustomerInfoError)

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleSaveChanges = () => {

    updateCustomerInfo()

    updateBookingByBooingId({
      variables: {
        bookingId,
        input: {
          checkInDate: checkInDate,
          checkOutDate: checkOutDate
        },
      },
    })

    updateGroupByGroupId({
      variables: {
        groupId,
        input: {
          name: groupName
        }
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Booking Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className='title'>
              <span
                className='highLight'
                onClick={() => {
                  history.push('/bookings')
                }}
              >
                Bookings
              </span>
              {` > ${bookingId} ${customerInfo.fullName || ''}`} 
            </div>
            <div className='buttonContainer'>
              <div>
                <ModalCheckIn
                  disabled={true}
                  buttonLabel="Check In"
                  buttonStyle={`
                    width: 113px;
                    margin-right: 10px;
                  `}
                  groupId={groupId}
                />
                <ModalCheckOut
                  buttonLabel="Check Out"
                  buttonStyle={`
                    width: 113px;
                    margin-right: 10px;
                  `}
                  groupId={groupId}
                />
                <ModalBookingCancel
                  bookingId={bookingId}
                  danger
                  buttonStyle={`
                    width: 115px;

                    @media only screen and (min-width: 767px) and (max-width: 1200px) {
                      width: 157px;
                    }
      
                    @media only screen and (max-width: 767px) {
                      width: 129px;
                    }
                  `}
                />
              </div>
            </div>
          </>
        }
      />
      <Content>
        <ContentColumn>
          <Container mobile>
            <GroupBookingInfo
              groupId={groupId}
              tenantList={tenantList}
              groupName={groupName}
              setGroupName={setGroupName}
            />
          </Container>
          <Container>
            <TravelArrangements
              bookingId={bookingId}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              setCheckInDate={setCheckInDate}
              setCheckOutDate={setCheckOutDate}
            />
          </Container>
          <Container>
            <Room
              roomNotes={roomNotes}
              roomType={roomType}
              roomPrice={roomPrice}
              status={roomStatus}
              ratePlanId={ratePlanId}
              setRatePlanId={setRatePlanId}
              roomNumber={roomNumber}
              roomId={roomId}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              bookingId={bookingId}
              refetch={()=>{
                refetch().then((bookingDetailsData) => {
                  setRoomNotes(bookingDetailsData.data.bookingByID?.roomBookings[0]?.room.roomNotes)
                })
              }}
            />
          </Container>
          <Container desktop>
            <GroupBookingInfo
              groupId={groupId}
              tenantName={customerInfo.fullName}
              tenantList={tenantList}
              groupName={groupName}
              setGroupName={setGroupName}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <GuestInformation
              customerInfoError={customerInfoError}
              setCustomerInfoError={setCustomerInfoError}
              btnInfo={
                <>
                  <Button
                    mainPage
                    onClick={() => {
                      history.push(`/guest/${customerInfo.tenantId}`)
                    }}
                    additionalStyle={`
                      width: 132px;
                    `}
                    disabled={customerInfo.tenantId?.length === 0}
                  >
                    Guest Profile
                  </Button>
                  <Button
                    mainPage
                    success
                    onClick={handleSaveChanges}
                    additionalStyle={`
                      margin-left: 14px;
                      width: 113px;
                    `}
                  >
                    Save Changes
                  </Button>
                </>
              }
            />
            <ImagesReview bookingId={bookingId}/>
          </Container>
          <Container>
            <AddOns
              bookingId={bookingId}
            />
          </Container>
          <Container>
            <GeneralBookingNotes
              notes={bookingNotes}
              bookingId={bookingId}
              headerString={'GENERAL BOOKING NOTES'}
              refetch={()=>{
                refetch().then((bookingDetailsData) => {
                  setBookingNotes(bookingDetailsData.data.bookingByID?.bookingNotes)
                })
              }}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
          <Container>
            <ActivityHistory bookingId={bookingId}/>
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  );
};

export default BookingDetails;
