import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  top: -5px;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Container = styled.div`

  & > div {
    margin-top: 15px;
    margin-bottom: 10px;
    color: ${colors.white1};
  }

  .groupTitle {
    margin-top: 25px;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 2.5px;
  }

  .checkboxGroup {
    width: 80%;
    height: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: flex-end;

  input {
    height: 49px;
  }  
`;
