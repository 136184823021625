import styled from 'styled-components';
import colors from 'constants/colors';

export const CancelledMsg = styled.div`
  border: ${colors.red2} solid 1px;
  height: 127px;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 10px 20px;
  color: ${colors.red2};
  margin: 15px;
  margin-bottom: 0px;
`;
export const Msg = styled.p`
  margin: auto 0px;
  text-align: center;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 2.5px;
`;
