import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 81px;
  color: ${colors.white1};
  cursor: pointer;

  .messageBody {
    font-size: 12px;
    padding: 13px 5px;
    width: 100%;
    min-width: 224px;
    position: relative;

    &:before {
      content: '';
      background-color: ${colors.green3};
      position: absolute;
      width: 4px;
      top: 10px;
      bottom: 10px;
      border-radius: 50px;
    }

    .messageInfo {
      margin-left: 10px;
      font-weight: 600;
      .fullName {
        margin-right: 10px;
      }

      .roomNumber {
        color: ${colors.orange3};
      }
    }

    .date {
      margin-left: 5px;
      color: ${colors.grey15};
    }

    .messageType {
      margin-left: 10px;
      color: ${colors.grey15};
    }

    .message {
      margin-left: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    ${({ mobile }) => (mobile? `
      height: 110px;
      .messageBody {
        font-size: 15px;
        line-height: 2;
      }
    `: null
    )};
  }

  @media only screen and (max-width: 767px) {
    ${({ mobile }) => (mobile? `
      height: 89px;
      .messageBody {
        font-size: 13px;
      }
    `: null
    )};
  }
`;
