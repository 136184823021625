import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  background-color: ${colors.grey3};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 18px 15px;
  color: ${colors.white1};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;

  .warning {
    color: ${colors.red1};
    margin-left: 10px;
  }
  
  .highLight {
    margin-left: 5px;
    color: ${colors.blue1};
    cursor: pointer;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
`;

